import {
  login,
  register,
  forgotPassword,
  resetPassword,
  me,
  updateCustomer,
  updateCompany,
  getBankTransferByCompany,
  getDefaultDashboard,
  deleteDashboardById,
  getDashboardWidgets,
  getDashboardsByCustomer,
  getDashboardsByCompany,
  getCompanyCustomers,
  ideaSoftApp,
} from '@/api/customer';
import VueCookies from 'vue-cookies';

export default {
  async login({ commit }, data) {
    const ideaData = await VueCookies.get('idea-app');
    return login({ ...data, ideaData })
      .then((response) => {
        commit('SET_CUSTOMER', response.data.customer);
        commit('SET_TOKEN', response.data.accessToken);
        commit('SET_SESSION', response.data.session);
        return response.data;
      });
  },
  me({ commit, state }) {
    if (state.customer.id != null) {
      return state.customer;
    }
    return me().then((response) => {
      commit('SET_CUSTOMER', response.data.customer);
    });
  },
  getCompanyCustomers({ commit }, payload) {
    return getCompanyCustomers(payload).then((response) => {
      commit('SET_COMPANY_CUSTOMERS', response.data);
      return response.data;
    });
  },
  forceMe({ commit }) {
    return me().then((response) => {
      commit('SET_CUSTOMER', response.data.customer);
      return response.data;
    });
  },
  updateCustomer({ commit }, data) {
    return updateCustomer(data).then((response) => {
      commit('SET_CUSTOMER', response.data.customer);
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateCompany({ commit }, data) {
    return updateCompany(data).then((response) =>
    // commit('SET_COMPANY', response.data.company);
      // eslint-disable-next-line implicit-arrow-linebreak
      response.data);
  },
  async register({ commit }, data) {
    const ideaData = await VueCookies.get('idea-app');
    return register({ ...data, ideaData }).then((response) => {
      commit('SET_CUSTOMER', response.data.customer);
      commit('SET_TOKEN', response.data.accessToken);
      commit('SET_SESSION', response.data.session);
    });
  },
  // eslint-disable-next-line no-unused-vars
  forgotPassword({ commit }, data) {
    return forgotPassword(data).then((response) => response.data);
  },
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit }, data) {
    return resetPassword(data).then((response) => response.data);
  },
  logout({ commit }) {
    commit('LOGOUT');
  },
  loadingUploadImage({ commit }, data) {
    commit('SET_UPLOAD_IMAGE', data);
  },
  addedAttiributes({ commit }, data) {
    commit('SET_ADDED_ATTRIBUTES', data);
  },
  darkModeChange({ commit }, data) {
    commit('SET_DARK_MODE', data);
  },
  numberStatusChange({ commit }, data) {
    commit('SET_NUMBER_MODE', data);
  },
  rxResetForm({ commit }, data) {
    commit('SET_RESET_FORM', data);
  },
  varyantCollapseMenu({ commit }, data) {
    commit('SET_COLLAPSE_MENU', data);
  },
  errorsPageStatus({ commit }, data) {
    commit('SET_ERRORS_PAGE_STATUS', data);
  },
  getBankTransferByCompany({ commit }) {
    return getBankTransferByCompany()
      .then((response) => {
        commit('SET_BANK_TRANSFER', response.data);
        return response.data;
      });
  },
  offlinePage({ commit }, targetPath) {
    commit('SET_OFFLINE_PAGE', false);
    if (this.history.pushState) this.history.pushState(null, 'Connection Error', targetPath);
  },
  getDefaultDashboard({ commit }) {
    return getDefaultDashboard()
      .then((response) => {
        commit('SET_DEFAULT_DASHBOARD', response.data);
        return response.data;
      });
  },
  getDashboardWidgets({ commit }) {
    return getDashboardWidgets()
      .then((response) => {
        commit('SET_DASHBOARD_WIDGETS', response.data);
        return response.data;
      });
  },
  getDashboardsByCustomer({ commit, state }) {
    if (state.customerDashboards.customerDashboards.length) {
      return state.customerDashboards.customerDashboards;
    }
    return getDashboardsByCustomer()
      .then((response) => {
        commit('SET_DASHBOARD_CUSTOMER', response.data);
        return response.data.customerDashboards;
      });
  },
  getDashboardsByCompany({ commit }) {
    return getDashboardsByCompany()
      .then((response) => {
        commit('SET_DASHBOARD_COMPANY', response.data);
        return response.data;
      });
  },
  deleteDashboardById(_, payload) {
    return deleteDashboardById(payload)
      .then((response) => response.data);
  },
  verifyIdeaApp({ commit }, data) {
    return ideaSoftApp(data)
      .then((response) => {
        commit('SET_IDEAPP', JSON.stringify(response.data.encryptedData));
        return response.data;
      });
  },
};
