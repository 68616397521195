import i18n from '../i18n.js';

const chartFilters = {
  products: {
    status: i18n.t('product.status'),
    categories: i18n.t('product.categories'),
    supplierCategories: i18n.t('product.supplierCategory'),
    brands: i18n.t('product.brands'),
    checkBuyBoxStatus: i18n.t('product.buyboxStatus'),
    baseProductId: i18n.t('product.mainProduct'),
    totalStocks: i18n.t('product.stock'),
    providers: {
      providerStatus: i18n.t('product.providerStatus'),
      providerOrder: i18n.t('product.providerOrder'),
      opponentAnalysisStatus: i18n.t('product.opponentAnalysisStatus'),
    },
  },
  productSaleReports: {
    categories: i18n.t('product.categories'),
    brands: i18n.t('product.brands'),
  },
  productDetailReports: {
    categories: i18n.t('product.categories'),
    brands: i18n.t('product.brands'),
  },
  cityBasedReporting: {
    categories: i18n.t('product.categories'),
    brands: i18n.t('product.brands'),
  },
};
export default chartFilters;
