<template>
  <div class="w-100">
    <div>
      <div class="p-0">
        <b-row>
          <b-col md="8">
            <b-form-select
              :options="filterOptions.filter((w) => !selectedFiltersList.includes(w.value))"
              v-model="currentFilter"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  {{$i18n.t('product.chooseFilter')}}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col md="4">
            <b-button
              class="w-100 mt-sm-0 mt-3"
              @click="handleAddFilters(currentFilter)">
              {{$i18n.t('product.add')}}
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <b-row
            :key="selectedFilter"
            class="mt-2 w-100"
            v-for="selectedFilter in selectedFiltersList">
            <b-col md="3" class="pt-1">
              {{getTitleByFilter(type, selectedFilter)}}
            </b-col>
            <b-col>
             <div class="d-flex w-100" style="gap: 10px">
               <component
                 class="w-100"
                 :tabFilter="tabFilter"
                 @change="handleFilterChange"
                 @select="handleFilterChange"
                 :provider="getProviderNameByFilter(selectedFilter)"
                 :is="'filter-' + getFilterNameByFilter(selectedFilter)">
               </component>
               <b-button
                 variant="danger"
                 style="max-height: 44px"
                 @click="removeFilter(selectedFilter)">
                 <i class="fa fa-trash"></i>
               </b-button>
             </div>
            </b-col>
          </b-row>
          <b-row  class="w-100 mt-3"
                  v-if="selectedFiltersList.length === 0">
            <b-col md="12" class="text-center w-100 mt-2">
              {{$i18n.t('product.targetText')}}
            </b-col>
          </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import chartFilters from '@/resources/chartFilters';
import { mapState, mapActions } from 'vuex';
import { getJsonItem } from '../../../utils/localStorage';

export default {
  name: 'rx-filter',
  props: ['type', 'value', 'providers', 'currentSorting', 'tabFilter'],
  data() {
    return {
      selectedFilters: {},
      selectedFiltersList: [],
      chartFilters: { },
      baseProduct: {
        show: false,
      },
      filterOptions: [],
      currentFilter: null,
      excludeFields: ['stockType', 'onlyInStock', 'inputSearch'],
    };
  },
  computed: {
    filters() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.chartFilters = chartFilters;
      if (chartFilters[this.type]) {
        return Object.keys(chartFilters[this.type]).filter((f) => f !== 'providers' && this.selectedFiltersList.indexOf(f) === -1);
      }
      return [];
    },
    chartFiltersProviders() {
      if (chartFilters[this.type] && chartFilters[this.type].providers) {
        return Object.keys(chartFilters[this.type].providers);
      }
      return [];
    },
    ...mapState({
      customer: (state) => state.customer.customer,
    }),
  },
  methods: {
    ...mapActions({
      updateDecoratedProducts: 'product/updateDecoratedProducts',
    }),
    handleAddFilters(filter) {
      if (filter) {
        this.currentFilter = null;
        if (!this.selectedFilters[filter]) {
          this.selectedFilters[filter] = null;
          this.selectedFiltersList.push(filter);
        }
        const isItCorrect = 'baseProductId';
        if (filter === isItCorrect) {
          this.updateDecoratedProducts(this.baseProduct);
        }
      }
    },
    removeFilter(filter) {
      delete this.selectedFilters[filter];
      const index = this.selectedFiltersList.indexOf(filter);
      if (index > -1) {
        this.selectedFiltersList.splice(index, 1);
      }
      this.$emit('change', this.selectedFilters);
    },
    handleFilterChange(data) {
      this.selectedFilters[data.filter] = data.data;
      this.$emit('change', this.selectedFilters);
    },
    getFilterNameByFilter(filterName) {
      return filterName.split('.').length === 1
        ? filterName : filterName.split('.')[1];
    },
    getProviderNameByFilter(filterName) {
      return filterName.split('.').length === 1 ? null : filterName.split('.')[0];
    },
    getTitleByFilter(type, filterName) {
      const provider = this.getProviderNameByFilter(filterName);
      if (provider == null) {
        return this.chartFilters[type][filterName];
      }
      return this.chartFilters[type].providers[this.getFilterNameByFilter(filterName)]
        .replace('{provider}', Vue.filter('providerName')(provider === 'ecommercePlatform'
          ? this.customer.company.defaultProvider : provider));
    },
  },
  created() {
    if (getJsonItem('productFilter') != null) {
      const storeChartFilters = Object.keys(getJsonItem('productFilter'));
      this.chartFilters = storeChartFilters
        .filter((w) => !this.excludeFields.includes(w))
        .reduce((obj, key) => {
          obj[key] = storeChartFilters[key];
          return obj;
        }, {});
      this.selectedFiltersList = Object.keys(getJsonItem('productFilter')).filter((w) => !this.excludeFields.includes(w));
    }
    this.filterOptions = this.filters.map((w) => ({
      text: this.chartFilters[this.type][w],
      value: w,
    }));
    if (this.chartFiltersProviders.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const provider of this.providers) {
        this.filterOptions.push({
          text: Vue.filter('providerName')(provider === 'ecommercePlatform'
            ? this.customer.company.defaultProvider : provider),
          options: this.chartFiltersProviders
            .filter((w) => this.selectedFiltersList.indexOf(`${provider}.${w}`) === -1).map((filter) => ({
              text: this.getTitleByFilter(this.type, `${provider}.${filter}`),
              value: `${provider}.${filter}`,
            })),
        });
      }
    }
  },
};
</script>
<style lang="scss">
@import "styles/index";
.filter-row{
  margin-top: -20px;
  margin-left: 20px;
  .title{
    font-weight: bold;
    margin-bottom: 5px;
  }
}
.filterContainer .dropdown-menu {
  width: 600px !important;
}
.select2-container--default {
  max-width: 100% !important;
}
</style>
