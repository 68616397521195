import {
  getSearchData,
} from '@/api/search';
import { cancelRequest } from '../../../utils/request.js';

export default {
  getSearchData({ commit }, payload) {
    cancelRequest();
    return getSearchData(payload)
      .then((response) => {
        commit('SET_MODULE_SEARCH', response.data);
        return response.data;
      });
  },
};
