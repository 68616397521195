import request from '../utils/request';

const prefix = '/api/customers';

// eslint-disable-next-line import/prefer-default-export
export function getPayments(payload) {
  return request.get(`${prefix}/transaction`, {
    params: {
      ...payload,
    },
  });
}
