import {
  getBatchRequests,
  getExportBatchRequestItems,
} from '@/api/product';

export default {
  getSummaryBatchRequests({ commit }, payload) {
    return getBatchRequests(payload)
      .then((response) => {
        commit('SET_SUMMARY_BATCH_REQUEST', response.data);
        return response.data;
      });
  },
  batchRequestModal({ commit }, data) {
    commit('BATCH_REQUEST_MODAL', data);
  },
  getExportBatchRequestItems(payload) {
    return getExportBatchRequestItems(payload)
      .then((response) => response.data);
  },
};
