import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './langs/en.json';
import tr from './langs/tr.json';

Vue.use(VueI18n);
export default new VueI18n({
  locale: localStorage.getItem('lang') || 'tr',
  silentTranslationWarn: true,
  fallbackLocale: 'tr',
  messages: {
    en,
    tr,
  },
});
