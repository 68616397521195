import request from '../utils/request';

const prefix = '/api/invoices';

export const getInvoices = ({ page, limit, filter }) => request.get(`${prefix}/`, { params: { page, limit, filter } });

export const getInvoice = ({ id }) => request.get(`${prefix}/${id}`);

export const getInvoiceStats = () => request.get(`${prefix}/stats`);

export const getWarehouseFromErp = () => request.get(`${prefix}/get-warehouse-erp`);

export const getIncomeInvoice = ({ page, limit, filter }) => request.get(`${prefix}/income-invoices`, { params: { page, limit, filter } });

export const getIncomeSettlement = ({ page, limit, filter }) => request.get(`${prefix}/income-settlement`, { params: { page, limit, filter } });

export const incomeInvoiceSummary = () => request.get(`${prefix}/income-invoice-summary`);

export const getInvoiceTemplateElements = () => request.get(`${prefix}/template-elements`);

export const getInvoiceTemplates = ({ page, limit, filter }) => request.get(`${prefix}/templates`, { params: { page, limit, filter } });

export const getEInvoices = ({ page, limit }) => request.get(`${prefix}/einvoices`, { params: { page, limit } });

export const getInvoiceTemplate = (id) => request.get(`${prefix}/templates/${id}`);

export const getInvoiceDefaultTemplate = () => request.get(`${prefix}/default-invoice-template`);

export const deleteInvoiceTemplates = (id) => request.delete(`${prefix}/templates/${id}`);

export const updateInvoiceTemplates = (id, params) => request.put(`${prefix}/templates/${id}`, params);

export const makeDefault = ({ id, type }) => request.put(`${prefix}/make-default/${id}`, { type });

export const sendReceiptToERP = ({ orderId }) => request.post(`${prefix}/send-receipt-erp`, { orderId });

export const sendProductToERP = (payload) => request.post(`${prefix}/send-product-erp`, payload);

export const sendReceiptsToERP = ({ orderIds }) => request.post(`${prefix}/send-receipts-erp`, { orderIds });

export const sendInvoiceToERP = ({ orderId }) => request.post(`${prefix}/send-invoice-erp`, { orderId });

export const sendEInvoice = (orderId) => request.post(`${prefix}/send-einvoice`, { orderIds: orderId });

export const sendInvoicesToERP = ({ orderIds }) => request.post(`${prefix}/send-invoices-erp`, { orderIds });

export function saveSerialNumber(payload) {
  return request.post(`${prefix}/save-serial-number`, { ...payload });
}

export function setInvoiceTemplates(payload) {
  return request.post(`${prefix}/templates`, { ...payload });
}

export function updateManyStatus(payload) {
  return request.put(`${prefix}/update-many-status`, {
    ...payload,
  });
}

export function getAccounts(payload) {
  return request.get(`${prefix}/accounts`, {
    params: {
      ...payload,
    },
  });
}

export function getERPProducts(params) {
  return request.get(`${prefix}/erp-provider-products`, { params });
}

export function prepareErpProduct(params) {
  return request.get(`${prefix}/prepare-erp-product`, { params });
}

export function createAccount(payload) {
  return request.post(`${prefix}/account`, {
    ...payload,
  });
}

export function deleteAccount(id) {
  return request.delete(`${prefix}/account/${id}`);
}

export function getAccount(id) {
  return request.get(`${prefix}/account`, {
    params: {
      id,
    },
  });
}

export function updateAccount(payload, id) {
  return request.put(`${prefix}/account/${id}`, {
    ...payload,
  });
}

export function createCashProceeds(payload) {
  return request.post(`${prefix}/cash-proceeds`, { ...payload });
}

export function getCashProceeds(payload) {
  return request.get(`${prefix}/cash-proceeds`, {
    params: {
      ...payload,
    },
  });
}
