export default {
  productSources: (state) => state.productSources,
  productSource: (state) => state.productSource,
  error: (state) => state.error,
  success: (state) => state.success,
  filePreviewResponse: (state) => state.filePreviewResponse,
  refreshFilePreviewResponse: (state) => state.refreshFilePreviewResponse,
  enumFileTypes: (state) => state.enums.fileTypes,
  enumFieldTypes: (state) => state.enums.fieldTypes,
  fieldPaths: (state) => state.enums.fieldPaths,
  valueFunctions: (state) => state.enums.valueFunctions,
  productSourceFileInfo: (state) => state.productSourceFileInfo,
};
