<template>
  <div class="quickview" :class="inputClicked ? 'edit-quick-open' : 'edit-quick-close'">
    <div
      v-show="!inputClicked"
      ref="input">
      <div class="d-flex">
        <span class="form-input input-content currency-input input-lg"
                      style="text-align: right"
                      @click="clickInput">
          {{ price  | money(currency) }}
        </span>
      </div>
    </div>
    <div class="input-frame"
         v-role="'products.change-provider-price'"
         v-show="inputClicked"
         v-closable="{
              exclude: ['input'],
              handler: 'onClose'
                    }">
      <currency-input
        @update:currency="salesPriceChange"
        @input="newValue"
        v-model="product[provider].defaultPrice.price"
        :currency.sync="product[provider].defaultPrice.currency"
        :provider="provider"
        style="text-align: right"></currency-input>
        <div
          v-if="limits != null && customer.company.boundaryPriceSettings.isBoundaryPriceActive"
          style="margin-right: 15px"
          class="parent-span-edit"
        >
          <span v-if="limits.min">
            Min: {{ limits.min | money(product[provider].defaultPrice.currency) }} <br /></span>
          <span>Ort: {{ avg | money(product[provider].defaultPrice.currency) }} <br /></span>
          <span v-if="limits.max">
            Max:  {{ limits.max | money(product[provider].defaultPrice.currency) }}</span>
        </div>
      <div
        v-else
        style="margin-right: 15px"
      ><br></div>
      <div class="button">
        <button @click="onClose" class="pp-btn tiny ghost">
          {{ $i18n.t('product.giveUp') }}
        </button>
        <button @click="updatePrice" class="pp-btn tiny primary-button primary">
          <b-spinner v-if="waiting" :disabled="waiting" label="Spinning" small></b-spinner>
          <span v-else>
            {{ $i18n.t('product.save') }}
          </span>
        </button>
      </div>
    </div>
    <b-modal
      id="boundary-price-warning"
      ref="boundary-price-warning"
      :title="$i18n.t('product.outOfBoundsPrice')"
      body-class="no-footer-padding"
      size="md"
      no-close-on-backdrop
    >
      <div>
        {{ $i18n.t('product.numberWantUpdate') }}:
        {{ getPrice() | money(isSupported ? product[provider].defaultPrice
        ? product[provider].defaultPrice.currency
        : 'TRY' : 'TRY') }}
        <br />  <br />
        {{ $i18n.t('product.avgPrice') }}:
        {{ this.avg | money(isSupported ? product[provider].defaultPrice
        ? product[provider].defaultPrice.currency
        : 'TRY' : 'TRY') }} <br />  <br />
        {{ $i18n.t('product.betweenDifference') }}:
        {{ parseFloat(this.differencePerc).toFixed(2) }}% <br />
      </div>
    <template #modal-footer>
      <b-button
        variant="light"
        @click="onClose">{{ $i18n.t('product.giveUp')  }}</b-button>
      <b-button
        variant="primary"
        @click="handleBoundary">{{ $i18n.t('product.save')  }}</b-button>
    </template>
    </b-modal>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import CurrencyInput from '@/components/CurrencyInput';
import _ from 'lodash';
import Vue from 'vue';
import { getProviderConstantWithKeys, productPriceLimit } from '../../../api/product';

export default {
  name: 'ProductHistory',
  components: {
    CurrencyInput,
  },
  props: {
    product: {},
    provider: String,
    productId: String,
  },
  data() {
    return {
      inputFrame: 'input-frame',
      inputClicked: false,
      waiting: false,
      oldPrice: 0,
      limits: null,
      avg: null,
      differencePerc: null,
      forceUpdate: false,
      show: false,
      price: 0,
      isSupported: false,
      currency: 'TRY',
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
      singleProduct: (state) => state.product.singleProduct,
      productCount: (state) => state.product.productCount,
      customer: (state) => state.customer.customer,
    }),
  },
  methods: {
    ...mapActions({
      getProducts: 'product/getProducts',
      getProductCount: 'product/getProductCount',
      updateSingleProduct: 'product/updateSingleProduct',
      updateManyStatus: 'product/updateManyStatus',
    }),
    clickInput() {
      this.inputClicked = !this.inputClicked;
      productPriceLimit({
        provider: this.provider,
        providerSkus: this.product[this.provider].sku,
      }).then(({ data }) => {
        if (data[0] != null && data[0].limits != null) {
          this.avg = data[0].avgPrice;
          this.limits = data[0].limits;
        }
      });
    },
    updatePrice() {
      if (this.customer.company.boundaryPriceSettings.isBoundaryPriceActive) {
        const price = this.getPrice();
        if (this.limits != null && !this.forceUpdate) {
          if ((this.limits.min && this.limits.min > price)
            || (this.limits.max && this.limits.max < price)) {
            this.differencePerc = (price - this.avg) * (100 / this.avg);
            this.$refs['boundary-price-warning'].show();
            return;
          }
        }
      }
      const data = {
        [`${this.provider}.calculatedPrice.salesPrice`]: this.product[this.provider].defaultPrice.price,
        [`${this.provider}.defaultPrice.price`]: this.product[this.provider].defaultPrice.price,
        [`${this.provider}.defaultPrice.currency`]: this.product[this.provider].defaultPrice.currency,
      };
      this.waiting = true;
      this.updateSingleProduct({
        // eslint-disable-next-line no-underscore-dangle
        productId: this.productId,
        data,
        forceUpdate: this.forceUpdate,
      }).then(() => {
        this.oldPrice = this.product[this.provider].defaultPrice.price;
        this.price = this.product[this.provider].defaultPrice.price
          ? this.product[this.provider].defaultPrice.price : this.product[this.provider].price;
        this.currency = this.product[this.provider].defaultPrice.currency;
        this.waiting = false;
        this.onClose();
        const currentProvider = this.provider === 'ecommercePlatform' ? Vue.filter('defaultProviderName')(this.customer.company.defaultProvider) : Vue.filter('providerName')(this.provider);
        this.$root.$bvToast.toast(`${currentProvider} için fiyat güncellendi ve otomatik fiyat değişimi kapatıldı.`, {
          title: 'Başarılı',
          variant: 'success',
          solid: true,
        });
      });
    },
    handleBoundary() {
      this.forceUpdate = true;
      this.updatePrice();
      this.forceUpdate = false;
      this.$refs['boundary-price-warning'].hide();
    },
    onClose() {
      this.inputClicked = false;
      this.product[this.provider].price = this.oldPrice;
      if (this.$refs['boundary-price-warning'] != null) {
        this.$refs['boundary-price-warning'].hide();
      }
    },
    salesPriceChange(val) {
      _.set(this.product, `${this.provider}.defaultPrice.currency`, val);
    },
    newValue(val) {
      _.set(this.product, `${this.provider}.defaultPrice.price`, val);
    },
    getPrice() {
      getProviderConstantWithKeys({
        provider: this.provider,
        keys: ['currencySupport'],
      }).then(({ data }) => {
        this.isSupported = data.currencySupport;
      });
      const rate = this.isSupported ? 1
        : _.get(this.customer,
          `company.exchangeRates.${_.get(this.product,
            `${this.provider}.defaultPrice.currency`, 'TRY')}`, 1);
      return this.product[this.provider].defaultPrice.price * rate;
    },
  },
  mounted() {
    this.oldPrice = this.product[this.provider].price;
  },
  created() {
    this.price = this.product[this.provider].defaultPrice.price
      ? this.product[this.provider].defaultPrice.price : this.product[this.provider].price;
    this.currency = this.product[this.provider].defaultPrice.currency;
  },
};
</script>
<style lang="scss" scoped>
  .quickview{
    position: relative;
  }
  .currency-input{
    border: 1px solid transparent;
    padding: 5px;
    width: 150px;
    &:hover{
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    &:focus{
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  .input-frame{
    min-width: 168px;
    border-radius: 8px;
    box-shadow: 0 8px 32px 0 rgb(0 0 0 / 8%);
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
    .parent-span-edit{
      span{
        line-height: 32px;
      }
    }
    .input-content{
      outline: none;
      width: calc(100% - 16px);
      height: 34px;
      margin: 8px 8px;
      text-align: right;
      border-radius: 8px;
      border: solid 2px var(--primary-color);
      background-color: #ffffff;
      padding-right: 12px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.24px;
      color: #484848;
    }
    button{
      font-size: 12px;
      width: 100%;
      border-radius: 8px;
    }
    .primary-button span{
      color: white !important;
      font-size: 12px;
    }
    .button{
      margin-top: 4px;
      display: flex;
      margin-bottom: 8px;
      padding: 0 5px;
      gap: 5px;
      width: 100%;
      .pp-btn {
        &.tiny {
          padding: 4px 8px 4px 8px;
        }

        &.ghost {
          background-color: #fff;
          color: #484848;
          border: solid 2px #ccc;
        }
      }
    }
  }
</style>
