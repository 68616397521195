export default {
  invoices: {
    data: [],
    count: 0,
  },
  invoiceStats: {

  },
  erpProducts: {},
  warehouseFromErp: [],
  incomeInvoices: {},
  templates: {},
  templateElements: {},
  templateElement: {},
  accounts: [],
  einvoices: {
    data: [],
    count: 0,
  },
  invoice: {},
  decoratedAccounts: {
    label: null,
    code: null,
    show: false,
  },
};
