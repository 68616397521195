<template>
  <div>
    <router-view :key="$route.fullPath"/>
    <b-modal
      v-show="isLarge"
      id="batchRequestModal"
      size="xl"
      body-class="no-footer-padding"
      hide-footer>
      <template #modal-header="{ cancel }">
        <div class="d-flex justify-content-between w-100">
          <h6>{{batchRequestModal.modalTitle}}</h6>
          <button @click="cancel" class="close">&times;</button>
        </div>
      </template>
      <div v-if="batchRequestModal.currentStep === 1
      && batchRequestModal.currentStep">
        <div v-if="bulkInsertLoading">
          <div class="text-center">
            <b-spinner label="Spinning"></b-spinner>
            <b-spinner type="grow" label="Spinning"></b-spinner>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <b-spinner variant="success" label="Spinning"></b-spinner>
            <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          </div>
        </div>
        <div v-else-if="batchRequestModal.selected != null && batchRequestModal.selected.length">
          <div class="mb-3">
            {{
              $i18n.t('product.selectedTextConfirm', {
                msg: this.batchRequestModal.selected.length
              })
            }}
          </div>
          <b-button v-if="batchRequestModal.process === 'update'"
                    variant="primary"
                    @click="sendUpdatedProviderProductDetails(true)">Gönder</b-button>
          <b-button v-else
                    variant="primary"
                    @click="sendBulkProviderProduct(batchRequestModal.type, true)">Gönder</b-button>
        </div>
        <div v-else>
          <b-form-group :label="$i18n.t('product.marketPlaces')">
            <treeselect
              v-if="batchRequestModal.process === 'update'"
              :options="customer.company.allProviders.filter((o) => o !== 'manual')
              .map((x) => ({
              id:x,
              label:x}))"
              :defaultExpandLevel="1"
              :multiple="true"
              value-consists-of="BRANCH_PRIORITY"
              v-model="form.providers"
              search-nested
            />
            <treeselect
              v-else-if="batchRequestModal.process === 'create'"
              :options="batchRequestModal.type === 'erp' ? batchRequestModal.erpDefaultProvider
              : customer.company.allProviders.filter((o) => o !== 'manual')
              .map((x) => ({
              id:x === customer.company.defaultProvider ? 'ecommercePlatform' : x,
              label:x}))"
              :defaultExpandLevel="1"
              :multiple="true"
              value-consists-of="BRANCH_PRIORITY"
              v-model="form.providers"
              search-nested
            />
          </b-form-group>
          <b-form-group
            v-if="batchRequestModal.type !== 'erp'"
            :label="$i18n.t('product.onSale')">
            <b-form-checkbox
              id="isSalable"
              v-model="form.isSalable"
              name="isSalable"
            >
            </b-form-checkbox>
          </b-form-group>
          <b-form-group :label="$i18n.t('product.onStock')">
            <b-form-checkbox
              id="isStock"
              v-model="form.isStock"
              name="isStock"
            >
            </b-form-checkbox>
          </b-form-group>
          <b-button v-if="batchRequestModal.process === 'update'"
                    variant="primary"
                    @click="sendUpdatedProviderProductDetails(false)">Gönder</b-button>
          <b-button v-else
                    variant="primary"
                    @click="sendBulkProviderProduct(batchRequestModal.type, false)">
            Gönder</b-button>
        </div>
      </div>
      <div v-else-if="batchRequestId != null">
        <batch-request-item :modalText="true" :id="batchRequestId"></batch-request-item>
      </div>
    </b-modal>
    <b-toast v-show="!isLarge"
             id="miniRequestModal"
             :title="$i18n.t('product.transactionInProgress')" no-auto-hide no-fade>
      <div style="cursor: pointer" @click="openBigModal">
        <batch-request-item :modalText="false" :id="batchRequestId">
        </batch-request-item>
      </div>
    </b-toast>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import treeselect from '@riophae/vue-treeselect';
import BatchRequestItem from './components/BatchRequestItem';
import {
  bulkInsertFromProviderProducts,
  sendUpdateProviderProductDetails,
} from './api/product';

export default {
  name: 'App',
  components: {
    BatchRequestItem,
    treeselect,
  },
  data() {
    return {
      isLarge: true,
      form: {
        providers: [],
        isSalable: true,
        isStock: true,
      },
      filter: {
        isAddedInProduct: false,
        isSalable: null,
        isStock: null,
        provider: null,
      },
      filterNumber: null,
      currentStep: 1,
      bulkInsertLoading: false,
      batchRequestId: null,
      miniModal: false,
      payload: {},
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer.customer,
      batchRequestModal: (state) => state.batchRequest.batchRequestModal,
      batchEnd: (state) => state.batchRequest.batchEnd,
    }),
  },
  methods: {
    ...mapActions({
      getProviderProducts: 'product/getProviderProducts',
    }),
    get() {
      this.loading = true;
      this.getProviderProducts({
        page: this.currentPage,
        limit: 20,
        filter: this.filter,
      }).then(() => {
        this.loading = false;
        this.isLarge = true;
      });
    },
    selectedProviderProductFilters() {
      const providers = [];
      const providerSkus = [];
      const barcodes = [];

      this.batchRequestModal.selected.forEach((x) => {
        if (x.provider != null && x.provider !== '') {
          providers.push(x.provider);
        }
        if (x.sku != null && x.sku !== '') {
          providerSkus.push(x.sku);
        }
        if (x.barcode != null && x.barcode !== '') {
          barcodes.push(x.barcode);
        }
      });

      return {
        providers: [...new Set(providers)],
        filter: {
          providerSkus,
          barcodes,
        },
      };
    },
    sendBulkProviderProduct(type, selected) {
      this.payload = {
        providers: this.form.providers,
        filter: {
          isSalable: this.form.isSalable,
          isStock: this.form.isStock,
        },
        type,
      };
      if (selected) {
        this.payload = this.selectedProviderProductFilters();
      }
      this.batchRequestModal.currentStep = 2;
      bulkInsertFromProviderProducts(this.payload).then((resp) => {
        this.get();
        this.currentStep = 2;
        // eslint-disable-next-line no-underscore-dangle
        this.batchRequestId = resp.data._id;
      });
    },
    sendUpdatedProviderProductDetails(selected) {
      this.payload = {
        providers: this.form.providers,
        filter: {
          isSalable: this.form.isSalable,
          isStock: this.form.isStock,
        },
      };
      if (selected) {
        this.payload = this.selectedProviderProductFilters();
      }
      this.batchRequestModal.currentStep = 2;
      sendUpdateProviderProductDetails(this.payload).then((resp) => {
        this.bulkInsertLoading = false;
        this.get();
        // eslint-disable-next-line no-underscore-dangle
        this.batchRequestId = resp.data._id;
      });
    },
    openBigModal() {
      this.$bvToast.hide('miniRequestModal');
      this.isLarge = true;
      this.$root.$emit('bv::show::modal', 'batchRequestModal');
    },
    closeModal() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'batchRequestModal') {
          this.bulkInsertLoading = false;
          this.isLarge = false;
          if (this.isLarge === false
            && this.batchRequestModal.end === false && this.batchRequestId != null) {
            this.$bvToast.show('miniRequestModal');
          }
        }
      });
    },
  },
  watch: {
    batchRequestModal(val) {
      if (val && !this.batchRequestModal.end) {
        this.isLarge = this.batchRequestModal.isLarge;
        this.get();
        if (this.isLarge) {
          this.$root.$emit('bv::show::modal', 'batchRequestModal');
        } else {
          this.$bvToast.show('miniRequestModal');
        }
        this.batchRequestId = null;
        if (val.batchRequestId) {
          this.batchRequestId = val.batchRequestId;
        }
      }
    },
  },
  mounted() {
    /*    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('./service-worker.js');
      });
    } */
    this.$root.$on('bv::modal::hide', (modalTemplateReview) => {
      if (modalTemplateReview.componentId === 'modalTemplateReview') {
        window.Tawk_API.showWidget();
      }
    });
    this.closeModal();
    window.redirectPage = (key) => {
      this.$router.push({
        name: key,
      });
    };
  },
};
</script>
<style lang="scss">
@import "./assets/scss";
#miniRequestModal {
  background-color: white;
}
</style>
