import request from '../utils/request';

const prefix = '/api/upload/s3';

export function uploadS3(payload) {
  return request.post(`${prefix}`, {
    ...payload,
  });
}

export function getS3(params) {
  return request.get(`${prefix}`, { params });
}
