import request from '../utils/request';

const prefix = '/api/products';

export function createProduct(payload) {
  return request.post(`${prefix}`, {
    ...payload,
  });
}

export function getProduct(payload) {
  return request.get(`${prefix}/${payload.productId}`);
}

export function getProductByBarcode(barcodeId) {
  return request.get(`${prefix}/get-product-by-barcode/${barcodeId}`);
}

export function estimateProductPrice(payload) {
  return request.get(`${prefix}/${payload.productId}/estimate-product-price`, {
    params: {
      provider: payload.provider,
    },
  }).then((resp) => resp.data);
}

export function updateSingleProduct(productId, payload, forceUpdate) {
  return request.put(`${prefix}/${productId}/provider-product`, {
    updateData: payload,
    forceUpdate,
  });
}

export function updateProduct(productId, payload) {
  return request.put(`${prefix}/${productId}`, {
    ...payload,
  });
}

export function getProductStocks({ page, limit, filter }) {
  return request.get(`${prefix}/product-stocks`, { params: { page, limit, filter } });
}

export function getProviderProductStocks({ productId }) {
  return request.get(`${prefix}/warehouse-provider-product-stocks`, { params: { productId } });
}

export function upsertProductStock(payload) {
  return request.post(`${prefix}/upsert-product-stock`, {
    ...payload,
  });
}

export function updateOnlyInfoProduct({ productId, data }) {
  return request.put(`${prefix}/updateOnlyInfos/${productId}`, {
    ...data,
  });
}

export function createXml({ data }) {
  return request.post(`${prefix}/create-xml`, {
    ...data,
  });
}

export function getXmls({ data }) {
  return request.get(`${prefix}/xmls`, {
    params: { ...data },
  });
}

export function updateXml({ xmlId, data }) {
  return request.put(`${prefix}/xml/${xmlId}`, {
    ...data,
  });
}

export function executeXml({ xmlId }) {
  return request.get(`${prefix}/execute-xml/${xmlId}`);
}

export function updateAnalysis(payload) {
  return request.put(`${prefix}/update-analysis`, {
    ...payload,
  });
}

export function getProducts(params) {
  return request.get(`${prefix}`, { params });
}

export function batchInfo(params) {
  return request.get(`${prefix}/batchInfo`, { params });
}

export function getAllProductsWithoutPagination(params) {
  return request.get(`${prefix}/get-products`, { params });
}

export function getProductHistories(params) {
  return request.get(`${prefix}/histories`, { params });
}
export function getProductPriceHistory(params) {
  return request.get(`${prefix}/price-history`, { params });
}
export function deleteProduct(productId) {
  return request.delete(`${prefix}/${productId}`);
}

export function deleteProducts(payload) {
  return request.delete(`${prefix}/delete-many-products`, { ...payload });
}

export function updateManyStatus(payload) {
  return request.put(`${prefix}/update-many-status`, {
    ...payload,
  });
}

export function createWarehouse(data) {
  return request.post(`${prefix}/warehouse`, data);
}

export function getWarehouses({ page, limit, filter }) {
  return request.get(`${prefix}/warehouses`, { params: { page, limit, filter } });
}

export function getWarehouseById(id) {
  return request.get(`${prefix}/warehouse/${id}`);
}

export function getShelfByBarcode(barcodeId) {
  return request.get(`${prefix}/warehouse-shelf/${barcodeId}`);
}

export function updateWarehouseById(id, payload) {
  return request.put(`${prefix}/warehouse/${id}`, { ...payload });
}

export function deleteWarehouse(id) {
  return request.delete(`${prefix}/warehouse/${id}`);
}

export function deleteShelfFromWarehouse(payload) {
  return request.put(`${prefix}/remove-shelf-from-warehouse`, { ...payload });
}

export function updateProviderStatus(data) {
  return request.post(`${prefix}/update-provider-status`, data);
}

export function unmatchProduct(params) {
  return request.post(`${prefix}/unmatch-product-attributes`, {
    ...params,
  });
}

export function bulkUnmatchProduct(params) {
  return request.post(`${prefix}/bulk-unmatch-product-attributes`, {
    ...params,
  });
}

export function matchProductStocks(params) {
  return request.post(`${prefix}/match-product-stocks`, {
    ...params,
  });
}

export function transferProductStocks(params) {
  return request.post(`${prefix}/transfer-between-ware-houses`, {
    ...params,
  });
}

export function getBrands(params) {
  return request.get(`${prefix}/brands`, {
    params,
  });
}

export function getProviderBrands(params) {
  return request.get(`${prefix}/provider-brands`, {
    params,
  });
}

export function getExchangeRates(params) {
  return request.get(`${prefix}/exchange-rates`, { params });
}

export function getCategoryConnectedProduct(categoryId) {
  return request.get(`${prefix}/check-connected-category/${categoryId}`);
}

export function getProductCount(params) {
  return request.get(`${prefix}/product-count`, { params });
}

export function updateBulkProductField(payload) {
  return request.put(`${prefix}/update-bulk-product-field`, { ...payload });
}

export function updateBulkProdviderStatus(payload) {
  return request.put(`${prefix}/update-bulk-provider-status`, { ...payload });
}

export function updateBulkOpponentAnalysis(payload) {
  return request.put(`${prefix}/update-bulk-opponent-analysis`, payload);
}

export function getProductFilterCount(params) {
  return request.post(`${prefix}/product-filter-count`, {
    ...params,
  });
}

export function getRequestLogs(params) {
  return request.get(`${prefix}/request-logs`, { params });
}

export function getProviderProducts(params) {
  return request.get(`${prefix}/provider-products`, { params });
}

export function saleProduct(data) {
  return request.post(`${prefix}/sale-product`, data);
}

export function fastSaleProduct(data) {
  return request.post(`${prefix}/fast-sale-product`, data);
}

export function matchedProduct(data) {
  return request.post(`${prefix}/matched-product`, data);
}

export function getBatchRequests(params) {
  return request.get(`${prefix}/batch-requests`, {
    params,
  });
}
export function getBatchRequest(id) {
  return request.get(`${prefix}/batch-requests/${id}`);
}

export function getBatchRequestItems(id, page, limit, filter) {
  return request.get(`${prefix}/batch-requests/${id}/items`, {
    params: {
      page, limit, filter,
    },
  });
}

export function getProviderAddresses(provider) {
  return request.get(`${prefix}/provider-addresses?provider=${provider}`);
}

export function changeProviderStatus(data) {
  return request.post(`${prefix}/provider/change-status-v2`, data);
}

export function changeProviderStock(data) {
  return request.post(`${prefix}/provider/change-stock`, data);
}

export function sendProductForDefaultProvider(payload) {
  return request.post(`${prefix}/${payload.productId}/send-product-for-default-provider`);
}

export function getWaitForApproveProductCount() {
  return request.get(`${prefix}/wait-for-approve-product-count`);
}

export function getConfigProducts({ productId }) {
  return request.get(`${prefix}/${productId}/configs`);
}

export function getBuyBoxProductCount() {
  return request.get(`${prefix}/buy-box-product-count`);
}

export function csvImport(payload) {
  return request.post(`${prefix}/csv-import`, payload);
}

export function bulkInsertFromProviderProducts(payload) {
  return request.post(`${prefix}/bulk-insert-from-provider-products`, payload);
}

export function updateManyPrice(payload) {
  return request.put(`${prefix}/update-many-price`, payload);
}

export function generateBarcodeProducts(payload) {
  return request.put(`${prefix}/generate-product-barcodes`, payload);
}

export function downloadTemplate() {
  return request.get(`${prefix}/template/download`);
}

export function downloadProductTemplate(params) {
  return request.get(`${prefix}/template/product-download`, {
    params,
  });
}

export function uploadTemplate({ url }) {
  return request.put(`${prefix}/template/upload`, {
    url,
  });
}
export function uploadProductTemplate({ url, categoryId }) {
  return request.put(`${prefix}/template/product-upload`, {
    url,
    categoryId,
  });
}

export function exportProducts(data) {
  return request.post(`${prefix}/export`, data);
}

export function getExportRequests() {
  return request.get(`${prefix}/export`);
}

export function getExportBatchRequestItems() {
  return request.get(`${prefix}/export-batch-request`);
}

export function getErrorBatchRequestItems(params) {
  return request.get(`${prefix}/error-batch-request-items`, { params });
}

export function createErrorBatchRequest(data) {
  return request.put(`${prefix}/error-batch-request`, data);
}

export function exportBatchRequestItems(data) {
  return request.post(`${prefix}/export-batch-request`, data);
}

export function sendUpdateProviderProductDetails(data) {
  return request.post(`${prefix}/update-provider-product-details`, data);
}

export function getExportTemplates() {
  return request.get(`${prefix}/exportTemplates`);
}

export function productPriceLimit({ provider, providerSkus }) {
  return request.get(`${prefix}/product-price-limits`, {
    params: {
      providerSkus,
      provider,
    },
  });
}

export function getVariantProducts({ productId }) {
  return request.get(`${prefix}/${productId}/variants`);
}

export function prepareSaleProduct({
  filter,
  providers,
  page,
  withCatalogId,
}) {
  return request.get(`${prefix}/prepare-sale-products`, {
    params: {
      filter,
      providers,
      page,
      withCatalogId,
    },
  });
}
export function prepareFastSaleProduct({ filter, providers }) {
  return request.get(`${prefix}/prepare-fast-sale-products`, {
    params: { filter, providers },
  });
}

export function saleProducts(data) {
  return request.post(`${prefix}/sale-products`, data);
}

export function saveVariantProduct(data) {
  return request.put(`${prefix}/update-or-create-variant-product`, data);
}

export function setBundleProducts(data) {
  return request.post(`${prefix}/set-bundle-products`, data);
}

export function discardBundleProduct(productId, bundleId) {
  return request.put(`${prefix}/discard-bundle-product/${productId}`, { bundleId });
}

export function getBannedTypes() {
  return request.get(`${prefix}/banned-list/types`);
}

export function getBannedLists(params) {
  return request.get(`${prefix}/banned-list`, {
    params,
  });
}

export function removeBannedLists(listId) {
  return request.delete(`${prefix}/banned-list/${listId}`);
}

export function createBannedList({ params }) {
  return request.post(`${prefix}/banned-list/`, {
    ...params,
  });
}

export function updateBannedList({ params, listId }) {
  return request.put(`${prefix}/banned-list/${listId}`, {
    ...params,
  });
}

export function getListingUpdateList() {
  return request.get(`${prefix}/listing-update-list`);
}

export function updateListing(payload, provider) {
  return request.put(`${prefix}/update-product-listing/${provider}`, payload);
}

export function bulkUpdateListing(payload, provider) {
  return request.put(`${prefix}/bulk-update-product-listing/${provider}`, payload);
}

export function updateProductInfoFromProvider(payload) {
  return request.post(`${prefix}/update-product-info-from-provider`, payload);
}

export function updateProductInfosFromProvider(payload) {
  return request.post(`${prefix}/update-product-infos-from-provider`, payload);
}

export function productEstimatedStocks(payload) {
  return request.get(`${prefix}/product-estimated-stocks`, payload);
}

export function getCriticalStockProducts(params) {
  return request.get(`${prefix}/critical-stock-products`, { params });
}

export function getProductByShelfBarcode(params) {
  return request.get(`${prefix}/product-by-shelf-barcode`, { params });
}

export function getStockTakingById(id) {
  return request.get(`${prefix}/stock-taking/${id}`);
}

export function getStockTakingProductsById({ id, params }) {
  return request.get(`${prefix}/stock-taking-products/${id}`, { params });
}

export function getStockTakings({ page, limit, filter }) {
  return request.get(`${prefix}/stock-takings`, { params: { page, limit, filter } });
}

export function getSalesChannels(provider) {
  return request.get(`${prefix}/sales-channels`, { params: { provider } });
}

export function updateProductStockTakingDifferences(payload) {
  return request.post(`${prefix}/update-stock-taking-differences`, payload);
}

export function upsertStockTaking(payload) {
  return request.put(`${prefix}/upsert-stock-taking`, payload);
}

export function checkTrackingInfos(payload) {
  return request.post(`${prefix}/check-tracking-infos`, payload);
}

export function getProviderConstantWithKeys({ provider, keys }) {
  return request.get(`${prefix}/provider-constant-with-keys`, { params: { provider, keys } });
}

export function checkLogisticProducts(payload) {
  return request.post(`${prefix}/check-logistic-products`, payload);
}

export function addLogisticProduct(payload) {
  return request.post(`${prefix}/add-logistic-product`, payload);
}

export function importProducts(params) {
  return request.post(`${prefix}/import`, {}, { params });
}

export function updateProviderProducts(params, payload) {
  return request.post(`${prefix}/update-provider-products`, payload, { params });
}

export function updateLogisticProducts(params) {
  return request.post(`${prefix}/update-logistic-products`, {}, { params });
}
export function updatePrices(params) {
  return request.post(`${prefix}/update-prices`, {}, { params });
}

export function updateOtherStores(params) {
  return request.post(`${prefix}/update-other-stores`, {}, { params });
}

export function getProductsSaleCount(params) {
  return request.get(`${prefix}/get-products-sale-count`, { params });
}

export function getProductsCount(params) {
  return request.get(`${prefix}/get-products-count`, { params });
}

export function getCatalogId(params) {
  return request.get(`${prefix}/catalogId-for-n11`, { params });
}
