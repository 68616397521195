<template>
  <b-input-group>
    <b-form-input
      v-model="displayValue"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
      :disabled="disabled"
      :class="{'border-right-0' : currency}"
      class="form-currency-input"
    >
    </b-form-input>
    <b-input-group-append  v-if="currency">
      <b-dropdown id="dropdown-currency"  size="sm" :text="currency"
                  :class="{'border-active': isInputActive}">
        <template #button-content >
          <span class="select-icon">
          {{currencies.find((x) => x.value === currency).text}}
          </span>
        </template>
          <b-dropdown-item class="select-currency"
                           :disabled="disabled"
                           @click="changeCurrency(item.value)"
                           v-for="(item, index) in getCurrencies(provider)" :key="index">
            <span class="dropdown-item-text">{{item.value}}</span>
          </b-dropdown-item>
      </b-dropdown>
    </b-input-group-append>
  </b-input-group>
</template>
<script>
import { getProviderConstantWithKeys } from '@/api/product';

export default {
  name: 'CurrencyInput',
  props: ['value', 'currency', 'disabled', 'provider'],
  data() {
    return {
      isInputActive: false,
      disabledCurrency: false,
      currencies: [
        { text: '$', value: 'USD' },
        { text: '₺', value: 'TRY' },
        { text: '€', value: 'EUR' },
      ],
      supportedCurrencies: [],
    };
  },
  methods: {
    changeCurrency(currency) {
      this.$emit('update:currency', currency);
    },
    getCurrencies(provider) {
      if (provider == null) {
        return this.currencies;
      }
      if (provider && this.supportedCurrencies.length === 0) {
        getProviderConstantWithKeys({
          provider,
          keys: ['supportedCurrencies'],
        }).then(({ data }) => {
          this.currencies.forEach((curr) => {
            if (data.supportedCurrencies.includes(curr.value)) {
              this.supportedCurrencies.push(curr);
            }
          });
        });
      }
      return this.supportedCurrencies;
    },
  },
  computed: {
    displayValue: {
      get() {
        if (this.value == null) {
          return 0;
        }
        if (this.isInputActive) {
          return this.value.toLocaleString('tr-TR');
        }
        return this.value.toLocaleString('tr-TR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      set(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace('$', '')
          .replace('₺', '').replace('€', '').replaceAll('.', '')
          .replace(',', '.'));
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.border-active{
  ::v-deep .dropdown-toggle{
    border: 1px solid #98b9f9 !important;
    border-radius: 0 !important;
  }
}
::v-deep #dropdown-currency{
  background-color: transparent;
  .btn-secondary{
    padding: 0.375rem 0.75rem !important;
  }
  .dropdown-item-text{
    padding: unset;
  }
  .dropdown-toggle{
    background-color: transparent !important;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 5px;
    min-width: 76px;
    min-height: 44px;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      width:100%;
      border: none !important;
      height: 44px;
      background-position: 12px 50% !important;
      background: url('../assets/svg/arrow-down-light.svg')  no-repeat;
    }
    .select-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-left: 4px;
      border-radius: 50%;
      color: #434545 !important;
      font-weight: 600;
      border: 1px solid #434545;
    }
  }
}

</style>
