import {
  createTicket,
  getTicketByCompanyId,
} from '@/api/customer';

export default {
  async createTicket({ commit }, payload) {
    try {
      const response = await createTicket(payload);

      commit('SET_CREATE_TICKET', response);
    } catch (e) {
      commit('SET_CREATE_TICKET_ERROR', e);
    }
  },
  async getTicketByCompanyId({ commit }, payload) {
    try {
      const response = await getTicketByCompanyId(payload);

      commit('SET_TICKET_COMPANY_SOURCE', response);
    } catch (e) {
      commit('SET_TICKET_COMPANY_SOURCE_ERROR', e);
    }
  },
};
