import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueCurrencyInput from 'vue-currency-input';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueTheMask from 'vue-the-mask';
import attributesTR from 'vee-validate/dist/locale/tr';
import attributesEn from 'vee-validate/dist/locale/en';
import VeeValidate, { Validator } from 'vee-validate';
import VueSidebarMenu from 'vue-sidebar-menu';
import VueGridLayout from 'vue-grid-layout';
import Select2 from 'v-select2-component';
// import './registerServiceWorker';
// import './service-worker';
import VueDraggableResizable from 'vue-draggable-resizable';
import VSelect from 'vue-select';
import VueApexCharts from 'vue-apexcharts';
import VueConfirmDialog from 'vue-confirm-dialog';
import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';
import VuePaycard from 'vue-paycard';
import i18n from './i18n.js';
import router from './router';
import store from './store';
import './utils/validator.js';
import { loadElements, loadFilters } from './utils/helpers';
import App from './App.vue';
import './utils/filters';

Vue.use(VueGtag, {
  includes: [
    { id: 'G-7EVWP9411R' },
  ],
  config: { id: 'UA-213622733-1' },
});
const pluginOptions = {
  globalOptions: { currency: 'TRY', locale: 'tr' },
};

// use ElementUI's plugin
Vue.use(ElementUI);
// use this package's plugin
Vue.use(ElementTiptapPlugin, {
  lang: 'en', // see i18n
  spellcheck: false,
});
Vue.use(VueGridLayout);
Vue.use(VueSidebarMenu);
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApexCharts);
Vue.use(VueTheMask);
Vue.use(VuePaycard);
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('Select2', Select2);
Vue.component('v-select', VSelect);
Vue.component('vue-draggable-resizable', VueDraggableResizable);
Vue.component('apexchart', VueApexCharts);
Vue.component('page-header', () => import('./components/PageHeader'));
Vue.component('page-title', () => import('./components/PageTitle'));
Vue.component('report-header', () => import('./components/ReportHeader'));
Vue.component('table-header', () => import('./components/TableHeader'));
Vue.component('invoice-info', () => import('./components/InvoiceInfo'));
Vue.component('market-place-buy-box', () => import('./components/MarketPlaceBuyBox'));
Vue.component('loading', () => import('./components/Loading'));

Validator.localize('tr', attributesTR);
Vue.use(VeeValidate, {
  locale: 'tr',
  inject: true,
  dictionary: {
    translationsEn: { attributes: attributesEn },
    translationsTR: { attributes: attributesTR },
  },
  fieldsBagName: 'veeFields',
});
Vue.config.productionTip = false;

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
loadElements();
loadFilters();
export default app;
