import request from '../utils/request';

const prefix = '/api/allowances';

export const getAllowances = ({ page, limit, filter }) => request.get(`${prefix}/`, { params: { page, limit, filter } });

export const getAllowanceDetail = ({ filter }) => request.get(`${prefix}/allowance-detail`, { params: { filter } });

export const getAllowanceSettlement = ({
  page, limit, provider, paymentDate,
}) => request.get(`${prefix}/allowance-settlement`, {
  params: {
    page, limit, provider, paymentDate,
  },
});

export const allowancesGroupDate = () => request.get(`${prefix}/allowances-group-date`);

export const getAllowanceSummary = () => request.get(`${prefix}/allowance-summary`);

export const getOrderNetOffs = ({
  page, limit, filter, sortBy, sortType,
}) => request.get(`${prefix}/order-net-offs`, {
  params: {
    page, limit, filter, sortBy, sortType,
  },
});
