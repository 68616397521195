export default {
  SET_IMPORT_PRODUCT_SOURCES(state, payload) {
    state.productSources.productSources = payload.productSources;
    state.productSources.totalCount = payload.totalCount;
  },
  SET_IMPORT_PRODUCT_SOURCE(state, payload) {
    state.productSource = payload;
  },
  SET_IMPORT_SOURCE_LOGS(state, payload) {
    state.sourceLogs = payload;
  },
  SET_IMPORT_PRODUCT_SOURCE_ERROR(state, payload) {
    state.error = payload;
  },
  SET_IMPORT_PRODUCT_SOURCE_SUCCESS(state, payload) {
    state.success = payload;
  },
  SET_FILE_PREVIEW_RESPONSE(state, payload) {
    state.filePreviewResponse = payload;
  },
  REFRESH_FILE_PREVIEW_RESPONSE(state, payload) {
    state.refreshFilePreviewResponse = payload;
  },
  SET_PRODUCT_SOURCE_FILE_INFO(state, payload) {
    state.productSourceFileInfo = payload;
  },
};
