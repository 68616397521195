export default {
  SET_INVOICES(state, payload) {
    state.invoices = payload;
    payload.data = payload.data.map((x) => {
      if (x.order == null) {
        x.order = {
          lines: [],
        };
      }
      if (x.order != null && x.order.packages != null && x.order.packages.length > 0) {
        x.order.packages = x.order.packages.map((w) => (
          {
            ...w,
            lines:
            x.order.lines.filter((z) => w.lines.findIndex((a) => a.lineId === z.lineId) !== -1),
          }
        ));
      }

      if (x.order != null && x.order.lines != null && x.order.lines.length > 0) {
        x.order.lines = x.order.lines.map((l) => {
          let linePackageId = null;
          if (x.order.packages != null) {
            linePackageId = x.order.packages
              .filter((p) => p.lines.findIndex((b) => l.lineId === b.lineId) !== -1);
            if (linePackageId.length > 0) {
              linePackageId = linePackageId[0].packageNumber;
            } else {
              linePackageId = null;
            }
          }
          return {
            ...l,
            /* eslint-disable max-len */
            packageId: linePackageId,
          };
        });
      }
      return x;
    });
    state.invoices = payload;
  },
  SET_ALLOWANCE(state, payload) {
    state.allowance = payload;
  },
  SET_WAREHOUSE_ERP(state, payload) {
    state.warehouseFromErp = payload;
  },
  SET_INCOME_INVOICE(state, payload) {
    state.incomeInvoices = payload;
  },
  SET_INVOICE_STATS(state, payload) {
    state.invoiceStats = payload;
  },
  SET_INVOICE_TEMPLATES(state, payload) {
    state.templates = payload;
  },
  SET_INVOICE_TEMPLATE_FILTER(state, payload) {
    const { params } = payload;
    if (params.length > 0) {
      payload.elements.filter((x) => {
        const searchedElemnt = x.elements.filter((cz) => cz.title.toLowerCase().includes(params.toLowerCase()));
        if (searchedElemnt.length > 0) {
          x.elements = searchedElemnt;
        } else {
          x.elements = [];
        }
        return x;
      });
    }
    state.templateElements = payload.elements;
  },
  SET_INVOICE(state, payload) {
    state.invoice = payload;
  },
  SET_ERP_PRODUCTS_INVOICE(state, payload) {
    state.erpProducts = payload;
  },
  SET_INVOICE_TEMPLATE_ELEMENTS(state, payload) {
    state.templateElements = payload;
  },
  SET_EINVOICE(state, payload) {
    state.einvoices = payload;
  },
  SET_INVOICE_TEMPLATE_ELEMENT(state, payload) {
    state.templateElement = payload;
  },
  SET_ACCOUNTS(state, payload) {
    state.accounts = payload;
  },
  SET_DECORATED_ACCOUNTS(state, payload) {
    state.decoratedAccounts = payload;
  },
};
