export default {
  SET_CLAIMS(state, payload) {
    state.claims.claims = payload.data;
    state.claims.totalCount = payload.totalCount;
    state.claims.limit = payload.limit;
    state.claims.totalPage = payload.totalPage;
  },
  SET_STATUSES(state, payload) {
    state.status = payload;
  },
  SET_REASONS(state, payload) {
    state.reasons = payload;
  },
  SET_APPROVE(state, payload) {
    state.approve = payload;
  },
  SET_REJECT(state, payload) {
    state.reject = payload;
  },
  SET_PROCESS(state, payload) {
    state.process = payload;
  },
};
