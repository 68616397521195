import request from '../utils/request';

const prefix = '/api/search';

// eslint-disable-next-line import/prefer-default-export
export function getSearchData(payload) {
  return request.get(`${prefix}/`, {
    params: {
      ...payload,
    },
  });
}
