import {
  createCommission,
  getCommission,
  getCommissions,
  updateCommission,
  createBasicCommission,
  updateBasicCommission,
  deleteCommission,
} from '@/api/commission';

export default {
  getCommissions({ commit }) {
    return getCommissions()
      .then((response) => {
        commit('SET_COMMISSIONS', response.data);
        return response.data;
      });
  },
  getCommission(_, id) {
    return getCommission(id)
      .then((response) => response.data);
  },
  createCommissions(_, data) {
    return createCommission(data).then((response) => response.data);
  },
  createBasicCommission(_, data) {
    return createBasicCommission(data).then((response) => response.data);
  },
  updateCommissions(_, payload) {
    return updateCommission(payload.commissionId, payload.data).then((response) => response.data);
  },
  updateBasicCommissions(_, payload) {
    return updateBasicCommission(payload.commissionId, payload.data)
      .then((response) => response.data);
  },
  deleteCommission(_, payload) {
    return deleteCommission(payload.commissionId, payload.title)
      .then((response) => response.data);
  },
};
