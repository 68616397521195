import {
  getCategories,
  getAllCategories,
  getProviderCategories,
  createCategory,
  updateCategory,
  getCategory,
} from '@/api/category';
import { toTreeArray } from '../../../utils/helpers';

export default {
  getCategory({ commit }, id) {
    return getCategory(id)
      .then((response) => {
        commit('SET_CATEGORY_ITEM', { ...response.data });
        return response.data;
      });
  },
  getCategories({ commit }, payload) {
    return getCategories(payload)
      .then((response) => {
        commit('SET_CATEGORIES', { ...response.data });
        return response.data;
      });
  },
  getAllCategories({ commit, state }, payload) {
    if (state.allCategories) {
      return {
        data: state.allCategories,
      };
    }
    return getAllCategories(payload)
      .then((response) => {
        const allCategories = toTreeArray(response.data.data.map((w) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: w._id,
          label: w.title,
          parent: w.parent == null ? 0 : w.parent,
        })));
        commit('SET_ALL_CATEGORIES', allCategories);
        commit('SET_ALL_CATEGORIES_FLAT', response.data.data);
        return response.data;
      });
  },
  getProviderCategories({ commit }, payload) {
    return getProviderCategories(payload)
      .then((response) => {
        commit('SET_PROVIDER_CATEGORIES', { ...response.data });
        return response.data;
      });
  },
  createCategory({ state }, payload) {
    return createCategory(payload)
      .then((response) => {
        state.categories.data = [];
        state.allCategories = null;
        return response.data;
      });
  },
  updateCategory({ state }, payload) {
    // eslint-disable-next-line no-underscore-dangle
    return updateCategory(payload._id, payload)
      .then((response) => {
        state.categories.data = [];
        state.allCategories = null;
        return response.data;
      });
  },
  addCategoryItem({ commit }, payload) {
    commit('ADD_CATEGORY_ITEM', payload);
  },
  resetCategoryItem({ commit }) {
    commit('RESET_CATEGORY_ITEM');
  },
  addCategoryId({ commit }, payload) {
    commit('ADD_CATEGORY_ID', payload);
  },
};
