const items = [
  { name: 'Monthly Recurring Revenue', type: 'page', link: '/reports/monthly-recurring-revenue' },
  { name: 'Revenue', type: 'page', link: '/reports/revenue' },
  { name: 'New Purchases & Trials', type: 'page', link: '/reports/new-purchases-trials' },
  { name: 'Active Subscriptions & Trials', type: 'page', link: '/reports/active-subscriptions-trials' },
  { name: 'Trial Start Rate', type: 'page', link: '/reports/trial-start-rate' },
  { name: 'Trial Conversion', type: 'page', link: '/reports/trial-conversion' },
  { name: 'Churn', type: 'page', link: '/reports/churn' },
  { name: 'Auto Renew Status', type: 'page', link: '/reports/auto-renew-status' },
  { name: 'Cohorts', type: 'page', link: '/reports/cohorts' },
];
export default items;
