export function getJsonItem(name) {
  const item = localStorage.getItem(name);
  if (item == null || item === 'undefined') {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
}

export function setJsonItem(name, value) {
  localStorage.setItem(name, JSON.stringify(value));
}
