import { getJsonItem, setJsonItem } from '../../../utils/localStorage';

export default {
  getColumn({ commit }, tableId) {
    const columns = getJsonItem(`t-${tableId}-c`);
    commit('SET_COLUMNS', { columns, tableId });
    return columns;
  },
  changeColumn({ commit }, payload) {
    setJsonItem(`t-${payload.tableId}-c`, payload.columns);
    commit('SET_COLUMNS', payload);
  },
};
