export default {
  customer: {},
  session: {},
  notificationTypes: {
    allNotification: 'Tümü',
    newOrder: 'Yeni Sipariş',
    cancelOrderByCustomer: 'Sipariş İptali',
    downFirstRankInBuyBox: 'BuyBox 1. Sıradan Düşüş',
    upFirstRankInBuyBox: 'BuyBox 1. Sıraya Çıkış',
    completeBatchRequest: 'Toplu Alım İsteği Onayı',
    changePriceWaitingForApproval: 'Fiyat Değişikliği Onayı',
    changedPriceCheck: 'Fiyat Değişikliği Uyarısı',
    sendInvoiceToERPCheck: "Faturayı ERP'ye Gönderme Uyarısı",
    sendReceiptToERPCheck: "Sipariş Fişini ERP'ye Gönderme Uyarısı",
    criticStock: 'Kritik Stok Uyarısı',
    newQuestion: 'Yeni Soru',
  },
  notificationItems: {
    sendNotification: 'Bildirim Gönder',
    sendEmail: 'E-mail Gönder',
  },
  errorsStatus: false,
  isOnline: true,
  darkMode: '',
  cleanedRxForm: null,
  varyantCollapse: false,
  numberStatus: false,
  statusUploadImage: false,
  addedAttiribute: false,
  bankTransfer: {},
  defaultDashboard: {},
  dashboardWidgets: [],
  customerDashboards: {
    customerDashboards: [],
    totalCount: 0,
  },
  companyDashboards: {
    companyDashboards: [],
    totalCount: 0,
  },
};
