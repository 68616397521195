<template>
  <div class="filterContainer d-flex align-items-center p-1">
    <div>
      <b-dropdown size="sm" right variant="link" ref="dropdown">
        <template #button-content>
          <b-icon-calendar-date class="mr-2"></b-icon-calendar-date>
          <span v-if="dateMode !== 'custom'">
            {{dropdownItemSelected}}
          </span>
          <span v-else>{{currentDate}}</span>
        </template>
        <b-dropdown-item
          v-for="dm in dateModes"
          :key="dm.key"
          :active="dateMode === dm.key"
          @click="calculateDate(dm.key)">
          {{dm.title}}
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-header>{{$i18n.t('daysFilter.specialDate')}}</b-dropdown-header>
        <b-dropdown-form>
          <date-picker
            v-model="customDate"
            :lang="$i18n.locale"
            range
            title-format="YYYY-MM-DD"
            @change="calculateDate"
            value-type="YYYY-MM-DD"
            range-separator=" - "
            :editable="false"
            :append-to-body="false"
            format="YYYY-MM-DD" ></date-picker>
        </b-dropdown-form>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/tr';
import { getJsonItem, setJsonItem } from '../../../utils/localStorage';

export default {
  name: 'rx-date-filter',
  props: ['value', 'date'],
  data() {
    return {
      customDate: null,
      dropdownItemSelected: null,
      dateFilter: {
        startDate: null,
        endDate: null,
      },
      activeFilter: null,
      dateMode: 'last28days',
      dateModes: [
        { key: 'last7days', title: this.$i18n.t('daysFilter.last7days') },
        { key: 'last28days', title: this.$i18n.t('daysFilter.last28days') },
        { key: 'last12months', title: this.$i18n.t('daysFilter.last12months') },
        { key: 'thisMonth', title: this.$i18n.t('daysFilter.thisMonth') },
        { key: 'lastMonth', title: this.$i18n.t('daysFilter.lastMonth') },
        { key: 'thisYear', title: this.$i18n.t('daysFilter.thisYear') },
        { key: 'lastYear', title: this.$i18n.t('daysFilter.lastYear') },
      ],
    };
  },
  components: {
    DatePicker,
  },
  computed: {
    currentDate() {
      const format = 'MMM DD, YYYY';
      if (this.dateFilter.startDate && this.dateFilter.endDate) {
        return `${moment(this.dateFilter.startDate).locale('tr').format(format)} - ${moment(this.dateFilter.endDate).locale('tr').format(format)}`;
      }
      return 'Invalid Date';
    },
  },
  beforeMount() {
    this.dateMode = getJsonItem('dateDropdownActive');
    if (getJsonItem('dateDropdownSelected')) {
      this.dropdownItemSelected = getJsonItem('dateDropdownSelected');
      this.$emit('change', getJsonItem('dateDropdownInfo'));
    } else if (getJsonItem('dateDropdownStartEnd')) {
      this.dropdownItemSelected = getJsonItem('dateDropdownStartEnd');
      this.$emit('change', getJsonItem('dateDropdownInfo'));
    } else {
      this.calculateDate('last28days');
    }
  },
  methods: {
    calculateDate(mode) {
      let startDate = moment().format('YYYY-MM-01');
      let endDate = moment().add(1, 'days').format('YYYY-MM-DD');
      const format = 'YYYY-MM-DD';
      if (Array.isArray(mode)) {
        this.dateMode = 'custom';
        startDate = moment(mode[0]);
        endDate = moment(mode[1]);
      } else {
        this.dateMode = mode;
        if (mode === 'last7days') {
          startDate = moment().subtract(7, 'days');
          endDate = moment().add(1, 'days');
        }

        if (mode === 'last28days') {
          startDate = moment().subtract(28, 'days');
          endDate = moment().add(1, 'days');
        }

        if (mode === 'last12months') {
          startDate = moment().subtract(12, 'months');
          endDate = moment().add(1, 'days');
        }

        if (mode === 'thisMonth') {
          startDate = moment().startOf('month');
          endDate = moment().add(1, 'days');
        }

        if (mode === 'lastMonth') {
          startDate = moment().subtract(1, 'month').startOf('month');
          endDate = moment().subtract(1, 'month').endOf('month');
        }

        if (mode === 'thisYear') {
          startDate = moment().startOf('year');
          endDate = moment().add(1, 'days');
        }

        if (mode === 'lastYear') {
          startDate = moment().subtract(1, 'year').startOf('year');
          endDate = moment().subtract(1, 'year').endOf('year');
        }
      }
      this.dateFilter.startDate = startDate.format(format);
      this.dateFilter.endDate = endDate.format(format);
      setJsonItem('dateDropdownActive', mode);
      if (this.dateMode !== 'custom') {
        this.dropdownItemSelected = this.dateModes.find((w) => w.key === this.dateMode).title;
      }
      setJsonItem('dateDropdownSelected', this.dropdownItemSelected);
      localStorage.removeItem('dateDropdownStartEnd');
      if (this.dateMode === 'custom') {
        localStorage.removeItem('dateDropdownSelected');
        setJsonItem('dateDropdownStartEnd', this.currentDate);
      }
      setJsonItem('dateDropdownInfo', this.dateFilter);
      this.$emit('change', getJsonItem('dateDropdownInfo'));
    },
  },
};
</script>
<style scoped lang="scss">
@import "styles/index";
</style>
