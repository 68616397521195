/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VeeValidate from 'vee-validate';
import * as _ from 'lodash';
import store from '@/store';
import moment from 'moment';
import chartInfos from '../resources/chartInfos.js';
import i18n from '../i18n.js';

export function loadStore() {
  const modules = [];
  const files = require.context('../store/modules/', true);
  files.keys()
    .forEach((key) => {
      if (key.indexOf('/index.js') > -1) {
        const parsedKey = key.replace('./', '').split('/');
        const moduleName = parsedKey[0];
        let modulePath = key.replace('./', '');
        modulePath = modulePath.replace('.js', '');
        // eslint-disable-next-line global-require,import/no-dynamic-require
        const required = require(`@/store/modules/${modulePath}`);
        modules[moduleName] = required.default;
      }
    });
  return modules;
}

export function loadElements() {
  const files = require.context('../components/elements/', true);
  files.keys().forEach((key) => {
    const k = key.replace('./', '');
    if (k.indexOf('/') > -1) return;
    if (_.size(files(key))) {
      Vue.component(
        files(key).default.name
        ?? key
          .split('/')
          .pop()
          .split('.')[0],
        files(key).default,
      );
    }
  });
}

export function checkPermission({ role, permission }) {
  if (Array.isArray(permission)) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const perm of permission) {
      if (perm == null || perm.length === 0) {
        return true;
      }
      if (role.isRoot === true) {
        return true;
      }
      if (role.permissions.filter((w) => w === perm).length > 0) {
        return true;
      }
      if (role.permissions.filter((w) => w === perm.split('.')[0]).length > 0) {
        return true;
      }
    }
  } else {
    if (permission == null || permission.length === 0) {
      return true;
    }
    if (role.isRoot === true) {
      return true;
    }
    if (role.permissions.filter((w) => w === permission).length > 0) {
      return true;
    }
    if (role.permissions.filter((w) => w === permission.split('.')[0]).length > 0) {
      return true;
    }
  }
  return false;
}

export function checkPackage({ fullFeatureList, module }) {
  if (module == null) {
    return true;
  }
  return fullFeatureList.filter((w) => w.key === module).length > 0;
}

export function loadSidebarMenu(name) {
  if (!name) {
    return null;
  }
  let items = require.context('../resources/sidebarItems/', true);
  items = items.keys();
  const filtered = items.filter((f) => name === f.replace('./', ''));
  if (filtered.length > 0) {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const module = require(`@/resources/sidebarItems/${name}`);
    return _.get(module, 'default');
  }
  return null;
}

export function sessionCheck(to, from, next) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = VueCookies.get(process.env.VUE_APP_TOKEN_PATH);
    if (token == null) {
      const redirectTo = to.fullPath;
      next({
        path: '/auth/login',
        query: { redirect: redirectTo },
      });
    } else {
      const isOnline = navigator?.onLine ?? true;
      if (isOnline) {
        store.dispatch('customer/me').then(() => {
          if (!store.state.customer.customer.company.isActive) {
            VueCookies.remove(process.env.VUE_APP_TOKEN_PATH);
            next({
              path: '/auth/login',
              query: { redirect: to.fullPath },
            });
          }
          const { role } = store.state.customer.customer;
          const { fullFeatureList } = store.state.customer.customer.company.currentPackage;
          const route = _.find(to.matched, (w) => w.meta != null
            && (w.meta.role != null || w.meta.module != null));
          if (store.state.customer.customer.langs) {
            i18n.locale = store.state.customer.customer.langs;
            localStorage.setItem('lang', store.state.customer.customer.langs);
            VeeValidate.Validator.locale = store.state.customer.customer.langs;
          }
          if (!checkPermission({ role, permission: _.get(route, 'meta.role') })) {
            next({
              path: '/auth/denied',
            });
          } else if (!checkPackage({ fullFeatureList, module: _.get(route, 'meta.module') })) {
            next({
              path: '/upgrade-package',
            });
          } else {
            store.dispatch('customer/errorsPageStatus', false);
            next();
          }
        }).catch((e) => {
          console.log(e);
          next({
            path: '/offline',
          });
        });
      } else {
        next({
          path: '/offline',
        });
      }
    }
  } else {
    next();
  }
}

export function loadFilters() {
  const files = require.context('../components/elements/rx-filter/filters', true);
  files.keys().forEach((key) => {
    const k = key.replace('./', '');
    if (k.indexOf('/') > -1) return;
    if (_.size(files(key))) {
      Vue.component(
        `filter-${key.split('/').pop().split('.')[0]}`,
        files(key).default,
      );
    }
  });
}

function traverse(data, parentId, i) {
  const blob = [];
  if (parentId == null || i > 100) {
    return blob;
  }
  data.filter((w) => w.parent === parentId).forEach((each) => {
    const children = traverse(data, each.id, i + 1);
    if (children.length > 0) {
      each.children = children;
    }
    blob.push(each);
  });
  return blob;
}

export function toTreeArray(data) {
  const blob = data.filter((w) => w.parent === 0).map((each) => {
    const children = traverse(data, each.id, 0);
    if (children.length > 0) {
      each.children = children;
    }
    return each;
  });
  return [{
    id: 0,
    children: blob,
    label: 'Ana Kategori',
  }];
}

export function getCategoryPath(target, data) {
  let result;
  data.some(({ id, label, children = [] }) => {
    if (id === target) result = label;
    const temp = getCategoryPath(target, children);
    if (temp) result = `${label} -> ${temp}`;
    return null;
  });
  return result;
}

export function getReportsDataForChart({
  data, segment, dataField, groupField,
}) {
  if (segment !== null) {
    return data.map((group) => ({
      name: chartInfos.segments[segment].formatter(group.name, Vue),
      group: group.name,
      data: group.data.map((item) => ({
        x: item[groupField],
        y: Math.round(item[dataField] || 0),
      })),
    }));
  }
  return [{
    name: i18n.t('reports.total'),
    data: data.map((item) => ({
      x: item[groupField],
      y: Math.round(item[dataField] || 0),
    })),
  }];
}
export function getReportsDataForMap({
  data, dataField,
}) {
  return [{
    name: 'Toplam',
    data: data.map((item) => ({
      x: item,
      y: item[dataField],
    })),
  }];
}

export const barcodeGenerator = () => {
  let randomNumber = Math.floor(100000000000 + Math.random() * 900000000000);
  randomNumber = randomNumber.toString();
  // eslint-disable-next-line no-use-before-define
  const newEanChar = getLastEan13Digit(randomNumber);
  randomNumber += newEanChar;
  return randomNumber;
};

const getLastEan13Digit = (ean) => {
  if (!ean || ean.length !== 12) throw new Error('Invalid EAN 13, should have 12 digits');
  const multiply = [1, 3];
  let total = 0;
  ean.split('').forEach((letter, index) => {
    total += parseInt(letter, 10) * multiply[index % 2];
  });
  const base10Superior = Math.ceil(total / 10) * 10;
  return base10Superior - total;
};

export const checkExtraFeatures = (key, currentPackage) => {
  const extrafeature = currentPackage.extraFeatures.find((o) => _.get(o, 'featureId.key', '') === key);
  // eslint-disable-next-line max-len
  const feature = currentPackage.features.find((o) => (o.featureId ? o.featureId.key === key : o._id === key));
  if (extrafeature || feature) return true;
  return false;
};

export const checkRequiredFields = (
  form,
  fields,
  attributes,
  variants,
  variantAttributeTitles,
  provider = null,
  isVariant = false,
) => {
  let requiredFields = fields
    .filter((d) => (form[d.key] == null
    || form[d.key].length === 0) && d.optional !== true)
    .map((d) => d.title);

  if ((form.category == null || form.category.providerId == null)
    && (provider != null && provider !== 'wix')) {
    requiredFields.push('Kategori');
  }
  requiredFields = requiredFields
    .concat((attributes || [])
      .filter((d) => d.mandatory === true
        && !variantAttributeTitles.includes(d.name)
        && form.attributes.filter((e) => e.value != null
          && e.value.length > 0
          && e.attributeName === d.name).length === 0)
      .map((d) => d.name));
  const variantFields = ['barcode', 'sku', 'stocks', 'salesPrice'];
  if (isVariant) {
    variants.forEach((variant) => {
      variantFields.forEach((field) => {
        if (variant[field] == null || variant[field] === '') {
          requiredFields.push(`${variant.sku} ilgili varyant için ${field} alanı`);
        }
      });
    });
  }
  if (requiredFields.length === 0) {
    return 'Satışa Çıkarmaya Hazır';
  }
  if (requiredFields.length > 4) {
    return `${requiredFields.slice(0, 3).join(', ')} ve ${requiredFields.length - 3} alan daha dolduruması gerekmektedir.`;
  }
  return `${requiredFields.join(',')} alanları doldurulması gerekmektedir.`;
};

export const checkRequiredFieldsForFastSale = (form, fields) => {
  const requiredFields = fields
    .filter((d) => (form[d.key] == null
      || form[d.key].length === 0) && d.optional !== true)
    .map((d) => d.title);

  if (requiredFields.length === 0) {
    return 'Satışa Çıkarmaya Hazır';
  }
  if (requiredFields.length > 4) {
    return `${requiredFields.slice(0, 3).join(', ')} ve ${requiredFields.length - 3} alan daha dolduruması gerekmektedir.`;
  }
  return `${requiredFields.join(',')} alanları doldurulması gerekmektedir.`;
};

export const convertToSaleProductObj = (product) => {
  const model = {
    product: {
      productId: product.productInfo.id,
      id: product.productInfo.id,
    },
    provider: product.provider,
    attributes: {},
  };
  product.attributes.forEach((attr) => {
    model.attributes[attr.attributeName] = attr.value;
  });
  product.fields.forEach((field) => {
    if (field.key === 'brandId') {
      model.brandId = field.defaultValue.key;
      model.brandName = field.defaultValue.value;
    } else if (field.key.substr(0, 8) === 'product.') {
      model.product[field.key.replace('product.', '')] = field.defaultValue;
    } else {
      model[field.key] = field.defaultValue;
    }
  });
  model.product.stocks = product.productInfo.stocks;
  model.categoryId = product.categoryId;
  model.variants = product.variants;
  model.mainCode = product.mainCode;
  if (model.product.sku == null && product.variants[0] != null) {
    model.product.sku = product.variants[0].sku;
  }
  return model;
};

export const convertToFastSaleProductObj = (product) => {
  const model = {
    product: {
      productId: product.productInfo.id,
      id: product.productInfo.id,
    },
    provider: product.provider,
  };
  product.fields.forEach((field) => {
    if (field.key.substr(0, 8) === 'product.') {
      model.product[field.key.replace('product.', '')] = field.defaultValue;
    } else {
      model[field.key] = field.defaultValue;
    }
  });
  return model;
};

export function sendGAEvent(gtag, category, label, value) {
  gtag.event('panel', {
    event_category: category,
    event_label: label,
    value,
  });
}
export function changeDateMode(mode) {
  let startDate = null;
  let endDate = null;
  let dateText = null;
  const format = 'YYYY-MM-DD';
  if (mode === 'last7days') {
    startDate = moment().subtract(7, 'days');
    endDate = moment().add(1, 'week');
    dateText = i18n.t('dashboards.last7days');
  }

  if (mode === 'last28days') {
    startDate = moment().subtract(28, 'days');
    endDate = moment().add(1, 'days');
    dateText = i18n.t('dashboards.last28days');
  }

  if (mode === 'last12months') {
    startDate = moment().subtract(12, 'months');
    endDate = moment().add(1, 'days');
    dateText = i18n.t('dashboards.last12months');
  }

  if (mode === 'thisMonth') {
    startDate = moment().startOf('month');
    endDate = moment().add(1, 'days');
    dateText = i18n.t('dashboards.thisMonth');
  }

  if (mode === 'lastMonth') {
    startDate = moment().subtract(1, 'month').startOf('month');
    endDate = moment().subtract(1, 'month').endOf('month');
    dateText = i18n.t('dashboards.lastMonth');
  }

  if (mode === 'thisYear') {
    startDate = moment().startOf('year');
    endDate = moment().add(1, 'days');
    dateText = i18n.t('dashboards.thisYear');
  }

  if (mode === 'lastYear') {
    startDate = moment().subtract(1, 'year').startOf('year');
    endDate = moment().subtract(1, 'year').endOf('year');
    dateText = i18n.t('dashboards.lastYear');
  }
  const result = {
    startDate,
    endDate,
    format,
    dateText,
  };
  return result;
}

export function similarity(s1, s2) {
  if (s1 == null || s2 == null) {
    return 0;
  }
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  // eslint-disable-next-line no-use-before-define
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

export function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else if (j > 0) {
        let newValue = costs[j - 1];
        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
          newValue = Math.min(Math.min(newValue, lastValue),
            costs[j]) + 1;
        }
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}
