import {
  getProductSources,
  getProductSourceById,
  createProductSource,
  updateProductSourceById,
  deleteProductSourceById,
  filePreview,
  runProductSourceRequest,
  getSourceLogs,
  getProductSourceFileInfo,
} from '@/api/imports';

export default {
  fetchProductSources({ commit }, payload) {
    return getProductSources(payload).then((response) => {
      commit('SET_IMPORT_PRODUCT_SOURCES', response.data);
    });
  },
  async fetchProductSource({ commit }, payload) {
    try {
      const response = await getProductSourceById(payload);

      commit('SET_IMPORT_PRODUCT_SOURCE', response);
    } catch (e) {
      commit('SET_IMPORT_PRODUCT_SOURCE_ERROR', e);
    }
  },
  async storeProductSource({ commit }, payload) {
    try {
      const response = await createProductSource(payload);

      commit('SET_IMPORT_PRODUCT_SOURCE_SUCCESS', response);
    } catch (e) {
      commit('SET_IMPORT_PRODUCT_SOURCE_ERROR', e);
    }
  },
  async updateProductSource({ commit }, payload) {
    try {
      const { id, params } = payload;
      const response = await updateProductSourceById(id, params);

      commit('SET_IMPORT_PRODUCT_SOURCE_SUCCESS', response);
    } catch (e) {
      commit('SET_IMPORT_PRODUCT_SOURCE_ERROR', e);
    }
  },
  async removeProductSource({ commit }, payload) {
    try {
      const response = await deleteProductSourceById(payload);

      commit('SET_IMPORT_PRODUCT_SOURCE_SUCCESS', response);
    } catch (e) {
      commit('SET_IMPORT_PRODUCT_SOURCE_ERROR', e);
    }
  },
  async fetchFilePreviewResponse({ commit }, payload) {
    try {
      const response = await filePreview(payload);

      commit('SET_FILE_PREVIEW_RESPONSE', response);
    } catch (e) {
      commit('SET_IMPORT_PRODUCT_SOURCE_ERROR', e);
    }
  },
  async runProductSource(_, payload) {
    await runProductSourceRequest(payload);
  },
  async getSourceLogs({ commit }, payload) {
    try {
      const response = await getSourceLogs(payload);
      commit('SET_IMPORT_SOURCE_LOGS', response.data);
    } catch (e) {
      commit('SET_IMPORT_PRODUCT_SOURCE_ERROR', e);
    }
  },
  getProductSourceFileInfo({ commit }, payload) {
    return getProductSourceFileInfo(payload).then((response) => {
      commit('SET_PRODUCT_SOURCE_FILE_INFO', response.data);
    });
  },
};
