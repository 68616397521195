<template>
  <div>
    <div v-if="decoratedProducts.show">
      <b-button  @click="cancelSearch" variant="light" class="position-relative btn-cancel-product">
        <span class="text-abbreviation">
          {{decoratedProducts.label}}</span>
        <span class="cancel-search"><span>&times;</span></span>
      </b-button>
    </div>
    <div class="position-relative" v-else-if="!decoratedProducts.show">
    <b-form-input type="text"
                  class="off-target-search-click form-input-search"
                  name="productId"
                  @input="onSearch"
                  @focus="setFocus(true)"
                  @click="setFocus(true)"
                  @keypress="setFocus(true)"
                  @keydown.up.exact="focusPrevious"
                  @keydown.down.exact="focusNext"
                  v-model="searchProduct"
                  :placeholder="$i18n.t('product.productSearch.placeHolder')"></b-form-input>
    <div :class="inputShow === false ? 'd-none' : 'd-flex'"
         class="product-search-dropdown off-target-search-click">
      <div v-if="loadingSearch && searchProduct.length > 2" class="text-center">
        <b-spinner label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
      </div>
      <div  v-if="searchProduct === ''">
        <ul class="mb-0 text-center">
          <li>
            {{$i18n.t('product.productSearch.first')}}
          </li>
        </ul>
      </div>
      <div v-else-if="searchProduct.length > 0 && searchProduct.length < 3">
        <ul class="mb-0 text-center">
          <li>{{$i18n.t('product.productSearch.characterText')}}</li>
        </ul>
      </div>
      <div v-else-if="loadingSearch === false" class="w-100 dark-bg-select">
        <div >
          <div class="mb-0 product-overflow-auto" ref="dropdown">
            <div v-if="!search.length"
                 class="text-not-found">
              <span>{{$i18n.t('product.productSearch.notFound')}}</span>
            </div>
              <b-button
                v-for="(searchData, index) in search"
                :key="index"
                @click="chooseSearch(searchData)"
                @keydown.up.exact="focusPrevious"
                @keydown.down.exact="focusNext"
                variant="link"
                class="product-search-dropdown-item mb-0">
                <div class="product-parent">
                  <div v-if="!miniComponent">
                    <div class="product-image-parent">
                      <img v-if="searchData.photos != null && searchData.photos.length > 0"
                           :src="searchData.photos[0].url" alt="">
                     <template v-else>
                       <img v-if="darkMode === 'light'" src="@/assets/svg/dont-photo.svg" alt="">
                       <img v-else src="@/assets/svg/dont-photo-light.svg" alt="">
                     </template>
                    </div>
                  </div>
                  <span  class="ml-3 text-abbreviation">
               {{searchData.title}}
                  </span>
                </div>
              </b-button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'OnSearch',
  props: {
    miniComponent: Boolean,
    type: String,
    showStore: Boolean,
  },
  data() {
    return {
      showText: false,
      searchProduct: '',
      inputShow: false,
      searchValue: '',
      loadingSearch: false,
      focusedIndex: -1,
      hideProduct: {
        label: null,
        code: null,
        show: false,
      },
    };
  },
  computed: {
    ...mapState({
      search: (state) => state.search.searchData,
      darkMode: (state) => state.customer.darkMode,
      decoratedProducts: (state) => state.product.decoratedProducts,
    }),
  },
  methods: {
    ...mapActions({
      updateDecoratedProducts: 'product/updateDecoratedProducts',
      getProducts: 'product/getProducts',
      getSearchData: 'search/getSearchData',
    }),
    onSearch(search) {
      this.loadingSearch = false;
      if (search.length > 2) {
        this.searchAction(search);
      }
    },
    searchAction(search) {
      setTimeout(() => {
        this.loadingSearch = true;
        this.getSearchData({ query: encodeURIComponent(search), type: this.type })
          .then(() => {
            this.loadingSearch = false;
          });
      }, 500);
    },
    offTarget(e) {
      if (!e.target.classList.contains('off-target-search-click')) {
        this.inputShow = false;
        this.focusedIndex = -1;
      }
    },
    setFocus(data) {
      setTimeout(() => {
        this.inputShow = data;
        this.focusedIndex = -1;
      }, 200);
    },
    focusPrevious() {
      this.focusedIndex -= 1;
      if (this.focusedIndex <= -1) {
        this.focusedIndex = this.$refs.dropdown.children.length;
      } else {
        this.$refs.dropdown.children[this.focusedIndex].focus();
        this.$refs.dropdown.children[this.focusedIndex].tabIndex = this.focusedIndex;
      }
    },
    focusNext() {
      this.focusedIndex += 1;
      if (this.$refs.dropdown.children.length - 1 < this.focusedIndex) {
        this.focusedIndex = -1;
      } else {
        this.$refs.dropdown.children[this.focusedIndex].focus();
        this.$refs.dropdown.children[this.focusedIndex].tabIndex = this.focusedIndex;
      }
    },
    chooseSearch(data) {
      this.showText = true;
      this.inputShow = false;
      const baseProduct = {
        label: data.title,
        // eslint-disable-next-line no-underscore-dangle
        code: data._id,
        show: true,
      };
      this.updateDecoratedProducts(baseProduct);
      this.$emit('change', this.decoratedProducts.code);
      this.$emit('change-data', data);
    },
    cancelSearch() {
      this.searchProduct = '';
      this.inputShow = true;
      const baseProductId = null;
      const baseProduct = {
        show: false,
      };
      this.updateDecoratedProducts(baseProduct);
      this.$emit('change', baseProductId);
    },
  },
  destroyed() {
    if (this.showStore !== true) {
      this.updateDecoratedProducts(this.hideProduct);
    }
  },
  mounted() {
    if (this.showStore !== true) {
      this.updateDecoratedProducts(this.hideProduct);
    }
    document.addEventListener('click', this.offTarget);
  },
  watch: {
    decoratedProducts(val) {
      if (val && val.code === null) {
        this.searchProduct = '';
      }
    },
  },
};
</script>
<style lang="scss">
.vs__selected-options{
  min-height: 40px;
}
</style>
<style lang="scss" scoped>
.btn-cancel-product{
  text-align: left;
  background-color: #e2e5e7;
  min-height: 50px;
  width: 100%;
  border-color: #e2e5e7 !important;
  &:hover{
    border-color: #e2e5e7 !important;
    opacity: 0.6;
  }
}
.form-input-search{
  min-height: 50px;
}
.text-abbreviation{
  height: auto;
  font-size: 14px;
}
.cancel-search{
  position: absolute;
  padding: 0px 6px;
  font-size: 14px;
  background-color: #8a8686;
  border-radius: 50%;
  color: white;
  z-index: 9;
  top: -13px;
  right: -8px;
}
::v-deep .search-bottom-text{
  color: #4C5862;
}
::v-deep .form-control-feedback{
  position: absolute;
  left: 10px;
  top: 100px;
  z-index: 9;
  button{
    font-size: 20px;
    color: #C2CFE0;
  }
}
.search-parent {
  margin-left: 30px;
  padding-left: 30px;
  max-width: 60vw;
  input{
    border: none;
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #90A0B7;
    }
  }
}
.product-overflow-auto{
  overflow: auto;
  max-height: 300px;
  width: 100%;
  min-height: 60px;
}
.product-search-dropdown{
  position: absolute;
  left: 0px;
  top: 50px;
  min-height: 60px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 100%;
  background-color: white;
  box-shadow: rgb(0 0 0 / 5%) -1px 20px 18px 3px;
  border-top: 0;
  z-index: 9999;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: white;
    width: 100%;
    height: auto;
    list-style: none;
    color: #323C47;
  }
  .product-search-dropdown-item{
    height: auto;
    width: 100%;
    text-align: left;
    padding: 5px;
    color: #323C47;
    border-bottom:1px solid #ececec !important;
    &:hover{
      background-color: #f0f2f8;
      outline: 0;
      box-shadow: none;
      text-decoration: none;
      border-radius: 0;
      ::v-deep .product-image-parent{
        border-color: #e3e3e3;
      }
    }
    &:focus{
      border-radius: 0;
      background-color: #f0f2f8;
      outline: 0;
      box-shadow: none;
      text-decoration: none;
      ::v-deep .product-image-parent{
        border-color: #e3e3e3;
      }
    }
  }
  .text-not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
  }
  ::v-deep .item-search *{
    color: #323C47;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }
  ::v-deep .item-search .search-bottom-text{
    color: #4C5862;
    font-weight: 400;
    font-size: 13px;
  }
}
.product-parent{
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.item-product{
  cursor: pointer;
}
.product-image-parent {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  padding: 5px;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
