import request from '../utils/request';

const prefix = '/api/route';

export function getBestSeller(params) {
  return request.get(`${prefix}/bestseller-prediction-by-companyid`, { params });
}

export function getEndorsement(params) {
  return request.get(`${prefix}/endorsement-prediction-by-companyid`, { params });
}
