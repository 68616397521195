import request from '../utils/request';

const prefix = '/api/products/commissions';

export function createCommission(payload) {
  return request.post(`${prefix}`, {
    ...payload,
  });
}

export function createBasicCommission(payload) {
  return request.post('/api/products/basic-commissions', {
    ...payload,
  });
}

export function getCommissions() {
  return request.get(`${prefix}`);
}

export function getCommission(id) {
  return request.get(`${prefix}/${id}`);
}

export function updateCommission(commissionId, payload) {
  return request.put(`${prefix}/${commissionId}`, {
    ...payload,
  });
}

export function updateBasicCommission(commissionId, payload) {
  return request.put(`/api/products/basic-commissions/${commissionId}`, {
    ...payload,
  });
}

export function deleteCommission(commissionId) {
  return request.delete(`${prefix}/${commissionId}`);
}

export function getCommissionStatuses() {
  return request.get(`${prefix}/statuses`);
}
