import VueCookies from 'vue-cookies';

export default {
  customer: (state) => state.customer,
  notificationTypes: (state) => state.notificationTypes,
  notificationItems: (state) => state.notificationItems,
  getToken() {
    return VueCookies.get(process.env.VUE_APP_TOKEN_PATH) || null;
  },
};
