import { Validator } from 'vee-validate';

Validator.extend('minValue', {
  validate: (value, args) => {
    const minValue = parseInt(args[0], 10);
    return value >= minValue;
  },
  params: ['minValue'],
});
Validator.localize({
  en: {
    messages: {
      minValue: (field) => `${field} must be greater than 0`,
    },
  },
  tr: {
    messages: {
      minValue: (field) => `${field} 0 dan büyük olmalıdır`,
    },
  },
});
