export default {
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
    state.loadMoreShouldShow = payload.data && payload.data.length > 2;
    state.skipCount = payload.limit;
  },
  SET_NOTIFICATIONS_PARENT(state, payload) {
    state.notificationsParent = payload;
    state.loadMoreShouldShowParent = payload.data && payload.data.length > 2;
    state.skipCountParent = payload.limit;
  },
  SET_NOTIFICATIONS_LOAD_MORE(state, payload) {
    state.loadMoreShouldShow = payload.data && payload.data.length > 2;
    state.skipCount += payload.limit;
    state.notifications = {
      ...state.notifications,
      data: [
        ...state.notifications.data,
        ...payload.data,
      ],
    };
  },
  SET_NOTIFICATIONS_PARENT_LOAD_MORE(state, payload) {
    state.loadMoreShouldShow = payload.data && payload.data.length > 2;
    state.skipCountParent += payload.limit;
    state.notificationsParent = {
      ...state.notificationsParent,
      data: [
        ...state.notificationsParent.data,
        ...payload.data,
      ],
    };
  },
  SET_NOTIFICATIONS_ERROR(state, payload) {
    state.error = payload;
  },
  SET_NOTIFICATION_UNREAD_COUNT(state, payload) {
    state.unreadCount = payload.data.count;
  },
};
