import {
  getPayments,
} from '@/api/payment';

export default {
  getPayments({ commit, rootState }, payload) {
    return getPayments(payload)
      .then((response) => {
        commit('SET_PAYMENTS', { ...response.data, rootState });
        return response.data;
      });
  },
};
