export default {
  orders: {
    data: [],
    count: 0,
  },
  orderBarcode: null,
  orderPackaged: null,
  orderUnPacked: null,
  cancelReason: null,
  orderCancel: null,
  cargoCompaines: null,
  changeCargoCompany: null,
  changeManyCargoCompany: null,
  orderCount: {
    count: {
      allOrder: 0,
      statusCancelled: 0,
      statusCreated: 0,
      statusDelivered: 0,
      statusPackaged: 0,
      statusShipped: 0,
      statusCargo: 0,
      waitingForPayCount: 0,
    },
  },
  order: {},
  exportOrdersData: [],
};
