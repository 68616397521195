import {
  getCompanyCustomers,
  createCompanyCustomer,
  updateCompanyCustomer,
  deleteCompanyCustomer,
} from '@/api/company-customer';

export default {
  async fetchCompanyCustomers({ commit }, payload) {
    try {
      const response = await getCompanyCustomers(payload);

      commit('SET_COMPANY_CUSTOMERS', response);
    } catch (e) {
      commit('SET_COMPANY_CUSTOMER_ERROR', e);
    }
  },
  async fetchCompanyCustomer({ commit }, payload) {
    try {
      const { data: { results } } = await getCompanyCustomers(payload);

      commit('SET_COMPANY_CUSTOMER', results[0]);
    } catch (e) {
      commit('SET_COMPANY_CUSTOMER_ERROR', e);
    }
  },
  async storeCompanyCustomer({ commit }, payload) {
    try {
      const response = await createCompanyCustomer(payload);
      commit('SET_COMPANY_CUSTOMER_SUCCESS', response);
      return response;
    } catch (e) {
      commit('SET_COMPANY_CUSTOMER_ERROR', e);
      return e;
    }
  },
  async updateCompanyCustomer({ commit }, payload) {
    const { id, params } = payload;
    const response = await updateCompanyCustomer(id, params);
    if (response.status === 200) {
      commit('SET_COMPANY_CUSTOMER_SUCCESS', response);
    } else {
      commit('SET_COMPANY_CUSTOMER_ERROR', response.status);
    }
    return response;
  },
  async removeCompanyCustomer({ commit }, payload) {
    try {
      const response = await deleteCompanyCustomer(payload);

      commit('SET_COMPANY_CUSTOMER_SUCCESS', response);
    } catch (e) {
      commit('SET_COMPANY_CUSTOMER_ERROR', e);
    }
  },
};
