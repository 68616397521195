<template>
  <div class="position-relative">
    <rx-filter-loading :loading="loading"/>
    <treeselect
      v-if="!checkStatus"
      :options="filterData"
      :defaultExpandLevel="1"
      v-model="selected"
      @input="onChange"
      :multiple="true"
      value-consists-of="LEAF_PRIORITY"
      search-nested
    />
    <b-form-checkbox
      class="checkbox-category-filter"
      v-if="!isSupplierCategory"
      id="checkbox-1"
      v-model="checkStatus"
      @input="onChangeCheck"
      name="checkbox-1"
    >
      Kategorisizleri Getir
    </b-form-checkbox>
    <slot></slot>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { mapActions, mapState } from 'vuex';
import { getJsonItem } from '../../../../utils/localStorage';
import { getCategories } from '../../../../api/imports';

export default {
  name: 'supplierCategories',
  components: { Treeselect },
  props: ['value', 'tabFilter'],
  data() {
    return {
      selected: [],
      options: [],
      loading: true,
      filterData: [],
      checkStatus: false,
      categoryFilter: [],
      isSupplierCategory: false,
    };
  },
  computed: {
    ...mapState({
      allCategories: (state) => state.category.allCategories,
    }),
  },
  methods: {
    ...mapActions({
      getAllCategories: 'category/getAllCategories',
      getCategories: 'imports/getCategories',
    }),
    onChange() {
      this.$emit('change', {
        filter: 'supplierCategories',
        data: this.selected,
      });
    },
    onChangeCheck() {
      if (this.checkStatus === true) {
        this.selected = [{ isNull: true }];
        this.$emit('change', { filter: 'supplierCategories', data: this.selected });
      } else {
        this.selected = [];
        this.$emit('change', { filter: 'supplierCategories', data: this.selected });
      }
    },
    categoryTabFilter() {
      this.categoryFilter = this.categoryTabFilter;
      this.isSupplierCategory = true;
      getCategories({
        supplierId: this.tabFilter.replace('supplier_', ''),
      }).then((response) => {
        this.options = response.data.map((r) => ({
          id: r,
          text: r,
        }));
        this.filterData = response.data.map((r) => ({
          id: r,
          label: r,
          parent: 0,
        }));
        const productFilter = getJsonItem('productFilter');
        if (productFilter != null && productFilter.categories != null) {
          this.selected = productFilter.supplierCategories;
        }
        this.loading = false;
      });
    },
  },
  beforeMount() {
    this.categoryTabFilter();
  },
};
</script>
<style lang="scss">
.checkbox-category-filter{
  margin-top: 5px;
}
</style>
