export default {
  SET_CAMPAIGNS(state, payload) {
    state.campaigns = payload;
  },
  SET_CAMPAIGN(state, payload) {
    state.campaign = payload;
  },
  SET_CAMPAIGN_ERROR(state, payload) {
    state.error = payload;
  },
  SET_CAMPAIGN_SUCCESS(state, payload) {
    state.success = payload;
  },
};
