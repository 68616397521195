import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import { sessionCheck } from '@/utils/helpers';
import dashboard from './modules/dashboard';
import authRoutes from './modules/auth';

Vue.use(VueRouter);
Vue.use(VueCookies);

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: { requiresAuth: true },
    component: () => import('@/views/layouts/default'),
    children: [
      ...dashboard,
    ],
  },
  {
    path: '/auth',
    component: () => import('@/views/layouts/public'),
    children: [
      ...authRoutes,
    ],
  },
  {
    path: '/show',
    component: () => import('@/views/layouts/public'),
    children: [
      {
        path: 'iframe-reports',
        name: 'iframeReports',
        component: () => import('@/views/pages/iframe/iframeReports'),
      },
    ],
  },
  {
    path: '/offline',
    name: 'offline',
    component: () => import('@/views/pages/errors/offline'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/pages/errors/404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});
router.beforeEach(sessionCheck);

export default router;
