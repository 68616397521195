import request from '../utils/request';

const prefix = '/api/notifications';

export const getAllNotifications = (params) => request.get(`${prefix}/`, {
  params: {
    ...params,
  },
});

export const markAllAsRead = () => request.post(`${prefix}/mark-all-notifications-as-read`);

export const getUnreadNotificationCount = () => request.get(`${prefix}/unread-count`);

export const markAsRead = (notificationId) => request.post(`${prefix}/mark-as-read`, { notificationId });

export const getAllNotifs = () => request.get(`${prefix}/all-notif-types`);

export function getUppStatus(params) {
  return request.get(`${prefix}/upp-status`, { params });
}
