<template>
  <div>
    <rx-filter-loading :loading="loading"/>
      <b-form class="d-flex"
              style="gap: 10px;
              background-color: unset !important;"
              ref="formTotalStock">
      <b-form-input
        @input="onChange"
        v-model="minStock"
        type="number"
        class="total-stock-input"
        placeholder="min Stok"></b-form-input>
      <b-form-input
        @input="onChange"
        class="total-stock-input"
        v-model="maxStock"
        type="number"
        placeholder="max Stok"></b-form-input>
      </b-form>
  </div>
</template>

<script>

import { getJsonItem } from '@/utils/localStorage';
import { mapState } from 'vuex';

export default {
  name: 'totalStocks',
  props: ['value'],
  data() {
    return {
      minStock: null,
      maxStock: null,
      loading: true,
    };
  },
  computed: {
    ...mapState({
      cleanedRxForm: (state) => state.customer.cleanedRxForm,
    }),
  },
  methods: {
    onChange() {
      if (this.minStock || this.maxStock) {
        this.$emit('change', {
          filter: 'totalStocks',
          data: {
            minStock: this.minStock,
            maxStock: this.maxStock,
          },
        });
      } else {
        this.$emit('change', { filter: 'totalStocks', data: null });
      }
    },
  },
  beforeMount() {
    const productFilter = getJsonItem('productFilter');
    if (productFilter != null && productFilter.totalStocks != null) {
      this.minStock = productFilter.totalStocks.minStock;
      this.maxStock = productFilter.totalStocks.maxStock;
      this.onChange();
    }
    this.loading = false;
  },
  watch: {
    cleanedRxForm(val) {
      if (val.reset === 'totalStocks') {
        this.$refs.formTotalStock.reset();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.total-stock-input{
  min-height: 48px;
}
</style>
