export default {
  questions: {
    questions: [],
    totalCount: null,
    limit: null,
    totalPage: null,
  },
  question: null,
  status: null,
  providers: null,
};
