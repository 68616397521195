import request from '../utils/request';

const prefix = '/api/questions';

export function getQuestions(payload) {
  return request.get(`${prefix}/companyQuestions`, {
    params: {
      ...payload,
    },
  });
}

export function setAnswer(payload) {
  return request.post(`${prefix}/answer`, {
    ...payload,
  });
}

export function getStatus() {
  return request.get(`${prefix}/statuses`);
}

export function getSupportedProviders() {
  return request.get(`${prefix}/supported-providers`);
}

export function createQuickResponse(data) {
  return request.post(`${prefix}/create-quick-response`, data);
}

export function getQuickResponse(id) {
  return request.get(`${prefix}/quick-response/${id}`);
}

export function getAllQuickResponses({ page, limit, filter }) {
  return request.get(`${prefix}/all-quick-responses`, { params: { page, limit, filter } });
}

export function updateQuickResponse(id, data) {
  return request.put(`${prefix}/quick-response/${id}`, {
    ...data,
  });
}
export function deleteQuickResponse(id) {
  return request.delete(`${prefix}/quick-response`, { params: { id } });
}
export function importQuestions(params) {
  return request.post(`${prefix}/import`, {}, { params });
}
