export default [
  {
    path: 'reports',
    meta: { requiresAuth: true },
    name: 'reports',
    component: () => import('@/views/pages/reports/index.vue'),
    redirect: 'reports/product-sales',
    children: [
      {
        path: 'product-sales',
        meta: { requiresAuth: true, role: 'reports.product-sales' },
        name: 'product-sales',
        component: () => import('@/views/pages/reports/productSales'),
      },
      {
        path: 'product-details',
        meta: { requiresAuth: true, role: 'reports.product-details' },
        name: 'product-details',
        component: () => import('@/views/pages/reports/productDetail'),
      },
      {
        path: 'product-campaign',
        meta: { requiresAuth: true, role: 'reports.product-campaign' },
        name: 'product-campaign',
        component: () => import('@/views/pages/reports/productCampaign'),
      },
      {
        path: 'product-campaign/:supportSupplierId',
        meta: { requiresAuth: true, role: 'reports.product-campaign' },
        name: 'product-campaign-detail',
        component: () => import('@/views/pages/reports/productCampaignDetail'),
      },
      {
        path: 'product-campaign/:supportSupplierId/:productId',
        meta: { requiresAuth: true, role: 'reports.product-campaign' },
        name: 'product-campaign-sellout-detail',
        component: () => import('@/views/pages/reports/productCampaignSelloutDetail'),
      },
      {
        path: 'product-stock-reports',
        meta: { requiresAuth: true, role: 'reports.product-stock-reports' },
        name: 'product-stock-reports',
        component: () => import('@/views/pages/reports/productStockReports'),
      },
      {
        path: 'city-based-reporting',
        meta: { requiresAuth: true, role: 'reports.city-based-reporting' },
        name: 'city-based-reporting',
        component: () => import('@/views/pages/reports/cityBasedReporting'),
      },
    ],
  },
];
