import request from '../utils/request';

const prefix = '/api/orders';

export function getOrders(params) {
  params.isBundleProduct = true;
  return request.get(`${prefix}`, { params });
}

export function getOrder(orderId) {
  return request.get(`${prefix}/${orderId}`);
}

export function getOrderCount(params) {
  return request.get(`${prefix}/stats`, { params });
}

export function getAllStatus() {
  return request.get(`${prefix}/statuses`);
}

export function getOrderCargoBarcode(params) {
  return request.get(`${prefix}/cargo-sticker-barcode`, { params });
}

export function getCargoCompanies() {
  return request.get(`${prefix}/cargo-company`);
}

export function changeCargoCompany(payload) {
  return request.put(`${prefix}/change-cargo-company`, { ...payload });
}

export function changeManyCargoCompany(payload) {
  return request.put(`${prefix}/change-many-cargo-company`, { ...payload });
}

export function orderToBePackaged(payload) {
  return request.post(`${prefix}/to-be-packaged`, { ...payload });
}

export function setOrderUnPackage(payload) {
  return request.post(`${prefix}/unpacked`, { ...payload });
}
export function setOrdersConfirm(payload) {
  return request.post(`${prefix}/to-be-packaged-selected-orders`, { ...payload });
}

export function getProviderCancelReason(payload) {
  return request.get(`${prefix}/provider-reasons/${payload.provider}`);
}

export function setOrderCancel(payload) {
  return request.post(`${prefix}/cancelled`, { ...payload });
}

export function setOrder(payload) {
  return request.post(`${prefix}`, { ...payload });
}

export function createQuickOrder(payload) {
  return request.post(`${prefix}/quick-order`, { ...payload });
}

export function editOrder(payload) {
  return request.put(`${prefix}/edit-order`, { ...payload });
}

export function ordersForProduct(data) {
  return request.get(`${prefix}/orders-for-product`, {
    params: data,
  });
}
export function getExportOrders() {
  return request.get(`${prefix}/export`);
}
export function exportOrders(data) {
  return request.post(`${prefix}/export`, data);
}
export function setIsPrintedInPackage(payload) {
  return request.post(`${prefix}/set-is-printed-in-package`, payload);
}

export function updateFromProvider(payload) {
  return request.post(`${prefix}/update-from-provider`, payload);
}

export function updateOrderStatusById(payload) {
  return request.post(`${prefix}/update-order-status`, { ...payload });
}

export function setHasBeenShipped(payload) {
  return request.post(`${prefix}/shipped`, { ...payload });
}

export function getLogisticProductsAcceptance(params) {
  return request.get(`${prefix}/logistic-products-acceptance`, { params });
}

export function createLogisticProductsAcceptance(payload) {
  return request.post(`${prefix}/logistic-products-acceptance`, payload);
}

export function checkOrderForLogisticOrder(payload) {
  return request.post(`${prefix}/check-order-for-logistic`, payload);
}

export function createLogisticOrder(payload) {
  return request.post(`${prefix}/send-order-by-logistic`, payload);
}

export function sendCargo(payload) {
  return request.post(`${prefix}/send-cargo`, payload);
}

export function cargoSlots(params) {
  return request.get(`${prefix}/get-cargo-delivery-slots`, { params });
}

export function cargoTypes(params) {
  return request.get(`${prefix}/get-cargo-delivery-types`, { params });
}

export function getCargoTrackings(params) {
  return request.get(`${prefix}/get-cargo-trackings`, { params });
}

export function importOrders(params, data) {
  return request.post(`${prefix}/import`, { ...data }, { params });
}

export function updateOrders(params) {
  return request.post(`${prefix}/update`, {}, { params });
}

export function updateLogisticOrders(params) {
  return request.post(`${prefix}/update-logistic-orders`, {}, { params });
}

export function getWaitingForPayOrders(params) {
  return request.post(`${prefix}/import-waiting-orders`, {}, { params });
}
