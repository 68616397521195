import request from '../utils/request';

const prefix = '/api/categories';

export function getCategory(id) {
  return request.get(`${prefix}/${id}`, { });
}

export function getAllMatchedAttr(params) {
  return request.get(`${prefix}/all-matched-attr`, { params });
}

export function getMatchedAttrDetail(id) {
  return request.get(`${prefix}/attr-match/${id}`);
}

export function getCategories(params) {
  return request.get(`${prefix}`, { params });
}

export function getAllCategories(params) {
  return request.get(`${prefix}/all`, { params });
}

export function getProviderCategories(params) {
  return request.get(`${prefix}/provider-categories/`, { params });
}

export function getECommerceCategories(params) {
  return request.get(`${prefix}/e-commerce-categories/`, { params });
}

export function getProviderAttributes(params) {
  return request.get(`${prefix}/provider-attributes/`, { params });
}
export function getProviderOptions(params) {
  return request.get(`${prefix}/provider-options/`, { params });
}
export function createMarketPlaceCategory(data) {
  return request.post(`${prefix}/market-place-categories/`, data);
}

export function getMarketPlaceCategory(id) {
  return request.get(`${prefix}/market-place-categories/${id}`);
}

export function getMarketPlaceCategories() {
  return request.get(`${prefix}/market-place-categories/`);
}

export function updateMarketPlaceCategories(id, data) {
  return request.put(`${prefix}/market-place-categories/${id}`, data);
}

export function createCategory(data) {
  return request.post(`${prefix}/`, data);
}

export function deleteCategory(id) {
  return request.delete(`${prefix}/delete-category/${id}`);
}

export function updateCategory(id, data) {
  return request.put(`${prefix}/${id}`, data);
}
