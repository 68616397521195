<template>
  <div class="quickview">
  <div
    v-show="!inputClicked"
    ref="input">
    <b-input-group>
      <b-form-input
      @click="inputClicked = !inputClicked"
      v-model="form.quantity"
      class="form-input input-content currency-input input-lg"
      style="text-align: right"
    ></b-form-input>
      </b-input-group>
   </div>
    <div class="input-frame"
         v-role="'products.change-provider-price'"
         v-show="inputClicked"
         v-closable="{
              exclude: ['input'],
              handler: 'onClose'
                    }">
      <b-input-group>
        <b-form-input
        v-model="form.quantity"
        class="form-input input-content currency-input input-lg"
        style="text-align: right"></b-form-input></b-input-group>
        <div class="button">
        <button @click="onClose" class="pp-btn tiny ghost">
          {{ $i18n.t('product.giveUp') }}
        </button>
        <button @click="updatePrice" class="pp-btn tiny primary">
              {{ $i18n.t('product.save') }}
        </button>
        </div>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';

export default {
  name: 'ProductHistory',
  props: {
    product: {},
    productId: String,
  },
  data() {
    return {
      inputFrame: 'input-frame',
      inputClicked: false,
      oldStock: 0,
      form: { quantity: 0 },
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
      productCount: (state) => state.product.productCount,
      customer: (state) => state.customer.customer,
    }),
  },
  methods: {
    ...mapActions({
      getProducts: 'product/getProducts',
      getProductCount: 'product/getProductCount',
      updateProduct: 'product/updateProduct',
      updateManyStatus: 'product/updateManyStatus',
    }),
    updatePrice() {
      const result = this.product.item.stocks.find((x) => x.isDefaultProvider === true);
      if (result) {
        result.quantity = this.form.quantity;
        const data = {
          stocks: this.product.item.stocks,
        };
        this.updateProduct({
        // eslint-disable-next-line no-underscore-dangle
          productId: this.productId,
          data,
        }).then(() => {
          this.oldStock = this.product.item.totalStocks;
          this.onClose();
          this.$emit('change-stock');
          // eslint-disable-next-line no-underscore-dangle
          this.$root.$bvToast.toast('Stok bilgisi değiştirildi', {
            title: 'Başarılı!',
            variant: 'success',
            solid: true,
          });
        });
      }
    },
    onClose() {
      this.inputClicked = false;
      this.product.item.totalStocks = this.oldStock;
    },
  },
  mounted() {
    this.oldStock = this.product.item.totalStocks;
    const result = this.product.item.stocks.find((x) => x.isDefaultProvider === true);
    if (result) {
      this.form.quantity = result.quantity;
    }
  },
};
</script>
<style lang="scss" scoped>
  .quickview{
    position: relative;
  }
  .currency-input{
    border: 1px solid transparent;
    padding: 5px;
    background-color: transparent;
    &:hover{
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    &:focus{
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  .input-frame{
    min-width: 168px;
    border-radius: 8px;
    box-shadow: 0 8px 32px 0 rgb(0 0 0 / 8%);
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
    .input-content{
      outline: none;
      width: calc(100% - 16px);
      height: 34px;
      margin: 8px 8px;
      text-align: right;
      border-radius: 8px;
      border: solid 2px var(--primary-color);
      background-color: #ffffff;
      padding-right: 12px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.24px;
      color: #484848;
    }
    button{
      margin: 0 4px;
      font-size: 12px;
      border-radius: 8px;
    }
    .button{
      margin-top: 4px;
      display: flex;
      margin-bottom: 8px;
      right: 0;
      justify-content: flex-end;
      .pp-btn {
        &.tiny {
          padding: 4px 8px 4px 8px;
        }

        &.ghost {
          background-color: #fff;
          color: #484848;
          border: solid 1px #d7d7d7;
        }

        &.primary {
          background-color: var(--primary-color);
          color: #fff !important;
          border: solid 1px var(--primary-color);
        }
      }
    }
  }
</style>
