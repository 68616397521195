<template>
  <div class="position-relative">
    <rx-filter-loading :loading="loading"/>
    <Select2
      v-model="selected"
      @change="onChange()"
      @select="onChange()"
      :options="options"
      :settings="{placeholder: 'Seçiniz'}"
    />
    <slot></slot>
  </div>
</template>

<script>
import Select2 from 'v-select2-component';
import { getJsonItem } from '../../../../utils/localStorage';

export default {
  name: 'ProviderOrder',
  components: { Select2 },
  props: ['value', 'provider'],
  data() {
    return {
      selected: [],
      options: [],
      loading: true,
    };
  },
  methods: {
    onChange() {
      this.$emit('change', { filter: `${this.provider}.providerOrder`, data: this.selected });
    },
  },
  beforeMount() {
    this.options = [
      { id: 'true', text: '1. sırada olanlar' },
      { id: 'false', text: '1. sırada olmayanlar' },
      { id: 'null', text: 'Sırası hesaplanmayanlar' },
    ];
    const productFilter = getJsonItem('productFilter');
    if (productFilter != null && productFilter[`${this.provider}.providerOrder`] != null) {
      this.selected = productFilter[`${this.provider}.providerOrder`];
      this.onChange();
    }
    this.loading = false;
  },
};
</script>
