export default {
  notifications: (state) => state.notifications,
  notificationsParent: (state) => state.notificationsParent,
  unreadCount: (state) => state.unreadCount,
  error: (state) => state.error,
  skipCount: (state) => state.skipCount,
  skipCountParent: (state) => state.skipCountParent,
  loadMoreShouldShow: (state) => state.loadMoreShouldShow,
  loadMoreShouldShowParent: (state) => state.loadMoreShouldShowParent,
};
