export default [
  {
    path: 'login',
    name: 'login',
    component: () => import('@/views/pages/auth/login'),
  },
  {
    path: '/login-as',
    name: 'loginAs',
    component: () => import('@/views/pages/loadingPage'),
  },
  {
    path: 'register',
    name: 'register',
    component: () => import('@/views/pages/auth/register'),
  },
  {
    path: 'forgot-password',
    name: 'forgotPassword',
    component: () => import('@/views/pages/auth/forgot'),
  },
  {
    path: 'reset-password',
    name: 'resetPassword',
    component: () => import('@/views/pages/auth/reset-password'),
  },
  {
    path: 'denied',
    name: 'denied',
    component: () => import('@/views/pages/auth/denied'),
  },
];
