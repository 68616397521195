<template>
  <div>
    <div
      class="w-100"
      v-if="batchRequest != null">
      <div v-if="batchRequest.status !== true">
        <b-alert :style="!modalText ? 'font-size: 10px': ''" show>
          {{batchRequest.title}} {{ $i18n.t('product.beingDone') }}</b-alert>
        <b-progress
          height="2rem"
          :style="!modalText ? 'height: 12px; font-size: 8px;' : ''"
          :max="batchRequest.totalCount">
          <b-progress-bar
            :value="batchRequest.completedCount">
          <span>{{$i18n.t('product.continue')}}
            <strong class="text-white" v-if="batchRequest.totalCount !== 0">
              {{ batchRequest.completedCount }} / {{ batchRequest.totalCount }}
            </strong>
          </span>
          </b-progress-bar>
        </b-progress>
        <div v-if="modalText" class="mt-2">{{ $i18n.t('product.estimatedRemainingTime') }}:
          <strong
            v-if="this.batchRequest.completedCount !== 0 && this.batchRequest.totalCount !== 0 ">
            {{ this.batchRequest.remainingTime | remainingDate }}
          </strong>
          <strong v-else>{{$i18n.t('product.calculatingRemainingTime')}}</strong>
        </div>
        <div v-if="modalText"
             v-html="$i18n.t('product.batchRequestCloseWindow')"
             class="w-100 mt-2">
        </div>
      </div>
      <div v-else>
        <b-alert show :style="!modalText ? 'font-size: 10px' : ''"
                 variant="success">{{batchRequest.title}}
          {{ $i18n.t('product.completed') }}</b-alert>
        <b-card v-if="options.chart.id.length"
                class="mb-4 shadow-sm"
                style="transition: 0.3s"
                no-body
        >
          <div class="d-flex justify-content-center py-3">
            <apexchart
              type="pie"
              width="450"
              height="400"
              :options="options"
              :series="series.data">
            </apexchart>
          </div>
        </b-card>
        <div v-if="batchRequest.totalCount === 0">
          {{batchRequest.title}} {{ $i18n.t('product.batchNoFound') }}
        </div>
        <div v-else-if="modalText">
          <b-row>
            <b-col lg="3">
              <b-input class="stock-height"
                       :placeholder="$i18n.t('product.stockCode')" v-model="filter.sku"/>
            </b-col>
            <b-col lg="3">
              <b-form-select class="mt-lg-0 mt-3" v-model="filter.type">
                <b-form-select-option :value="null">{{ $i18n.t('product.all') }}
                </b-form-select-option>
                <b-form-select-option v-for="(item, index) in batchInfo.filter((x) => x.id != null)"
                                      :value="item.id"
                                      :key="index">{{item.label}}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col lg="1" class="pr-xl-0">
              <b-button class="mt-lg-0 mt-3" @click="getItems(true)">
                {{ $i18n.t('product.search') }}
              </b-button>
            </b-col>
            <b-col lg="2" class="pl-xl-0">
              <b-button class="mt-lg-0 mt-3"
                        @click="handleErrorItems"
              >{{ $i18n.t('product.tryAgain') }} {{ `(${this.errorDataCount})` }}
              </b-button>
            </b-col>
            <template>
              <b-dropdown
                class="ml-auto mr-lg-4 mr-2"
                variant="primary"
                @click="handleExportProducts()"
                split :text="$i18n.t('product.export')">
                <b-dropdown-item class="font-size" v-if="exportBatchItems.length === 0">
                  <b-icon-search></b-icon-search>
                  {{ $i18n.t('product.noRecordsFound') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="request of exportBatchItems"
                  :href="request.url"
                  :key="request._id">
                  <b-icon-clock v-if="!request.isCompleted"></b-icon-clock>
                  <b-icon-check v-else></b-icon-check>
                  {{ request.fileName }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-row>
          <b-card :class="{'darkmode-padding' : darkMode === 'dark'}" class="mt-3" no-body>
            <b-table
              striped
              ref="batchReqTable"
              bordered
              :show-empty="true"
              responsive
              :empty-text="$i18n.t('generalTable.emptyText')"
              :busy="isLoading"
              :items="items"
              :fields="fields">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $i18n.t('generalTable.loading') }}</strong>
                </div>
              </template>
              <template v-slot:head(selected)>
                <span>Seç</span>
              </template>
              <template v-slot:cell(selected)="row">
                <b-checkbox
                  :disabled="!row.item.isTriable"
                  v-model="row.rowSelected"
                  @change="changeRowSelected($event, row)"
                ></b-checkbox>
              </template>
              <template v-slot:cell(productSku)="row">
                <a
                  target="_blank"
                  :href="`/products/${row.item.productId}`">{{row.item.productSku}}</a>
              </template>
              <template v-slot:cell(isSuccess)="row">
              <span v-if="batchInfo.find((x) => x.id === row.item.type)">
                {{batchInfo.find((x) => x.id === row.item.type).label}}</span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="10"
              @input="getItems(true)"
            ></b-pagination>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import {
  getBatchRequest,
  getBatchRequestItems,
  exportBatchRequestItems,
  getErrorBatchRequestItems,
  createErrorBatchRequest,
} from '../api/product';

export default {
  name: 'BatchRequestItem',
  props: {
    id: String,
    modalText: Boolean,
  },
  data() {
    return {
      currentPage: 1,
      batchRequest: null,
      items: [],
      exportBatchItems: [],
      isLoading: false,
      timeOut: null,
      errorDataCount: 0,
      totalCount: 0,
      filter: {
        title: null,
        type: null,
      },
      fields: [
        {
          key: 'productSku', sortable: false, label: this.$i18n.t('product.product'),
        },
        {
          key: 'isSuccess', sortable: false, label: this.$i18n.t('product.status'),
        },
        {
          key: 'status', sortable: false, label: this.$i18n.t('product.message'),
        },
      ],
      series: {
        data: [],
      },
      options: {
        chart: {
          width: 300,
          type: 'pie',
          id: [],
          events: {
            dataPointSelection:
              (event, chartContext, config) => {
                this.filter.type = config.w.config.chart.id[config.dataPointIndex];
                this.getItems(true);
              },
          },
        },
        labels: [],
        responsive: [{
          breakpoint: 767,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
      },
    };
  },
  computed: {
    ...mapState({
      darkMode: (state) => state.customer.darkMode,
      batchRequestModal: (state) => state.batchRequest.batchRequestModal,
      batchInfo: (state) => state.product.batchInfo,
    }),
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
  methods: {
    ...mapActions({
      getSummaryBatchRequests: 'batchRequest/getSummaryBatchRequests',
      getBatchInfo: 'product/getBatchInfo',
      getExportBatchRequestItems: 'batchRequest/getExportBatchRequestItems',
      errorBatchRequestModal: 'batchRequest/batchRequestModal',
    }),
    get() {
      getErrorBatchRequestItems({
        batchRequestId: this.id,
      }).then((errorItems) => {
        this.errorDataCount = errorItems.data.count;
      });
      this.getExportBatchRequestItems().then((response) => {
        this.exportBatchItems = response;
      });
      this.getBatch();
    },
    getBatch() {
      this.isLoading = true;
      getBatchRequest(this.id).then((resp) => {
        this.batchRequest = resp.data;
        this.totalCount = this.batchRequest.totalCount;
        const now = new Date().getTime();
        if (this.batchRequest.completedCount !== 0) {
          this.batchRequest.remainingTime = ((
            (now - this.batchRequest.startDate)
            * (this.batchRequest.totalCount - this.batchRequest.completedCount)
          ) / this.batchRequest.completedCount);
          this.isLoading = false;
        }
        if (this.batchRequest.status !== true) {
          this.timeOut = setTimeout(() => {
            this.getBatch();
          }, 3000);
        } else {
          this.batchRequestModal.end = true;
          if (this.timeOut != null) {
            clearTimeout(this.timeOut);
          }
          this.getItems();
          this.getSummaryBatchRequests({
            page: 1,
            limit: 10,
          });
          this.$root.$emit('bv::show::modal', 'batchRequestModal');
          this.$root.$bvToast.hide('miniRequestModal');
        }
      });
    },
    handleExportProducts() {
      const filter = {
        batchRequestId: this.id,
      };
      exportBatchRequestItems(filter).then(() => {
        this.$root.$bvToast.toast(this.$i18n.t('product.exportRequestToast.text'), {
          title: this.$i18n.t('product.successful'),
          variant: 'success',
          solid: true,
        });
        this.getExportBatchRequestItems().then((response) => {
          this.exportBatchItems = response;
        });
      });
    },
    handleErrorItems() {
      getErrorBatchRequestItems({
        batchRequestId: this.id,
      }).then((errorItems) => {
        createErrorBatchRequest(errorItems.data).then((batchRequest) => {
          if (batchRequest.data) {
            this.$root.$emit('bv::hide::modal', 'batchRequestModal');
            this.batchRequestId = batchRequest.data.id;
            this.errorBatchRequestModal({
              modalTitle: this.$i18n.t('product.batchStatusError'),
              isLarge: true,
              end: false,
              process: 'status',
              batchRequestId: batchRequest.data.id,
            });
          } else {
            this.$root.$bvToast.toast(this.$i18n.t('product.noHaveFaultyProductToast.text'), {
              title: 'Başarılı!',
              variant: 'success',
              solid: true,
            });
          }
        });
      });
    },
    getItems(data) {
      this.isLoading = true;
      getBatchRequestItems(this.id, this.currentPage, 10, this.filter)
        .then((resp) => {
          this.items = resp.data;
          this.isLoading = false;
          if (!data) {
            this.getBatchInfo({
              batchId: this.id,
            }).then(() => {
              this.options.labels = this.batchInfo.map((x) => `${x.label} (${x.count})`);
              this.series.data = this.batchInfo.map((x) => x.count);
              this.totalCount = _.sum(this.batchInfo.map((x) => x.count));
              this.options.chart.id = this.batchInfo.filter((x) => x.id != null).map((y) => y.id);
            });
          }
        });
    },
  },
  mounted() {
    this.getSummaryBatchRequests({
      limit: 10,
      page: 1,
    });
    this.get();
  },
};
</script>
<style lang="scss" scoped>
.darkmode-padding{
  padding: 24px;
}
.scroll-table {
  width: 100%;
  height: 200px;
  overflow: auto;
}
.stock-height{
  height: 39px;
  width: 100%;
}
</style>
