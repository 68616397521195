import {
  getCurrencies,
} from '@/api/exchange';

export default {
  getCurrencies({ commit }, payload) {
    return getCurrencies(payload).then((response) => {
      commit('SET_CURRENCY', response.data);
      return response.data;
    });
  },
};
