import {
  getAllNotifications,
  markAllAsRead,
  getUnreadNotificationCount,
  markAsRead,
} from '@/api/notifications';

export default {
  async fetchNotifications({ commit }, payload = {}) {
    try {
      const response = await getAllNotifications(payload);
      if (payload.skip) {
        commit('SET_NOTIFICATIONS_LOAD_MORE', {
          ...response,
          skip: payload.skip,
          limit: payload.limit,
        });
      } else {
        commit('SET_NOTIFICATIONS', {
          ...response,
          skip: payload.skip,
          limit: payload.limit,
        });
      }
    } catch (e) {
      commit('SET_NOTIFICATIONS_ERROR', e);
    }
  },
  async fetchParentNotifications({ commit }, payload = {}) {
    try {
      const response = await getAllNotifications(payload);
      if (payload.skip) {
        commit('SET_NOTIFICATIONS_PARENT_LOAD_MORE', {
          ...response,
          skip: payload.skip,
          limit: payload.limit,
        });
      } else {
        commit('SET_NOTIFICATIONS_PARENT', {
          ...response,
          skip: payload.skip,
          limit: payload.limit,
        });
      }
    } catch (e) {
      commit('SET_NOTIFICATIONS_ERROR', e);
    }
  },
  async handleMarkAsRead({ commit }, payload) {
    try {
      return await markAsRead(payload);
      // commit('SET_NOTIFICATIONS', response);
    } catch (e) {
      commit('SET_NOTIFICATIONS_ERROR', e);
      return false;
    }
  },
  async fetchUnreadCount({ commit }, payload = {}) {
    try {
      const response = await getUnreadNotificationCount(payload);

      commit('SET_NOTIFICATION_UNREAD_COUNT', response);
    } catch (e) {
      commit('SET_NOTIFICATIONS_ERROR', e);
    }
  },
  async markAllAsRead({ commit }, payload) {
    try {
      await markAllAsRead(payload);
    } catch (e) {
      commit('SET_NOTIFICATIONS_ERROR', e);
    }
  },
};
