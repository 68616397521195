export default {
  categories: {
    data: [],
    count: 0,
  },
  allCategories: null,
  allCategoriesFlat: null,
  providerCategories: [],
  isActive: [],
  categoryItem: {},
  categoryId: null,
};
