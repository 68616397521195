import {
  getBestSeller,
  getEndorsement,
} from '@/api/routeModule';

export default {
  getBestSeller({ commit }, payload) {
    return getBestSeller(payload)
      .then((response) => {
        commit('SET_MODULE_BESTSELLER', response.data);
        return response.data;
      });
  },
  getEndorsement({ commit }, payload) {
    return getEndorsement(payload)
      .then((response) => {
        commit('SET_MODULE_ENDORSEMENT', response.data);
        return response.data;
      });
  },
};
