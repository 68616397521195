import request from '../utils/request';

const prefix = '/api/integrations';

export function getIntegration(application) {
  return request.get(`${prefix}`, {
    params: {
      application,
    },
  });
}

export function getIntegrationLogs(applicationId) {
  return request.get(`${prefix}/logs`, {
    params: {
      applicationId,
    },
  });
}

export function updateIntegration(data) {
  return request.post(`${prefix}`, data);
}

export function getLogs(params) {
  return request.get(`${prefix}/logs`, { params });
}

export function getRequestLog(requestIds) {
  return request.get(`${prefix}/request-logs/by-ids?ids=${requestIds}`);
}

export function getReasons(payload) {
  return request.get(`${prefix}/${payload.provider}/claimReasons`);
}
