import request from '../utils/request';

const prefix = '/api/customers';

export function login(payload) {
  return request.post(`${prefix}/login`, {
    ...payload,
  });
}

export function ideaSoftApp(payload) {
  return request.post(`${prefix}/ideasoft-app`, {
    ...payload,
  });
}

export function register(payload) {
  return request.post(`${prefix}/register`, {
    ...payload,
  });
}

export function forgotPassword(payload) {
  return request.post(`${prefix}/forgot-password`, {
    ...payload,
  });
}

export function resetPassword(payload) {
  return request.post(`${prefix}/reset-password`, {
    ...payload,
  });
}

export function getCompanyCustomers(params) {
  return request.get(`${prefix}/company-customers-for-companyid`, { params });
}

export function getIdeaSoftInfo() {
  return request.get(`${prefix}/ideasoft-info`);
}

export function getAmazonTRInfo() {
  return request.get(`${prefix}/amazontr-info`);
}

export function me() {
  const params = { UIFilter: true };
  return request.get(`${prefix}/me`, { params });
}

export function updateCustomer(payload) {
  return request.put(`${prefix}/customer`, {
    ...payload,
  });
}

export function updateFeatureStatus(payload) {
  return request.put(`${prefix}/feature`, {
    ...payload,
  });
}

export function updateCompany(payload) {
  return request.put(`${prefix}/company`, {
    ...payload,
  });
}

export function getPackages() {
  return request.get(`${prefix}/packages`);
}

export function getFeatures() {
  return request.get(`${prefix}/features`);
}

export function getSendProductSupportedERP() {
  return request.get(`${prefix}/erp-send-product-support`);
}

export function getActiveAnnouncements(params) {
  return request.get(`${prefix}/active-announcements`, { params });
}

export function createTicket(data) {
  return request.post(`${prefix}/ticket`, data);
}

export function getTicketByCompanyId(params) {
  return request.get(`${prefix}/tickets-by-companyId`, { params });
}

export function getTicketByTicketId(params) {
  return request.get(`${prefix}/tickets-by-ticketId`, { params });
}

export function sendTicketMessage(data) {
  return request.post(`${prefix}/send-ticket-message`, data);
}

export function closeTicketByTicketId(data) {
  return request.post(`${prefix}/close-ticket-by-ticketId`, data);
}

export function createTransaction(data) {
  return request.post(`${prefix}/create-transaction`, data);
}

export function pay(data) {
  return request.post(`${prefix}/pay`, data);
}

export function createBankTransfer(data) {
  return request.post(`${prefix}/create-bank-transfer`, data);
}

export function setTrialer() {
  return request.post(`${prefix}/set-trialer`);
}

export function getHistories(params) {
  return request.get(`${prefix}/history`, { params });
}

export function getHistoryTypes() {
  return request.get(`${prefix}/historyTypes`);
}

export function getInstallmentInfo(params) {
  return request.get(`${prefix}/installment-info`, { params });
}

export function getBankTransferByCompany() {
  return request.get(`${prefix}/bank-transfer-by-company`);
}

export function checkCoupon(title) {
  return request.get(`${prefix}/check-coupon`, { params: { title } });
}

export function getDefaultDashboard() {
  return request.get(`${prefix}/dashboards/default-dashboard`);
}

export function getDashboardWidgets() {
  return request.get(`${prefix}/dashboards/dashboard-widgets`);
}

export function getDashboardsByCustomer() {
  return request.get(`${prefix}/dashboards/dashboards-by-customer`);
}

export function getDashboardsByCompany() {
  return request.get(`${prefix}/dashboards/dashboards-by-company`);
}

export function upsertDashboard(data) {
  return request.post(`${prefix}/dashboards/upsert-dashboard`, data);
}

export function deleteDashboardById(dashboardId) {
  return request.delete(`${prefix}/dashboards/${dashboardId}`);
}
export function verifyClient(payload) {
  return request.post(`${prefix}/verify-client`, {
    ...payload,
  });
}
