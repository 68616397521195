import {
  getClaims,
  getStatuses,
  approveClaim,
  rejectClaim,
  processClaim,
} from '@/api/claims';

import {
  getReasons,
} from '@/api/integration';

export default {
  getClaims({ commit, rootState }, payload) {
    return getClaims(payload)
      .then((response) => {
        commit('SET_CLAIMS', { ...response.data, rootState });
        return response.data;
      });
  },
  getStatuses({ commit }, payload) {
    return getStatuses(payload).then((response) => {
      commit('SET_STATUSES', response.data);
      return response.data;
    });
  },
  getReasons({ commit, rootState }, payload) {
    return getReasons(payload).then((response) => {
      commit('SET_REASONS', { ...response.data, rootState });
      return response.data;
    });
  },
  approveClaim({ commit, rootState }, payload) {
    return approveClaim(payload).then((response) => {
      commit('SET_APPROVE', { ...response.data, rootState });
      return response.data;
    });
  },
  rejectClaim({ commit, rootState }, payload) {
    return rejectClaim(payload).then((response) => {
      commit('SET_REJECT', { ...response.data, rootState });
      return response.data;
    });
  },
  processClaim({ commit, rootState }, payload) {
    return processClaim(payload).then((response) => {
      commit('SET_PROCESS', { ...response.data, rootState });
      return response.data;
    });
  },
};
