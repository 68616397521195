export default {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_CATEGORY_ITEM(state, payload) {
    state.categoryItem = payload;
  },
  SET_ALL_CATEGORIES(state, payload) {
    state.allCategories = payload;
  },
  SET_ALL_CATEGORIES_FLAT(state, payload) {
    state.allCategoriesFlat = payload;
  },
  SET_PROVIDER_CATEGORIES(state, payload) {
    state.providerCategories = payload.data;
  },
  ADD_CATEGORY_ITEM(state, payload) {
    state.categoryItem[`${payload.provider}`] = payload.data;
  },
  ADD_CATEGORY_ID(state, payload) {
    state.categoryId = payload;
  },
};
