export default {
  products: {
    data: [],
    count: 0,
  },
  productSearch: {
    data: [],
    count: 0,
  },
  providerProducts: {
    data: [],
    count: 0,
  },
  decoratedProducts: {
    label: null,
    code: null,
    show: false,
  },
  criticalStockProducts: {
    criticalStockProducts: [],
    totalCount: 0,
  },
  supplierId: null,
  changeLabelEdited: true,
  product: null,
  productCount: {},
  waitForApproveProductCount: {},
  exportRequests: [],
  exportTemplates: [],
  batchInfo: [],
  createdProduct: null,
  warehouses: {
    warehouses: [],
    totalCount: 0,
  },
  warehouse: {},
  stockTakings: {
    stockTakings: [],
    totalCount: 0,
  },
};
