export default {
  SET_QUESTIONS(state, payload) {
    state.questions.questions = payload.data;
    state.questions.totalCount = payload.totalCount;
    state.questions.limit = payload.limit;
    state.questions.totalPage = payload.totalPage;
  },
  SET_ANSWER(state, payload) {
    state.answer = payload;
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload;
  },
};
