<template>
  <div>
    <rx-filter-loading :loading="loading"/>
    <treeselect
      v-model="selected"
      @input="onChange()"
      :multiple="true"
      :valueConsistsOf="valueConsistsOf"
      search-nested
      :default-expand-level="1"
      :options="options"
    />
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getBrands } from '@/api/product';
import { getJsonItem } from '../../../../utils/localStorage';

export default {
  name: 'Brands',
  components: { Treeselect },
  props: ['value'],
  data() {
    return {
      loading: true,
      selected: [],
      valueConsistsOf: 'LEAF_PRIORITY',
      options: [{
        id: 'all',
        label: 'Tümü 🏢',
        children: [{
          id: 'children',
          label: 'Tümü 🏢',
        }],
      }],
    };
  },
  methods: {
    onChange() {
      this.$emit('change', { filter: 'brands', data: this.selected });
    },
  },
  beforeMount() {
    getBrands().then((response) => {
      this.options[0].children = response.data.map((r) => ({
        // eslint-disable-next-line no-underscore-dangle
        id: r._id,
        // eslint-disable-next-line no-underscore-dangle
        label: r._id,
      })).filter((x) => x.id != null);
      const productFilter = getJsonItem('productFilter');
      if (productFilter != null && productFilter.brands != null) {
        this.selected = productFilter.brands;
        this.onChange();
      }
      this.loading = false;
    });
  },
};
</script>
