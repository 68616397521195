import reports from './reports';
import importRoutes from './imports';

export default [
  {
    path: '/home',
    meta: { requiresAuth: true },
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard'),
  },
  {
    path: 'home/:id',
    meta: { requiresAuth: true },
    name: 'dashboardDetail',
    component: () => import('@/views/pages/dashboard'),
  },
  {
    path: 'add-new-dashboard',
    meta: { requiresAuth: true },
    name: 'addNewDashboard',
    component: () => import('@/views/pages/addNewDashboard'),
  },
  {
    path: 'upgrade-package',
    name: 'upgrade-package',
    meta: { requiresAuth: true },
    component: () => import('@/views/pages/errors/upgradePackage'),
  },
  {
    path: 'packages',
    name: 'packages',
    component: () => import('@/views/pages/support/packages/packages'),
  },
  {
    path: 'summaryOrders',
    name: 'summaryOrders',
    component: () => import('@/views/pages/orders/summary'),
  },
  {
    path: 'orders/:orderId',
    name: 'orderDetail',
    component: () => import('@/views/pages/orders/detail'),
  },
  {
    path: 'payment',
    name: 'payment',
    component: () => import('@/views/pages/support/packages/payment'),
  },
  {
    path: 'pay',
    name: 'pay',
    component: () => import('@/views/pages/support/packages/pay'),
  },
  {
    path: 'products',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'products',
    component: () => import('@/views/pages/products/list'),
  },
  {
    path: 'banned-list',
    meta: { requiresAuth: true, role: 'products.read', module: 'banned-product' },
    name: 'bannedList',
    component: () => import('@/views/pages/products/bannedList'),
  },
  {
    path: '/products/bulk-import',
    meta: { requiresAuth: true, role: 'products.add' },
    name: 'products-bulk-import',
    component: () => import('@/views/pages/products/bulkImport'),
  },
  {
    path: 'product-waitlist',
    meta: { requiresAuth: true, role: 'products.waitlist' },
    name: 'productsWaitList',
    component: () => import('@/views/pages/products/waitlist'),
  },
  {
    path: 'out-of-stock',
    meta: { requiresAuth: true, role: 'products.outOfStock' },
    name: 'outOfStock',
    component: () => import('@/views/pages/products/outOfStock'),
  },
  {
    path: 'product-request-logs',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'ProductRequestLogs',
    component: () => import('@/views/pages/products/requestLog'),
  },
  {
    path: 'product-multi-step',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'productMultiStep',
    component: () => import('@/views/pages/products/multiStepProduct'),
  },
  {
    path: 'product-pending-action',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'ProductPendingAction',
    component: () => import('@/views/pages/products/productPendingAction'),
  },
  {
    path: 'warehouse/warehouse-stock',
    meta: {
      requiresAuth: true,
      role: 'warehouse.warehouse-stock',
      module: 'warehouse-management-system',
    },
    name: 'warehouseStock',
    component: () => import('@/views/pages/warehouse/warehouseStock'),
  },
  {
    path: 'warehouse/stock-takings',
    meta: { requiresAuth: true, role: 'warehouse.stock-taking' },
    name: 'stockTaking',
    component: () => import('@/views/pages/warehouse/stockTakingList'),
  },
  {
    path: 'warehouse/stock-taking/:id',
    meta: { requiresAuth: true, role: 'warehouse.stock-taking' },
    name: 'stockTakingDetail',
    component: () => import('@/views/pages/warehouse/stockTakingDetail'),
  },
  {
    path: 'warehouse/stock-taking/final/:id',
    meta: { requiresAuth: true, role: 'warehouse.stock-taking' },
    name: 'stockTakingFinal',
    component: () => import('@/views/pages/warehouse/stockTakingFinal'),
  },
  {
    path: 'categories',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'categories',
    component: () => import('@/views/pages/categories/list'),
  },
  {
    path: 'products/bulk-sale-product',
    meta: { requiresAuth: true, role: 'products.sale' },
    name: 'bulkSaleProduct',
    component: () => import('@/views/pages/products/bulkSaleProduct'),
  },
  {
    path: 'products/productXml',
    meta: { requiresAuth: true, role: 'products.sale', module: 'dealer' },
    name: 'productXml',
    component: () => import('@/views/pages/products/xml'),
  },
  {
    path: 'products/:productId',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'productDetail',
    component: () => import('@/views/pages/products/detail'),
  },
  {
    path: 'provider-products',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'providerProducts',
    component: () => import('@/views/pages/products/providerProducts'),
  },
  {
    path: 'erp-provider-products',
    meta: { requiresAuth: true, role: 'products.read' },
    name: 'ERPProviderProducts',
    component: () => import('@/views/pages/products/erpProviderProducts'),
  },
  {
    path: 'settings',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.settings' },
    name: 'settings',
    component: () => import('@/views/pages/settings/settings'),
  },
  {
    path: 'customer/settings/company',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.company' },
    name: 'customerSettings',
    component: () => import('@/views/pages/settings/customer/detail'),
  },
  {
    path: 'customer/settings/api',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.company' },
    name: 'apiSettings',
    component: () => import('@/views/pages/settings/api/api'),
  },
  {
    path: 'customer/settings/jobs',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.company' },
    name: 'apiSettings',
    component: () => import('@/views/pages/settings/jobs/jobs'),
  },
  {
    path: 'customer/settings/company-roles',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.role' },
    name: 'companyRoles',
    component: () => import('@/views/pages/settings/role/list'),
  },
  {
    path: 'customer/settings/provider',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.provider' },
    name: 'providerSettings',
    component: () => import(/* webpackChunkName: "customerSettings" */ '@/views/pages/settings/provider/detail'),
  },
  {
    path: '/customer/settings/invoice-settings',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.provider' },
    name: 'invoiceSettings',
    component: () => import(/* webpackChunkName: "customerSettings" */ '@/views/pages/settings/invoice/detail'),
  },
  {
    path: 'customer/settings/price-change',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.price-change' },
    name: 'priceChangeSettings',
    component: () => import(/* webpackChunkName: "customerSettings" */ '@/views/pages/settings/price-change/detail'),
  },
  {
    path: 'customer/settings/exchange',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.exchange' },
    name: 'exchangeSettings',
    component: () => import('@/views/pages/settings/exchange/detail'),
  },
  {
    path: 'customer/settings/commission',
    meta: {
      requiresAuth: true, menu: 'app', role: 'settings.commission',
    },
    name: 'commission',
    component: () => import('@/views/pages/settings/commission/list'),
  },
  {
    path: 'customer/settings/commission/edit-commission/:id',
    meta: {
      requiresAuth: true, menu: 'app', role: 'settings.commission',
    },
    name: 'commissionSettings',
    component: () => import('@/views/pages/settings/commission/edit'),
  },
  {
    path: 'customer/settings/commission/add-commission/',
    meta: {
      requiresAuth: true, menu: 'app', role: 'settings.commission',
    },
    name: 'addCommission',
    component: () => import('@/views/pages/settings/commission/edit'),
  },
  {
    path: 'customer/settings/company-customers',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.customers' },
    name: 'companyCustomers',
    component: () => import('@/views/pages/settings/company-customers/list'),
  },
  {
    path: '/company-payments',
    meta: { requiresAuth: true },
    name: 'companyPayments',
    role: 'support.company-payments',
    component: () => import('@/views/pages/support/payment/list'),
  },
  {
    path: 'customer/settings/stocks',
    meta: { requiresAuth: true, menu: 'app', role: 'settings.stock' },
    name: 'stockSettings',
    component: () => import('@/views/pages/settings/stocks/detail'),
  },
  {
    path: 'warehouse/warehouses',
    meta: {
      requiresAuth: true, role: 'warehouse.warehouse', module: 'warehouse-management-system',
    },
    name: 'warehouseSettings',
    component: () => import('@/views/pages/warehouse/myWarehouses'),
  },
  {
    path: 'warehouse/logistic-products-acceptance',
    meta: {
      requiresAuth: true, role: 'settings.warehouse', module: 'warehouse-management-system',
    },
    name: 'warehouse',
    component: () => import('@/components/warehouse/logisticProductsAcceptance'),
  },
  {
    path: '/warehouse/products',
    meta: {
      requiresAuth: true, role: 'settings.warehouse', module: 'warehouse-management-system',
    },
    name: 'warehouseProducts',
    component: () => import('@/components/warehouse/warehouseProducts'),
  },
  {
    path: 'orders',
    meta: { requiresAuth: true, role: 'orders.read' },
    name: 'orders',
    component: () => import('@/views/pages/orders/list'),
  },
  {
    path: 'manuelOrderAdd',
    meta: { requiresAuth: true, role: 'manuelOrderAdd.read' },
    name: 'manuelOrderAdd',
    component: () => import('@/views/pages/orders/manuelOrderAdd'),
  },
  {
    path: 'fastManuelOrderAdd',
    meta: { requiresAuth: true, role: 'manuelOrderAdd.read' },
    name: 'fastManuelOrderAdd',
    component: () => import('@/views/pages/orders/fastManualOrderAdd'),
  },
  {
    path: 'manuelAccountAdd',
    meta: { requiresAuth: true, role: 'invoices.accounts' },
    name: 'manuelAccountAdd',
    component: () => import('@/views/pages/accounts/manualAccountAdd'),
  },
  {
    path: 'edit-order/:orderId',
    meta: { requiresAuth: true, role: 'editOrder.read' },
    name: 'editOrder',
    component: () => import('@/views/pages/orders/editOrder'),
  },
  {
    path: 'suppliers',
    meta: { requiresAuth: true, role: 'suppliers.read', module: 'product-xml' },
    name: 'suppliers',
    component: () => import('@/views/pages/suppliers/list'),
  },
  {
    path: 'suppliers/:supplierId/logs',
    meta: { requiresAuth: true, role: 'suppliers.read', module: 'product-xml' },
    name: 'system-logs',
    component: () => import('@/views/pages/suppliers/systemLogs'),
  },
  {
    path: 'suppliers',
    meta: { requiresAuth: true, role: 'suppliers.write', module: 'product-xml' },
    name: 'suppliersWrite',
    component: () => import('@/views/pages/suppliers/list'),
  },
  {
    path: 'suppliers/import-products/:id',
    meta: { requiresAuth: true, role: 'suppliers.write', module: 'product-xml' },
    name: 'import-products',
    component: () => import('@/views/pages/suppliers/products'),
  },
  {
    path: 'batch-requests',
    meta: { requiresAuth: true },
    name: 'batch-requests',
    component: () => import('@/views/pages/batchRequests/list'),
  },
  {
    path: 'batch-requests/:id',
    meta: { requiresAuth: true },
    name: 'batch-request',
    component: () => import('@/views/pages/batchRequests/list'),
  },
  {
    path: 'campaigns',
    meta: { requiresAuth: true, role: 'campaigns.read' },
    name: 'campaigns',
    component: () => import('@/views/pages/campaigns/list'),
  },
  {
    path: 'campaigns',
    meta: { requiresAuth: true, role: 'campaigns.write' },
    name: 'campaignsWrite',
    component: () => import('@/views/pages/campaigns/list'),
  },
  {
    path: 'preferences-notification',
    meta: { requiresAuth: true },
    name: 'preferences-notification',
    component: () => import('@/views/pages/preferences/notifications/form'),
  },
  {
    path: 'profile-settings',
    meta: { requiresAuth: true },
    name: 'profile-settings',
    component: () => import('@/views/pages/preferences/notifications/profile'),
  },
  {
    path: 'notification',
    meta: { requiresAuth: true },
    name: 'Notification',
    component: () => import('@/views/pages/preferences/notifications/list'),
  },
  {
    path: 'invoices',
    meta: { requiresAuth: true, role: 'invoices.read' },
    name: 'invoices',
    component: () => import('@/views/pages/invoices/list'),
  },
  {
    path: 'invoices/:invoiceId',
    meta: { requiresAuth: true, role: 'invoices.read' },
    component: () => import('@/views/pages/invoices/detail'),
  },
  {
    path: 'accounts',
    meta: { requiresAuth: true, role: 'invoices.read' },
    name: 'accounts',
    component: () => import('@/views/pages/accounts/list'),
  },
  {
    path: 'accounts/:accountId',
    name: 'accountDetail',
    component: () => import('@/views/pages/accounts/detail'),
  },
  {
    path: 'allowances',
    meta: { requiresAuth: true, module: 'vesting' },
    name: 'allowances',
    component: () => import('@/views/pages/invoices/allowances'),
  },
  {
    path: 'allowance-settlements',
    meta: { requiresAuth: true, module: 'vesting' },
    name: 'allowance-settlements',
    component: () => import('@/views/pages/invoices/allowanceSettlements'),
  },
  {
    path: 'einvoices',
    meta: { requiresAuth: true },
    name: 'einvoices',
    component: () => import('@/views/pages/invoices/einvoice'),
  },
  {
    path: 'invoice-template',
    meta: { requiresAuth: true, role: 'invoices.invoice-template' },
    name: 'invoiceTemplate',
    component: () => import('@/views/pages/templates/invoiceTemplate'),
  },
  {
    path: 'invoice-template/:id',
    meta: { requiresAuth: true, role: 'invoices.create-template' },
    name: 'createTemplate',
    component: () => import('@/views/pages/templates/createInvoiceTemplate'),
  },
  {
    path: 'cargo-templates',
    meta: { requiresAuth: true },
    name: 'cargoTemplate',
    component: () => import('@/views/pages/templates/cargoTemplate'),
  },
  {
    path: 'cargo-template/:id',
    meta: { requiresAuth: true, role: 'invoices.create-template' },
    name: 'createCargoTemplate',
    component: () => import('@/views/pages/templates/createCargoTemplate'),
  },
  {
    path: 'edit-market-place-category',
    meta: { requiresAuth: true },
    name: 'edit-market-place-category',
    component: () => import('@/views/pages/categories/marketPlaceAdd'),
  },
  {
    path: 'edit-market-place-category/:id',
    meta: { requiresAuth: true },
    name: 'edit-market-place-category-id',
    component: () => import('@/views/pages/categories/marketPlaceAdd'),
  },
  {
    path: 'market-place-categories',
    meta: { requiresAuth: true },
    name: 'market-place-category-list',
    component: () => import('@/views/pages/categories/marketPlaceList'),
  },
  {
    path: 'generate-config-product',
    meta: { requiresAuth: true },
    name: 'generate-config-product',
    component: () => import('@/views/pages/products/generateConfigProducts'),
  },
  {
    path: 'product-pending-action',
    meta: { requiresAuth: true, role: 'products.product-pending-action' },
    name: 'product-pending-action',
    component: () => import('@/views/pages/products/productPendingAction'),
  },
  {
    path: 'allowance-detail',
    meta: { requiresAuth: true },
    name: 'allowance-detail',
    component: () => import('@/views/pages/invoices/allowanceDetail'),
  },
  {
    path: 'route-module',
    meta: { requiresAuth: true },
    name: 'route-module',
    component: () => import('@/views/pages/routeModule/routeModule'),
  },
  {
    path: 'admin-proxy-list',
    meta: { requiresAuth: true },
    name: 'admin-proxy-list',
    component: () => import('@/views/pages/admin/proxy'),
  },
  {
    path: 'questions',
    meta: { requiresAuth: true, role: 'questions.read', module: 'questions' },
    name: 'questions',
    component: () => import('@/views/pages/questions/questions'),
  },
  {
    path: 'quick-responses',
    meta: { requiresAuth: true, role: 'questions.qr-read', module: 'questions' },
    name: 'quick-responses',
    component: () => import('@/views/pages/questions/quickResponses'),
  },
  {
    path: 'quick-response/createResponse',
    meta: { requiresAuth: true, role: 'questions.qr-write', module: 'questions' },
    name: 'quick-response/createResponse',
    component: () => import('@/views/pages/questions/createResponse'),
  },
  {
    path: 'quick-response/:id',
    meta: { requiresAuth: true, role: 'questions.qr-write', module: 'questions' },
    name: 'quick-response/:id',
    component: () => import('@/views/pages/questions/editResponse'),
  },
  {
    path: 'tickets',
    meta: { requiresAuth: true },
    name: 'tickets',
    role: 'support.tickets',
    component: () => import('@/views/pages/support/tickets'),
  },
  {
    path: 'tickets/createTicket',
    meta: { requiresAuth: true },
    name: 'create-ticket',
    role: 'support.create-ticket',
    component: () => import('@/views/pages/support/createTicket'),
  },
  {
    path: 'tickets/:id',
    meta: { requiresAuth: true },
    name: 'chat-ticket-id',
    role: 'support.tickets',
    component: () => import('@/views/pages/support/chatSupport'),
  },
  {
    path: 'claims',
    meta: { requiresAuth: true, module: 'claim' },
    name: 'claims',
    component: () => import('@/views/pages/claims/claims'),
  },
  {
    path: 'history/',
    meta: { requiresAuth: true },
    name: 'history',
    component: () => import('@/views/pages/history/list'),
  },
  {
    path: 'history/:id',
    meta: { requiresAuth: true },
    name: 'historyDetail',
    component: () => import('@/views/pages/history/list'),
  },
  {
    path: 'categories/add',
    meta: { requiresAuth: true },
    name: 'add-category',
    component: () => import('@/views/pages/categories/edit'),
  },
  {
    path: 'categories/:id',
    meta: { requiresAuth: true },
    name: 'edit-category',
    component: () => import('@/views/pages/categories/edit'),
  },
  {
    path: 'variant-products/:id',
    meta: { requiresAuth: true },
    name: 'variant-products',
    component: () => import('@/views/pages/products/variantProducts'),
  },
  {
    path: 'apps',
    meta: { requiresAuth: true },
    name: 'apps',
    component: () => import('@/views/pages/apps/apps'),
  },
  {
    path: 'my-apps',
    meta: { requiresAuth: true },
    name: 'my-apps',
    component: () => import('@/views/pages/apps/my-apps'),
  },
  {
    path: 'search',
    meta: { requiresAuth: true },
    name: 'search',
    component: () => import('@/views/pages/searchResultPage'),
  },
  {
    path: 'search',
    meta: { requiresAuth: true },
    name: 'search',
    component: () => import('@/views/pages/searchResultPage'),
  },
  ...importRoutes,
  ...reports,
];
