<template>
  <div>
        <div id="my-strictly-unique-vue-upload-multiple-image"
             :style="isSingle ? 'margin-top: 0;': 'margin-top: 60px'"
             class="d-flex flex-column align-items-center"
             style="display: flex; justify-content: center;">
          <vue-upload-multiple-image
            @upload-success="uploadImageSuccess"
            @before-remove="beforeRemove"
            @edit-image="editImage"
            :dataImages="photos"
            @mark-is-primary="markIsPrimary"
            :multiple="multiple"
            idUpload="myIdUpload"
            :max-image="maxImage"
            :dragText="$i18n.t('product.dragText')"
            :browseText="$i18n.t('product.browseText')"
            :primary-text="$i18n.t('product.uploadPrimaryText')"
            :popup-text="$i18n.t('product.uploadPopupText')"
            :drop-text="$i18n.t('product.uploadDropText')"
            :markIsPrimaryText="$i18n.t('product.uploadMarkIsPrimaryText')"
            editUpload="myIdEdit"
            class="my-edit-upload"
          ></vue-upload-multiple-image>
          <b-progress size="sm"
                      v-if="statusUploadImage && maxLoadingTime !== 0 && loadingTime !== 0"
                      class="mt-2"
                      style="max-width: 100%; width: 185px"
                      :max="maxLoadingTime" height="0.65rem">
            <b-progress-bar :value="loadingTime" :label="`${((loadingTime / maxLoadingTime) * 100)
      .toFixed(2)}%`"></b-progress-bar>
          </b-progress>
  </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import VueUploadMultipleImage from 'vue-upload-multiple-image';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { getS3 } from '../../../api/upload';

export default {
  name: 'Upload',
  components: { VueUploadMultipleImage },
  props: {
    photos: Array,
    isSingle: Boolean,
  },
  data() {
    return {
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 5,
      multiple: true,
      s3Image: this.photos,
      file: null,
      maxImage: 20,
    };
  },
  computed: {
    ...mapState({
      statusUploadImage: (state) => state.customer.statusUploadImage,
      categories: (state) => state.product.categories,
    }),
  },
  methods: {
    ...mapActions({
      loadingUploadImage: 'customer/loadingUploadImage',
      createProduct: 'product/createProduct',
    }),
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    uploadImageSuccess(formData, index, fileList) {
      this.startLoading(fileList.length);
      this.loadingUploadImage(true);
      this.uploadStatus = true;
      const that = this;
      const file = fileList[index];
      const s3Params = {
        filename: file.name,
        bucketName: 'pp-photos2',
        fileType: file.path.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0],
      };
      setTimeout(function () {
        getS3(s3Params).then((res) => {
          const signedUrl = res.data.signedRequest;
          const { url } = res.data;
          console.log('s3Params', s3Params);
          console.log(file);
          console.log(index);
          that.s3Image = that.photos;

          const block = file.path.split(';');

          const contentType = block[0].split(':')[1];// In this case "image/gif"

          const realData = block[1].split(',')[1];//
          const blobData = that.b64toBlob(realData, contentType);
          console.log('signedUrl', signedUrl);
          axios.put(signedUrl, blobData, {
            headers: {
              'Content-Type': s3Params.fileType,
              'x-amz-acl': 'public-read',
            },
          }).then((response) => {
            console.log('urll', url);
            if (that.photos.length === fileList.length - 1) {
              that.loadingTime = fileList.length - 1;
              that.loadingUploadImage(false);
            }
            if (response.status === 200) {
              that.s3Image.push({
                path: `https://pp-photos2.s3.fr-par.scw.cloud/${url}`,
              });
            } else {
              this.$root.$bvToast.toast('Resim başarıyla eklendi', {
                title: 'Başarılı!',
                variant: 'success',
                solid: true,
              });
            }
          });
        });
      }, 1000 * index);
    },
    beforeRemove(index, done) {
      this.$confirm(
        {
          message: 'Seçili olan görseli silmek istediğinize emin misiniz?',
          title: 'Resim silinecek emin misiniz?',
          button: {
            no: 'Hayır',
            yes: 'Evet',
          },
          /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: (confirm) => {
            if (confirm) {
              done();
              this.photos.splice(index, 1);
              this.$emit('change-photo', this.photos);
            }
          },
        },
      );
    },
    editImage(formData, index, fileList) {
      this.uploadImageSuccess(formData, index, fileList);
    },
    markIsPrimary(index, fileList) {
      this.$emit('change-photo', fileList);
    },
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    startLoading(dataCount) {
      this.maxLoadingTime = dataCount;
      this.loading = true;
      this.loadingTime = 0;
    },
  },
  created() {
    this.$_loadingTimeInterval = null;
    if (this.isSingle) {
      this.multiple = false;
    }
  },
  mounted() {
    this.startLoading();
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();
        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            // eslint-disable-next-line no-plusplus
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .my-edit-upload{
  .image-overlay{
    background-color: rgba(243, 243, 243, 0.8);
  }
}
  #my-strictly-unique-vue-upload-multiple-image {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  h1, h2 {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
