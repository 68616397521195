export default {
  suppliers: (state) => state.suppliers,
  suppliersForTreeSelect: (state) => state.suppliers.data && state.suppliers.data.results
    .map((node) => ({
      id: node.id,
      label: node.supplierName,
    })),
  supplier: (state) => state.supplier,
  error: (state) => state.error,
  success: (state) => state.success,
  cities: (state) => state.cities,
  districts: (state) => state.districts,
  providerFields: (state) => state.providerFields,
};
