<template>
  <div  class="filterContainer d-flex align-items-center">
    <div>
      <b-button class="btn-sidebar-open"
                variant="link"
                @click="optionsFilter()"
                v-b-toggle.sidebar-right>
        <b-badge class="badge-selected bg-primary mr-1"
                 v-if="Object.keys(selectedFilters).length > 0">
          {{Object.keys(selectedFilters).length}}</b-badge>
        <b-icon icon="filter" class="icon-back-filter"></b-icon>
        {{$i18n.t('product.toFilter')}}
      </b-button>
      <b-sidebar
        :backdrop-variant="variant"
        backdrop
        id="sidebar-right" title="" sidebar-class="sidebar-filter-right" right shadow>
        <div class="px-3 pt-3 pb-5">
          <template>
            <span class="allClear" @click="allClear">
             <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
             <span class="ml-1"> {{$i18n.t('product.allClean')}}</span>
            <b-badge class="badge-selected"
                     v-if="Object.keys(selectedFilters).length > 0">
              {{Object.keys(selectedFilters).length}}</b-badge>
            </span>
          </template>
          <div class="mt-4">
              <b-list-group class="filter-group-sidebar">
                <b-list-group-item
                  class="py-3 filter-group-item"
                   v-for="(filterItem,index)
                   in filterOptionList"
                  :key="index">
                  <template v-if="filterItem.options == null">
                    <div v-b-toggle.sidebar-right-two
                          @click="handleAddFilters(filterItem.value)">
                      <div class="d-flex justify-content-between w-100 ">
                     {{filterItem.text}}
                        <template >
                          <div class="d-flex">
                            <span class="add-text"
                                  v-if="selectedFiltersList.indexOf(filterItem.value) != -1">
                            </span>
                            <b-icon v-if="selectedFilters[filterItem.value]"
                                    class="ml-2 check-circle"
                                    icon="check-circle-fill">
                            </b-icon>
                            <b-icon  v-else
                                     class="ml-2 arrow-right"
                                     icon="arrow-right-square">
                            </b-icon>
                          </div>
                        </template>
                        </div>
                    </div>
                  </template>
                  <template v-else>
                    <strong>{{filterItem.text}}</strong>
                    <b-list-group-item
                      class="border-0 pr-0 filter-group-item-child" v-b-toggle.sidebar-right-two
                      @click="handleAddFilters(optionItem.value)"
                        v-for="(optionItem,index) in filterItem.options" :key="index">
                      <div class="w-100 d-flex justify-content-between">
                        {{optionItem.text}}
                        <template >
                          <div>
                            <span class="add-text"
                                  v-if="selectedFiltersList.indexOf(optionItem.value) != -1">
                            </span>
                            <b-icon v-if="selectedFilters[optionItem.value]"
                                    class="ml-2 check-circle"
                                    icon="check-circle-fill">
                            </b-icon>
                            <b-icon  v-else
                                     class="ml-2 arrow-right"
                                     icon="arrow-right-square">
                            </b-icon>
                          </div>
                        </template>
                         </div>
                    </b-list-group-item>
                  </template>
                </b-list-group-item>
              </b-list-group>
          </div>
          <div class="sidebar-filter-bottom d-flex " style="gap: 10px">
            <b-button
              variant="light"
              class="w-100" @click="allClear"
              v-b-toggle.sidebar-right> {{$i18n.t('product.giveUp')}}</b-button>
            <b-button :disabled="!Object.keys(selectedFilters).length > 0"
                      @click="apply"
                      variant="primary"
                      class="w-100" v-b-toggle.sidebar-right>
              {{$i18n.t('product.apply')}}
            </b-button>
          </div>
        </div>
      </b-sidebar>
      <b-sidebar
        :backdrop-variant="variantTwo"
        backdrop
        id="sidebar-right-two"  sidebar-class="sidebar-filter-right"
        right shadow>
        <template #header="{ hide }">
          <div  class="w-100 d-flex  align-items-center py-3">
            <span>
              <b-icon @click="hide" icon="arrow-left-circle-fill" class="icon-back-filter"></b-icon>
            </span>
          </div>
        </template>
        <div class="px-3 py-2">
          <hr />
          <b-row
            :key="index"
            class="mt-2"
            v-for="(selectedFilter, index) in selectedFiltersList">
            <template v-if = "virtualData === selectedFilter">
              <b-col md="12" class="py-2">
                <span>{{getTitleByFilter(type, selectedFilter)}}</span>
              </b-col>
              <b-col :cols="10">
                <component
                  ref="selectedInput"
                  class="selected-input"
                  :tabFilter="tabFilter"
                  @change-search="handleFilterChange"
                  @change="handleFilterChange"
                  @select="handleFilterChange(filterItem)"
                  :provider="getProviderNameByFilter(selectedFilter)"
                  :is="'filter-' + getFilterNameByFilter(selectedFilter)">
                </component>
              </b-col>
              <b-col :cols="2" class="pl-0">
                <b-button
                  variant="danger"
                  class="w-100 h-100"
                  style="max-height: 50px"
                  @click="removeFilter(selectedFilter)">
                  <i class="fa fa-trash" style="font-size: 16px;"></i>
                </b-button>
              </b-col>
            </template>
          </b-row>
          <hr />
          <div class="sidebar-filter-bottom d-flex " style="gap: 10px">
              <b-button
                variant="light"
                class="w-100"
                @click="giveUp(virtualData)"
                v-b-toggle.sidebar-right-two>
                {{$i18n.t('product.giveUp')}}
              </b-button>
            <b-button
              :disabled="!Object.keys(selectedFilters).length > 0"
              @click="apply(virtualData)"
              v-b-toggle="['sidebar-right', 'sidebar-right-two']"
              variant="primary"
              class="w-100"
              id="sidebar-two-close"
            >
              {{$i18n.t('product.apply')}}
            </b-button>
          </div>
        </div>
      </b-sidebar>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import chartFilters from '@/resources/chartFilters';
import { mapState, mapActions } from 'vuex';
import { getJsonItem, setJsonItem } from '../../../utils/localStorage';

export default {
  name: 'rx-filter',
  props: ['type', 'value', 'providers', 'currentSorting', 'activeTab'],
  data() {
    return {
      variantTwo: 'transparent',
      variant: 'dark',
      selectedFilters: {},
      selectedFiltersList: [],
      chartFilters: {},
      filterItem: {},
      activeItem: {},
      virtualData: [],
      controlData: [],
      baseProduct: {
        show: false,
      },
      deleteItem: [],
      selected: [],
      tabFilter: null,
      filter: {},
      selectedFilter: [],
      optionItem: {},
      currentFilter: {},
      filterOptions: [],
      isThereFilter: false,
      excludeFields: ['stockType', 'onlyInStock', 'inputSearch'],
    };
  },
  computed: {
    filterOptionList() {
      if (this.tabFilter === 'all' || this.tabFilter === 'default') {
        return this.filterOptions.filter((x) => x.value !== 'supplierCategories');
      }
      return this.filterOptions;
    },
    filters() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.chartFilters = chartFilters;
      if (chartFilters[this.type]) {
        return Object.keys(chartFilters[this.type]).filter((f) => f !== 'providers' && this.selectedFiltersList.indexOf(f) === -1);
      }
      return [];
    },
    chartFiltersProviders() {
      if (chartFilters[this.type] && chartFilters[this.type].providers) {
        return Object.keys(chartFilters[this.type].providers);
      }
      return [];
    },
    ...mapState({
      customer: (state) => state.customer.customer,
    }),
  },
  methods: {
    ...mapActions({
      rxResetForm: 'customer/rxResetForm',
      updateDecoratedProducts: 'product/updateDecoratedProducts',
    }),
    allClear() {
      this.selectedFiltersList = [];
      this.selectedFilters = {};
      this.$emit('change', this.selectedFilters);
      this.updateDecoratedProducts(this.baseProduct);
    },
    handleAddFilters(filter) {
      const index = this.selectedFiltersList.indexOf(filter);
      this.isThereFilter = false;
      if (index === -1) {
        this.selectedFiltersList.push(filter);
        this.virtualData = filter;
      } else {
        this.virtualData = filter;
        setJsonItem('productFilter', this.selectedFilters);
      }
      this.optionsFilter();
    },
    optionsFilter() {
      this.tabFilter = getJsonItem(`${window.location.pathname}activeTab`);
    },
    giveUp(filter) {
      this.$delete(this.selectedFilters, filter);
      this.selectedFiltersList.splice(filter, 1);
      this.$emit('change', this.selectedFilters);
      const isItCorrect = 'baseProductId';
      if (filter === isItCorrect) {
        this.updateDecoratedProducts(this.baseProduct);
      }
    },
    removeFilter(filter) {
      this.$delete(this.selectedFilters, filter);
      this.$refs.selectedInput[0].selected = [];
      this.rxResetForm({ reset: filter });
      this.isThereFilter = true;
      this.$emit('change', this.selectedFilters);
      const isItCorrect = 'baseProductId';
      if (filter === isItCorrect) {
        this.updateDecoratedProducts(this.baseProduct);
      }
    },
    handleFilterChange(data) {
      if (data.data === null || data.data.length === 0) {
        this.$delete(this.selectedFilters, data.data);
        this.$delete(this.selectedFilters, data.filter);
        this.$emit('change', this.selectedFilters);
      } else {
        this.$set(this.selectedFilters, data.filter, data.data);
      }
    },
    getFilterNameByFilter(filterName) {
      return filterName.split('.').length === 1
        ? filterName : filterName.split('.')[1];
    },
    getProviderNameByFilter(filterName) {
      return filterName.split('.').length === 1 ? null : filterName.split('.')[0];
    },
    getTitleByFilter(type, filterName) {
      const provider = this.getProviderNameByFilter(filterName);
      if (provider == null) {
        return this.chartFilters[type][filterName];
      }
      return this.chartFilters[type].providers[this.getFilterNameByFilter(filterName)]
        .replace('{provider}', Vue.filter('providerName')(provider === 'ecommercePlatform' ? this.customer.company.defaultProvider : provider));
    },
    apply() {
      this.$emit('change', this.selectedFilters);
    },
  },
  created() {
    localStorage.removeItem('productFilter');
    if (getJsonItem('productFilter') != null) {
      const storeChartFilters = Object.keys(getJsonItem('productFilter'));
      this.chartFilters = storeChartFilters
        .filter((w) => !this.excludeFields.includes(w))
        .reduce((obj, key) => {
          obj[key] = storeChartFilters[key];
          return obj;
        }, {});
    }
    this.filterOptions = this.filters.map((w) => ({
      text: this.chartFilters[this.type][w],
      value: w,
    }));
    if (this.chartFiltersProviders.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const provider of this.providers) {
        this.filterOptions.push({
          text: Vue.filter('providerName')(provider === 'ecommercePlatform' ? this.customer.company.defaultProvider : provider),
          options: this.chartFiltersProviders
            .filter((w) => this.selectedFiltersList.indexOf(`${provider}.${w}`) === -1).map((filter) => ({
              text: this.getTitleByFilter(this.type, `${provider}.${filter}`),
              value: `${provider}.${filter}`,
            })),
        });
      }
    }
  },
  watch: {
    activeTab(val) {
      const isThereSupplier = Object.keys(this.selectedFilters).includes('supplierCategories');
      if (val && isThereSupplier) {
        this.removeFilter('supplierCategories');
      }
    },
  },
};
</script>
<style lang="scss">
@import "styles/index";
.filter-row{
  margin-top: -20px;
  margin-left: 20px;
  .title{
    font-weight: bold;
    margin-bottom: 5px;
  }
}
.dropdown-menu{
  z-index: 9999;
}
.filterContainer .dropdown-menu {
  width: 600px !important;
}
.sidebar-filter-right{
  max-width: 100% !important;
  width: 400px !important;
  transition: 0.6s !important;
}
.arrow-right{
  min-width: 19px;
  min-height: 19px;
}
.check-circle{
  min-width: 20px;
  min-height: 20px;
  fill:#28a745;
}
.icon-back-filter{
  cursor: pointer;
  fill:gray;
  margin-right: 2px;
  width: 22px;
  height: 22px;
  &:hover{
    fill:black;
    transition: 0.6s;
  }
}
.btn-give-up{
  background-color: gray;
  border: none;
  &:hover{
    transition: 1s;
  }
}
.btn-apply{
  width: 100%;
  background-color: var(--primary) !important;
  border: none;
  &:focus{
    background-color: #28a745 !important;
  }
  &:hover{
    transition: 1s;
    background-color: #28a745;
  }
}
.btn-sidebar-open{
  background-color: transparent;
  border: none;
  color: var(--primary-link) !important;
  border-radius: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  svg{
    fill: var(--primary);
  }
  &:hover{
    transition: 0.5s;
    text-decoration: underline;
    svg{
      fill: #955400;
      transition: 0.5s;
    }
  }
}
.modal-body .removeFilter{
  height: 32px;
}
.removeFilter{
  height: 47px;
  width: 100%;
  border: none;
  background-color: gray;
  &:hover{
    background-color: black;
  }
}
.allClear{
  cursor: pointer;
  font-size: 15px;
  color:gray;
  display:inline-flex;
  align-items: center;
  margin-top: 5px;
  transition: color 0.6s;
  .badge-selected{
    margin-left: 5px;
    padding: 5px;
    margin-top: 1px;
    background-color:var(--primary);
    transition: background-color 0.6s;
  }
  &:hover{
    color: var(--primary);
    transition: 0.6s;
    .badge-selected{
      background-color: var(--primary);
      transition: 0.6s;
    }
  }
}
.add-text{
  color:green;
}
.filter-group-sidebar{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  max-height: 458px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .filter-group-item{
   &:hover{
     background-color: #eeeeee;
     transition: 0.5s;
     color: darkgray;
     strong{
       color: black;
     }
     .filter-group-item-child{
       background-color: #eeeeee;
       transition: 0.5s;
       color: black;
       &:hover{
         color: darkgray;
       }
     }
    }
  }
}
.sidebar-filter-bottom{
  margin-top: 35px;
}
</style>
