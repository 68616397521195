export default {
  SET_COMPANY_CUSTOMERS(state, payload) {
    state.companyCustomers = payload;
  },
  SET_COMPANY_CUSTOMER(state, payload) {
    state.companyCustomer = payload;
  },
  SET_COMPANY_CUSTOMER_ERROR(state, payload) {
    state.error = payload;
  },
  SET_COMPANY_CUSTOMER_SUCCESS(state, payload) {
    state.success = payload;
  },
};
