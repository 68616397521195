import request from '../utils/request';

const prefix = '/api/orders';

export function getClaims(payload) {
  return request.get(`${prefix}/claims`, {
    params: {
      ...payload,
    },
  });
}

export function getStatuses(payload) {
  return request.get(`${prefix}/claims-statuses`, {
    ...payload,
  });
}

export function approveClaim(payload) {
  return request.post(`${prefix}/approve-claim`, {
    ...payload,
  });
}

export function rejectClaim(payload) {
  return request.post(`${prefix}/reject-claim`, {
    ...payload,
  });
}

export function processClaim(payload) {
  return request.post(`${prefix}/process-claim`, payload);
}
