import request from '../utils/request';

const prefix = '/api/imports';

export const getProductSources = ({ page, limit, filter }) => request.get(`${prefix}/product-sources`, { params: { page, limit, filter } });

export const getProductSourceById = (id) => request.get(`${prefix}/${id}`);

export const createProductSource = (params) => request.post(`${prefix}`, params);

export const updateProductSourceById = (id, params) => request.put(`${prefix}/${id}`, params);

export const deleteProductSourceById = (id) => request.delete(`${prefix}/${id}`);

export const runProductSourceRequest = (id) => request.post(`${prefix}/${id}/run`);

export const getImportProductsBySupplier = async (params) => {
  const data = await request.get(`${prefix}/import-products`, { params });
  return data.data;
};

export const getCategories = ({ supplierId }) => request.get(`${prefix}/categories`, {
  params: {
    supplierId,
  },
});

export const getSourceLogs = ({ supplierId, page, limit }) => request.get(`${prefix}/source-logs`, {
  params: { supplierId, page, limit },
});

export const getProductSourceFileInfo = ({
  supplierId, url, title, ip,
}) => request.get(`${prefix}/product-source-file-info`, {
  params: {
    supplierId, url, title, ip,
  },
});

export const filePreview = (data) => {
  const page = data.page || 1;
  const limit = data.limit || 10;

  delete data.page;
  delete data.limit;

  return request.post(`${prefix}/file-preview?page=${page}&limit=${limit}`, data);
};
