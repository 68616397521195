export default {
  SET_ORDER_NET_OFFS(state, payload) {
    state.orderNetOffs.orderNetOffs = payload.orderNetOffs;
    state.orderNetOffs.totalCount = payload.totalCount;
  },
  SET_ALLOWANCES(state, payload) {
    state.allowances.allowances = payload.allowances;
    state.allowances.totalCount = payload.totalCount;
  },
};
