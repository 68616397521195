var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-view',{key:_vm.$route.fullPath}),_c('b-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLarge),expression:"isLarge"}],attrs:{"id":"batchRequestModal","size":"xl","body-class":"no-footer-padding","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('h6',[_vm._v(_vm._s(_vm.batchRequestModal.modalTitle))]),_c('button',{staticClass:"close",on:{"click":cancel}},[_vm._v("×")])])]}}])},[(_vm.batchRequestModal.currentStep === 1
    && _vm.batchRequestModal.currentStep)?_c('div',[(_vm.bulkInsertLoading)?_c('div',[_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Spinning"}}),_c('b-spinner',{attrs:{"type":"grow","label":"Spinning"}}),_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}}),_c('b-spinner',{attrs:{"variant":"primary","type":"grow","label":"Spinning"}}),_c('b-spinner',{attrs:{"variant":"success","label":"Spinning"}}),_c('b-spinner',{attrs:{"variant":"success","type":"grow","label":"Spinning"}})],1)]):(_vm.batchRequestModal.selected != null && _vm.batchRequestModal.selected.length)?_c('div',[_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$i18n.t('product.selectedTextConfirm', { msg: this.batchRequestModal.selected.length }))+" ")]),(_vm.batchRequestModal.process === 'update')?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.sendUpdatedProviderProductDetails(true)}}},[_vm._v("Gönder")]):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.sendBulkProviderProduct(_vm.batchRequestModal.type, true)}}},[_vm._v("Gönder")])],1):_c('div',[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('product.marketPlaces')}},[(_vm.batchRequestModal.process === 'update')?_c('treeselect',{attrs:{"options":_vm.customer.company.allProviders.filter(function (o) { return o !== 'manual'; })
            .map(function (x) { return ({
            id:x,
            label:x}); }),"defaultExpandLevel":1,"multiple":true,"value-consists-of":"BRANCH_PRIORITY","search-nested":""},model:{value:(_vm.form.providers),callback:function ($$v) {_vm.$set(_vm.form, "providers", $$v)},expression:"form.providers"}}):(_vm.batchRequestModal.process === 'create')?_c('treeselect',{attrs:{"options":_vm.batchRequestModal.type === 'erp' ? _vm.batchRequestModal.erpDefaultProvider
            : _vm.customer.company.allProviders.filter(function (o) { return o !== 'manual'; })
            .map(function (x) { return ({
            id:x === _vm.customer.company.defaultProvider ? 'ecommercePlatform' : x,
            label:x}); }),"defaultExpandLevel":1,"multiple":true,"value-consists-of":"BRANCH_PRIORITY","search-nested":""},model:{value:(_vm.form.providers),callback:function ($$v) {_vm.$set(_vm.form, "providers", $$v)},expression:"form.providers"}}):_vm._e()],1),(_vm.batchRequestModal.type !== 'erp')?_c('b-form-group',{attrs:{"label":_vm.$i18n.t('product.onSale')}},[_c('b-form-checkbox',{attrs:{"id":"isSalable","name":"isSalable"},model:{value:(_vm.form.isSalable),callback:function ($$v) {_vm.$set(_vm.form, "isSalable", $$v)},expression:"form.isSalable"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('product.onStock')}},[_c('b-form-checkbox',{attrs:{"id":"isStock","name":"isStock"},model:{value:(_vm.form.isStock),callback:function ($$v) {_vm.$set(_vm.form, "isStock", $$v)},expression:"form.isStock"}})],1),(_vm.batchRequestModal.process === 'update')?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.sendUpdatedProviderProductDetails(false)}}},[_vm._v("Gönder")]):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.sendBulkProviderProduct(_vm.batchRequestModal.type, false)}}},[_vm._v(" Gönder")])],1)]):(_vm.batchRequestId != null)?_c('div',[_c('batch-request-item',{attrs:{"modalText":true,"id":_vm.batchRequestId}})],1):_vm._e()]),_c('b-toast',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLarge),expression:"!isLarge"}],attrs:{"id":"miniRequestModal","title":_vm.$i18n.t('product.transactionInProgress'),"no-auto-hide":"","no-fade":""}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.openBigModal}},[_c('batch-request-item',{attrs:{"modalText":false,"id":_vm.batchRequestId}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }