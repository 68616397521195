export default {
  SET_PRODUCTS(state, payload) {
    // eslint-disable-next-line array-callback-return,no-unused-vars
    payload.data = payload.data.map((x) => {
      // eslint-disable-next-line no-underscore-dangle,no-restricted-syntax
      for (const marketProvider of payload.rootState.customer.customer.company.marketProviders) {
        if (x[marketProvider] == null) {
          x[marketProvider] = {
            calculatedPrice: {
              salesPrice: 0,
            },
          };
        } else if (x[marketProvider].calculatedPrice == null) {
          x[marketProvider].calculatedPrice = {
            salesPrice: 0,
          };
        } else if (x[marketProvider].calculatedPrice.salesPrice == null) {
          x[marketProvider].calculatedPrice.salesPrice = 0;
        }
      }
      return x;
    });
    delete payload.rootState;
    state.products = payload;
  },
  SET_PRODUCTS_SEARCH_DATA(state, payload) {
    // eslint-disable-next-line array-callback-return,no-unused-vars
    payload.data = payload.data.map((x) => {
      // eslint-disable-next-line no-underscore-dangle,no-restricted-syntax
      for (const marketProvider of payload.rootState.customer.customer.company.marketProviders) {
        if (x[marketProvider] == null) {
          x[marketProvider] = {
            calculatedPrice: {
              salesPrice: 0,
            },
          };
        } else if (x[marketProvider].calculatedPrice == null) {
          x[marketProvider].calculatedPrice = {
            salesPrice: 0,
          };
        } else if (x[marketProvider].calculatedPrice.salesPrice == null) {
          x[marketProvider].calculatedPrice.salesPrice = 0;
        }
      }
      return x;
    });
    delete payload.rootState;
    state.productSearch = payload;
  },
  SET_PRODUCT_COUNT(state, payload) {
    state.productCount = payload;
  },
  SET_PRODUCT(state, payload) {
    // eslint-disable-next-line no-restricted-syntax
    for (const marketProvider of payload.rootState.customer.customer.company.marketProviders) {
      if (payload[marketProvider] == null) {
        payload[marketProvider] = {
          calculatedPrice: {},
        };
      } else if (payload[marketProvider].calculatedPrice == null) {
        payload[marketProvider].calculatedPrice = {
          salesPrice: 0,
        };
      } else if (payload[marketProvider].calculatedPrice.salesPrice == null) {
        payload[marketProvider].calculatedPrice.salesPrice = 0;
      }
      if (payload[marketProvider] != null && payload[marketProvider].otherStores != null) {
        payload[marketProvider].otherStores = payload[marketProvider].otherStores
          .sort((a, b) => a.order - b.order);
        // eslint-disable-next-line no-loop-func
        payload[marketProvider].otherStores = payload[marketProvider].otherStores.map((x) => (
          {
            ...x,
            currentProvider: marketProvider,
          }
        ));
      }
    }
    delete payload.rootState;
    delete payload.saleProductRequest;
    state.product = payload;
  },
  SET_CRITICAL_STOCK_PRODUCTS(state, payload) {
    state.criticalStockProducts.criticalStockProducts = payload.data;
    state.criticalStockProducts.totalCount = payload.count;
  },
  SET_PROVIDER_PRODUCTS(state, payload) {
    state.providerProducts = payload;
  },
  SET_PRODUCT_DATA(state, payload) {
    state.createdProduct = payload;
  },
  SET_BATCH_INFO(state, payload) {
    state.batchInfo = payload;
  },
  SET_WAIT_FOR_APPROVE_PRODUCT_PRODUCTS(state, payload) {
    state.waitForApproveProductCount = payload;
  },
  SET_EXPORT_REQUESTS(state, payload) {
    state.exportRequests = payload;
  },
  SET_EXPORT_TEMPLATES(state, payload) {
    state.exportTemplates = payload;
  },
  SET_DECORATED_PRODUCTS(state, payload) {
    state.decoratedProducts = payload;
  },
  SET_WAREHOUSES(state, payload) {
    state.warehouses.warehouses = payload.warehouses;
    state.warehouses.totalCount = payload.totalCount;
  },
  SET_WAREHOUSE_BY_ID(state, payload) {
    state.warehouse = payload;
  },
  SET_STOCK_TAKINGS(state, payload) {
    state.stockTakings.stockTakings = payload.stockTakings;
    state.stockTakings.totalCount = payload.totalCount;
  },
};
