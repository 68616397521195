export default {
  SET_ORDERS(state, payload) {
    payload.data = payload.data.map((x) => {
      if (x.packages != null && x.packages.length > 0) {
        x.packages = x.packages.map((w) => (
          {
            ...w,
            lines: x.lines.filter((z) => w.lines.findIndex((a) => a.lineId === z.lineId) !== -1),
          }
        ));
      }
      if (x.lines != null && x.packages != null && x.lines.length > 0) {
        x.lines = x.lines.map((l) => {
          /* eslint-disable max-len */
          let linePackageId = x.packages.filter((p) => p.lines.findIndex((b) => l.lineId === b.lineId) !== -1);
          if (linePackageId.length > 0) {
            linePackageId = linePackageId[0].packageNumber;
          } else {
            linePackageId = null;
          }
          return {
            ...l,
            /* eslint-disable max-len */
            packageId: linePackageId,
          };
        });
      }
      return x;
    });
    state.orders = payload;
  },
  SET_ORDER_BARCODE(state, payload) {
    state.orderBarcode = payload;
  },
  SET_ORDER_TO_BE_PACKAGED(state, payload) {
    state.orderPackaged = payload;
  },
  SET_ORDER_COUNT(state, payload) {
    state.orderCount = payload;
  },
  SET_ORDER_UN_PACKAGE(state, payload) {
    state.orderUnPacked = payload;
  },
  SET_ORDER_CANCEL(state, payload) {
    state.orderCancel = payload;
  },
  SET_PROVIDER_CANCEL_REASON(state, payload) {
    state.cancelReason = payload;
  },
  SET_CARGO_COMPANY(state, payload) {
    state.cargoCompaines = payload;
  },
  SET_CHANGE_CARGO_COMPANY(state, payload) {
    state.changeCargoCompany = payload;
  },
  SET_CHANGE_MANY_CARGO_COMPANY(state, payload) {
    state.changeManyCargoCompany = payload;
  },
  SET_EXPORT_ORDERS(state, payload) {
    state.exportOrdersData = payload;
  },
  SET_ORDER(state, payload) {
    if (payload.packages != null && payload.packages.length > 0) {
      payload.packages = payload.packages.map((w) => (
        {
          ...w,
          lines: payload.lines.filter((z) => w.lines.findIndex((a) => a.lineId === z.lineId) !== -1),
        }
      ));
    }

    if (payload.lines != null && payload.lines.length > 0 && payload.packages != null) {
      payload.lines = payload.lines.map((l) => {
        /* eslint-disable max-len */
        let linePackageId = payload.packages.filter((p) => p.lines.findIndex((b) => l.lineId === b.lineId) !== -1);
        if (linePackageId.length > 0) {
          linePackageId = linePackageId[0].packageNumber;
        } else {
          linePackageId = null;
        }
        return {
          ...l,
          /* eslint-disable max-len */
          packageId: linePackageId,
        };
      });
    }
    state.order = payload;
  },
};
