export default [
  {
    path: 'imports/products',
    meta: { requiresAuth: true, module: 'product-xml' },
    name: 'imports.products',
    component: () => import('@/views/pages/imports/products/list'),
  },
  {
    path: 'imports/products/:productId',
    meta: { requiresAuth: true, module: 'product-xml' },
    name: 'import-product-source-form',
    component: () => import('@/views/pages/imports/products/detail'),
  },
];
