import {
  getQuestions,
  setAnswer,
  getStatus,
  getSupportedProviders,
} from '@/api/questions';

export default {
  getQuestions({ commit, rootState }, payload) {
    return getQuestions(payload)
      .then((response) => {
        commit('SET_QUESTIONS', { ...response.data, rootState });
        return response.data;
      });
  },
  setAnswer({ commit, rootState }, payload) {
    return setAnswer(payload).then((response) => {
      commit('SET_ANSWER', { ...response.data, rootState });
      return response.data;
    });
  },
  getStatus({ commit }) {
    return getStatus()
      .then((response) => {
        commit('SET_STATUS', response.data);
        return response.data;
      });
  },
  getSupportedProviders({ commit, rootstate }) {
    return getSupportedProviders().then((response) => {
      commit('SET_PROVIDERS', { ...response.data, rootstate });
      return response.data;
    });
  },
};
