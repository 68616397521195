export default {
  SET_CREATE_TICKET(state, payload) {
    state.conversation.message = payload.data;
    state.conversation.from = payload.from;
    state.questions.limit = payload.limit;
    state.questions.totalPage = payload.totalPage;
  },
  SET_ANSWER(state, payload) {
    state.answer = payload;
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload;
  },
};
