import axios from 'axios';
import * as _ from 'lodash';
import VueCookies from 'vue-cookies';
import app from '@/main';
import router from '../router';
import store from '../store';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

const showError = (message) => {
  app.$bvToast.toast(message, {
    title: 'Hata!',
    variant: 'danger',
    solid: true,
  });
};

const { CancelToken } = axios;
let cancel;

service.interceptors.response.use((response) => response, (error) => {
  if (_.has(error, 'response.data.code')) {
    showError(error.response.data.message);
    return Promise.reject(error.response.data);
  }
  if (_.has(error, 'response.data.code')) {
    if (error.response.data.code === 1002 || error.response.data.code === 1003) {
      store.dispatch('customer/logout');
      router.push('/auth/login');
    }
    showError(error.response.data.message);
    return Promise.reject(new Error(error.response.data));
  }
  showError(error.response.data.message);
  return Promise.reject(error);
});

service.interceptors.request.use(
  (config) => {
    config.cancelToken = new CancelToken((c) => {
      cancel = c;
    });
    const token = VueCookies.get(process.env.VUE_APP_TOKEN_PATH);
    if (token !== null) {
      config.headers.authorization = token;
      config.headers.langs = localStorage.getItem('lang') || 'tr';
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export const cancelRequest = () => {
  cancel('Isteğiniz iptal edildi');
};

export default service;
