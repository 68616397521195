import Vue from 'vue';
import * as _ from 'lodash';
import moment from 'moment';
import store from '@/store';
import i18n from '../i18n.js';
import { checkPermission, getCategoryPath } from './helpers';

Vue.filter('capitalize', (value) => _.words(value).map((text) => _.upperFirst(text)).join(' '));

Vue.filter('money', (value, currency) => {
  if (value != null && currency !== '') {
    try {
      return new Intl.NumberFormat('tr-TR', { style: 'currency', currency })
        .format(value).replace(/^(\D+)/, '$1 ');
    } catch (e) {
      return '-';
    }
  }
  return '-';
});
Vue.filter('romanNumeral', (value) => {
  const lookup = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let roman = '';
  Object.keys(lookup).forEach((i) => {
    while (value >= lookup[i]) {
      roman += i;
      value -= lookup[i];
    }
  });
  return roman;
});

Vue.filter('timeSince', (value) => {
  if (value) {
    return moment(String(new Date(value))).locale(i18n.locale).fromNow();
  }
  return '';
});

Vue.filter('formatPrice', (value) => {
  if (value) {
    const val = (value / 1).toFixed(2).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return '';
});

Vue.filter('remainingDate', (value) => {
  if (value) {
    return moment.duration(value).locale(i18n.locale).humanize();
  }
  return '';
});
Vue.filter('addLine', (value) => {
  if (value) {
    return value.concat(' /');
  }
  return '';
});
Vue.filter('number', (value) => parseFloat(value).toFixed(2));

Vue.filter('segmentDate', (value) => {
  if (value) {
    return moment(String(new Date(value))).locale(i18n.locale).format('DD MMMM YYYY - HH:mm:ss');
  }
  return '';
});

Vue.filter('numberDate', (value) => {
  if (value) {
    return moment(String(new Date(value))).locale(i18n.locale).format('DD.MM.YYYY');
  }
  return '';
});
Vue.filter('segmentOnlyDate', (value) => {
  if (value) {
    return moment(String(new Date(value))).locale(i18n.locale).format('DD MMMM YYYY');
  }
  return '';
});
Vue.filter('phoneMask', (value) => value.replace(/[^0-9]/g, '')
  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3'));
Vue.filter('address', (value) => {
  if (value) {
    return `${value.address}<br />  ${value.town}<br /> ${value.district != null ? value.district : value.town}/${value.city}<br />${value.phoneNumber != null ? value.phoneNumber : ''}`;
  }
  return '';
});

Vue.filter('providerName', (value) => {
  const providers = {
    hepsiburada: 'Hepsiburada',
    hepsiglobal: 'HepsiGlobal',
    n11: 'N11',
    trendyol: 'Trendyol',
    gg: 'GittiGidiyor',
    ciceksepeti: 'Çiçek Sepeti',
  };
  return providers[value] == null ? _.words(value).map((text) => _.upperFirst(text)).join(' ') : providers[value];
});

Vue.filter('defaultProviderName', (value) => {
  const providers = {
    platinMarket: 'Platin Market',
    ideaSoft: 'İdeaSoft',
    ticimax: 'Ticimax',
  };
  return providers[value] == null ? _.words(value).map((text) => _.upperFirst(text)).join(' ') : providers[value];
});

Vue.filter('logisticProviderName', (value) => {
  const providers = {
    hepsilojistik: 'HepsiLojistik',
  };
  return providers[value] == null ? _.words(value).map((text) => _.upperFirst(text)).join(' ') : providers[value];
});

Vue.filter('orderStatus', (value) => {
  const statuses = {
    Created: 'Paketlenecek (Yeni)',
    Picking: 'Gönderime Hazır',
    Packaged: 'Gönderime Hazır',
    Shipped: 'Kargoda',
    Delivered: 'Teslim Edildi',
    Cancelled: 'İptal Edildi',
    UnPacked: 'Paketlenecek (Yeni)',
    UnDelivered: 'Teslim Edilemedi',
    UnDeliveredAndReturned: 'Teslim Edilemedi',
    WaitingForPay: 'Ödemesi Beklenenler',
    ClaimCreated: 'İade Oluşturuldu',
    AtCollectionPoint: 'Teslimat Noktasında',
  };
  return statuses[value] == null ? _.words(value).map((text) => _.upperFirst(text)).join(' ') : statuses[value];
});

Vue.filter('logisticProductsAcceptanceStatus', (value) => {
  const statuses = {
    Created: 'Mal Kabul Oluşturuldu (Yeni)',
    Semidone: 'Mal Kabul Devam Ediyor',
    Completed: 'Mal Kabul Tamamlandı',
    Cancelled: 'İptal Edildi',
  };
  return statuses[value] == null ? _.words(value).map((text) => _.upperFirst(text)).join(' ') : statuses[value];
});

Vue.filter('transactionType', (value) => {
  const statuses = {
    Payment: 'Sipariş Tutarı',
    Commission: 'Komisyon Tutarı',
    CommissionRefund: 'Komisyon İade Tutarı',
    ShipmentCostSharingExpense: 'Kargo Katkı Payı Gideri',
    ShipmentCostSharingIncome: 'Kargo Katkı Payı İadesi',
    ProcessingFeeExpense: 'Hizmet Bedeli',
    ProcessingFeeExpenseRefund: 'Hizmet Bedeli İadesi',
    CustomerSatisfaction: 'Müşteri Memnuniyeti Yansıtma',
    CustomerSatisfactionRefund: 'Müşteri Memnuniyeti Yansıtma İadesi',
    LineItemTransferExpense: 'Sipariş Transfer Gideri',
    LineItemTransferIncome: 'Sipariş Transfer Geliri',
    CampaignDiscount: 'Kampanya İndirimleri',
    CampaignDiscountRefund: 'Kampanya İndirimleri İadesi',
    RevenueExpense: 'Ciro Gideri',
    RevenueIncome: 'Ciro Geliri',
    CargoMargin: 'Kargo Marjı',
    CargoCompensationIncomeRefund: 'Kargo Tazmin Geliri İadesi',
    MpScrapIncome: 'Hurda Geliri',
    RefusedInvoiceExpenseRefund: 'Reddedilen Faturalar',
    RefusedInvoiceExpense: 'Reddedilen Fatura Bedeli',
    CommissionReturnPriceDifference: 'Komisyon İade Tutarı',
    Deposit: 'Depozito Tutarı',
    AdSharingExpense: 'Reklam Katılım Gideri',
    AdSharingExpenseRefund: 'Reklam Katılım Gideri İadesi',
    DropShipmentCostSharingIncome: 'Drop Kargo Katkı Payı Gideri',
    DropShipmentCostSharingExpense: 'Drop Kargo Katkı Payı Geliri',
    Chargeout: 'Masraf Yansıtma Tutarı',
    CargoCompensationSellerSatisfactionIncome: 'Kargo Tazmin Satıcı Memnuniyet Geliri',
    MarketingExpense: 'Pazarlama Bedeli',
    MarketingExpenseRefund: 'Pazarlama Bedeli İadesi',
    StudioExpense: 'Stüdyo Kullanım Bedeli',
    PriceDifferenceRefund: 'Fiyat Farkı İadesi',
    PriceDifferenceExpense: 'Fiyat Farkı Bedeli',
    LateInterestExpense: 'Vade Farkı Gideri',
    CargoCostRefund: 'Kargo İade',
    RoadAssistanceExpenseRefund: 'Yol Yardım İadesi',
    RoadAssistanceExpense: 'Yol Yardım Bedeli',
    SponsorshipFee: 'Sponsorluk Bedeli',
    OverseasCommissionRefund: 'Yurtdışı Komisyon İadesi',
    MpScrapExpense: 'Hurda Gideri',
    MpCompensationIncome: 'MP Operasyon Departmanı Tazmin Geliri',
    CargoCompensationExpenseRefund: 'Kargo Tazmin Gideri İadesi',
    VAT: 'KDV',
    BalanceCorrection: 'Düzeltme Kaydı',
    SfsInterest: 'TFS Vade Farkı',
    ProductsReportedByService: 'MP Servis Raporlu Ürünler',
    FacebookAdExpense: 'MP Facebook Reklam Gideri',
    CargoLimitExcessCompensationIncome: 'Kargo Limit Aşımı Tazmin Geliri',
    OrderBasedTransactions: 'Sipariş Bazlı Kayıtlar',
    ContractualOrSpecialCaseTransactions: 'Sözleşmeye Bağlı/Özel Durum Kayıtlar',
    CommissionSettlementTransactions: 'Komisyon Mahsuplaşma Kayıtları',
    InvoiceTransactions: 'Faturalar',
    IncomeTransactions: 'Tüm Gelir Kayıtları',
    ExpenseTransactions: 'Tim Gider Kayıtları',
    AdBalanceTopUpFromAllowance: 'Reklam Bakiye Yüklemesi',
    AdBalanceTopUpFromAllowanceRefund: 'Reklam Bakiye İadesi',
    MarketingSupportParticipation: 'Pazarlama Katılım Destek',
    MpOutletProductExpense: 'MP Outlet Gider Kalemi',
    BnplOrder: 'BNPL Sipariş Tutarı',
    BnplRefund: 'BNPL İade Tutarı',
    Return: 'Toplam İade Tutarı',
    Sale: 'Satış',
    Discount: 'İndirim Tutarı',
    DiscountCancel: 'İndirim İadesi',
    Coupon: 'Kupon Tutarı',
    CouponCancel: 'Kupon İptal Tutarı',
    ProvisionPositive: 'Pozitif Gramaj Farkı',
    ProvisionNegative: 'Negatif Gramaj Farkı',
  };
  return statuses[value] == null ? _.words(value).map((text) => _.upperFirst(text)).join(' ') : statuses[value];
});

Vue.filter('categoryPath', (target, data) => getCategoryPath(target, data));

Vue.filter('categoryName', (categoryId) => {
  const categories = store.state.category.allCategoriesFlat;
  // eslint-disable-next-line no-underscore-dangle
  const currentCategory = _.find(categories, (w) => w._id === categoryId);
  if (currentCategory) {
    return currentCategory.title;
  }
  return 'Bilinmiyor';
});

Vue.filter('commissionName', (commissionId) => {
  const commissions = store.state.commissions.commissions.data;
  // eslint-disable-next-line no-underscore-dangle
  const current = _.find(commissions, (w) => w._id === commissionId);
  if (current) {
    return current.name;
  }
  return 'Bilinmiyor';
});

Vue.filter('supplierName', (supplierId, suppliers) => {
  const current = _.find(suppliers, (w) => w.id === supplierId);
  if (current) {
    return current.supplierName;
  }
  return 'Bilinmiyor';
});

let handleOutsideClick;

Vue.directive('closable', {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName];
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl != null) clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },

  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

Vue.directive('aclosable', {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$el;
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl != null) {
            e.path.forEach((x) => {
              if (x.className === refName) {
                clickedOnExcludedEl = true;
              }
            });
          }
        }
      });

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },

  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

Vue.directive('role', {
  bind(el, binding, vnode) {
    const { state } = vnode.context.$store;
    const { role } = state.customer.customer;
    if (checkPermission({ role, permission: binding.value })) {
      return;
    }
    if (el.classList != null && el.classList.contains('custom-switch')) {
      el.innerHTML = el.getElementsByTagName('input')[0].checked ? 'Aktif' : 'Pasif';
      return;
    }
    el.style.display = 'none';
    el.style.visibility = 'hidden';
  },
});

Vue.filter('percentage', (newValue, oldValue) => {
  const result = (((newValue - oldValue) / oldValue) * 100).toFixed(0);
  return result;
});
Vue.filter('percentageToFixed', (newValue, oldValue) => {
  const result = (((newValue - oldValue) / oldValue) * 100).toFixed(2);
  return result;
});

Vue.filter('truncate', (text, length, clamp) => {
  clamp = clamp || '...';
  const node = document.createElement('div');
  node.innerHTML = text;
  const content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});
