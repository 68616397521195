<template>
  <div>
    <rx-filter-loading :loading="loading"/>
    <on-search
      :miniComponent="true"
      :showStore="true"
      @change="onSearch($event)"
    ></on-search>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OnSearch from '../../../ProductSearch';

export default {
  name: 'baseProductId',
  components: { OnSearch },
  props: {
    value: null,
  },
  data() {
    return {
      productSearchId: null,
      form: {
        productId: [],
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      decoratedProducts: (state) => state.product.decoratedProducts,
      productSearch: (state) => state.product.productSearch,
    }),
  },
  methods: {
    onSearch(result) {
      this.$emit('change', { filter: 'baseProductId', data: result });
    },
  },
  beforeMount() {
    if (this.decoratedProducts.code !== null) {
      this.productSearchId = this.decoratedProducts.code;
      this.onSearch(this.productSearchId);
    }
  },
};
</script>
