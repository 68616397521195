export default {
  productSources: {
    productSources: [],
    totalCount: 0,
  },
  productSource: {},
  error: null,
  success: {},
  sourceLogs: {
    results: [],
  },
  filePreviewResponse: [],
  refreshFilePreviewResponse: Date.now(),
  enums: {
    fieldTypes: [
      'static',
      'xPath',
      'jsonPath',
    ],
    fileTypes: [
      'xml',
      'json',
    ],
    fieldPaths: [
      'title',
      'status',
      'price.defaultPrice',
      'price.defaultPriceCurrency',
      'price.salesPrice',
      'price.salesPriceCurrency',
      'price.costPrice',
      'price.costPriceCurrency',
      'sku',
      'stocks',
      'barcode',
      'brand',
      'desi',
      'warrantyPeriodInMonth',
      'taxVatRate',
      'categoryTitle',
      'description',
      'photos.0.url',
      'photos.1.url',
      'photos.2.url',
      'photos.3.url',
      'photos.4.url',
      'photos.5.url',
      'photos.6.url',
      'photos.7.url',
      'variant.mainCode',
      'variant.variantAttributes.*.attributeTitle',
      'variant.variantAttributes.*.value',
      'variant.variantAttributes.0.attributeTitle',
      'variant.variantAttributes.0.value',
      'variant.variantAttributes.1.attributeTitle',
      'variant.variantAttributes.1.value',
      'attributes.*.attributeId',
      'attributes.*.attributeTitle',
      'attributes.*.valueId',
      'attributes.*.valueTitle',
    ],
    valueFunctions: [
      {
        text: 'Stoğu text değerinden sayıya çevirici',
        value: 'arenaStockValue',
      }, {
        text: 'Türkçe formattaki döviz kısaltmalarını globale çevirici',
        value: 'arenaCurrency',
      }, {
        text: 'Türkçe formattaki fiyatı globale çevirici',
        value: 'arenaPrice',
      }, {
        text: 'Stok sayısını numaraya çevirme',
        value: 'stockToNumber',
      }, {
        text: 'Gün sayısını ay formatına çevirme',
        value: 'daysToMonths',
      }, {
        text: 'Barkod oluşturucu',
        value: 'barcodeGenerator',
      }, {
        text: 'Barkod tamamlayıcı (13 hane)',
        value: 'padBarcode',
      }, {
        text: 'Bölü işaretine göre seperatör',
        value: 'splitSlash',
      }, {
        text: 'Büyüktür işaretine göre seperatör',
        value: 'splitBiggerThan',
      },
      {
        text: 'Boşlukları sil',
        value: 'trim',
      },
      {
        text: 'Barcode olmayanlar oluştur',
        value: 'ifEmptyThenGenerateBarcode',
      },
      {
        text: 'Ana kodu tedarikçi kodu yap',
        value: 'mainCodeWithSupplierId',
      },
      {
        text: 'Noktaya göre böl ve sonuncusunu çıkar',
        value: 'splitDotExculudeLastItem',
      },
      {
        text: 'Noktaya göre böl ve sonuncusunu getir',
        value: 'splitDotGetLastItem',
      },
      {
        text: 'Özellik değerlerini değerden siler',
        value: 'removeAttributeFromTitle',
      },
      {
        text: 'Diziyi birleştir',
        value: 'mergeArray',
      },
      {
        text: 'Değerin başına x- ekler',
        value: 'suffixX',
      },
      {
        text: 'Değerin başına bed ekler',
        value: 'suffixBed',
      },
      {
        text: 'Vergi oranından % işaretini kaldırır',
        value: 'removePercentage',
      },
    ],
  },
  productSourceFileInfo: {},
};
