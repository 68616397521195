import request from '../utils/request';

const prefix = '/api/customers/roles';

export const getRoles = ({ page, limit, filter }) => request.get(`${prefix}`, { params: { page, limit, filter } });

export const updateRole = ({ roleId, data }) => request.put(`${prefix}/${roleId}`, data);

export const deleteRole = ({ roleId }) => request.delete(`${prefix}/${roleId}`);

export const createRole = (data) => request.post(`${prefix}`, data);

export const getPermissions = (data) => request.get(`${prefix}/permissions`, data);
