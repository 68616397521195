import {
  getCampaigns,
  getCampaignById,
  createCampaign,
  updateCampaignById,
  deleteCampaignById,
} from '@/api/campaigns';

export default {
  async fetchCampaigns({ commit }, payload) {
    const response = await getCampaigns(payload);
    commit('SET_CAMPAIGNS', response.data);
  },
  async fetchCampaign({ commit }, payload) {
    try {
      const response = await getCampaignById(payload);

      commit('SET_CAMPAIGN', response);
    } catch (e) {
      commit('SET_CAMPAIGN_ERROR', e);
    }
  },
  async storeCampaign({ commit }, payload) {
    try {
      const response = await createCampaign(payload);

      commit('SET_CAMPAIGN_SUCCESS', response);
    } catch (e) {
      commit('SET_CAMPAIGN_ERROR', e);
    }
  },
  async updateCampaign({ commit }, payload) {
    try {
      const { id, params } = payload;
      const response = await updateCampaignById(id, params);

      commit('SET_CAMPAIGN_SUCCESS', response);
    } catch (e) {
      commit('SET_CAMPAIGN_ERROR', e);
    }
  },
  async removeCampaign({ commit }, payload) {
    try {
      const response = await deleteCampaignById(payload);

      commit('SET_CAMPAIGN_SUCCESS', response);
    } catch (e) {
      commit('SET_CAMPAIGN_ERROR', e);
    }
  },
};
