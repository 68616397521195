export default {
  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload;
  },
  SET_SUPPLIER(state, payload) {
    state.supplier = payload;
  },
  SET_SUPPLIER_ERROR(state, payload) {
    state.error = payload;
  },
  SET_SUPPLIER_SUCCESS(state, payload) {
    state.success = payload;
  },
  SET_CITIES(state, payload) {
    payload.data = payload.data.sort((a, b) => a.name.localeCompare(b.name));
    state.cities = payload;
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload;
  },
  SET_PROVIDER_FIELDS(state, payload) {
    state.providerFields = payload;
  },
};
