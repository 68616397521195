import {
  getRoles,
  createRole,
  updateRole,
  getPermissions,
  deleteRole,
} from '@/api/role';

export default {
  getRoles({ commit }, payload) {
    return getRoles(payload)
      .then((response) => {
        commit('SET_ROLES', response.data);
        return response.data;
      });
  },
  updateRole(_, payload) {
    return updateRole(payload)
      .then((response) => response.data);
  },
  createRole(_, payload) {
    return createRole(payload)
      .then((response) => response.data);
  },
  deleteRole(_, payload) {
    return deleteRole(payload)
      .then((response) => response.data);
  },
  getPermissions({ commit }, payload) {
    return getPermissions(payload)
      .then((response) => {
        commit('SET_PERMISSIONS', response.data);
        return response.data;
      });
  },
};
