import i18n from '../i18n.js';

const chartInfos = {
  pages: {
    productSaleReports: {
      segments: [null, 'provider', 'mainCategoryId', 'buyBox', 'commission', 'productBrand'],
      dataFields: ['avgPrice', 'totalPrice', 'quantity', 'totalProfit'],
    },
    productDetailReports: {
      segments: [null, 'provider', 'mainCategoryId', 'buyBox', 'commission', 'productBrand'],
      dataFields: ['avgPrice', 'totalPrice', 'quantity', 'totalProfit'],
    },
    productCampaignReports: {
      segments: [null, 'provider', 'mainCategoryId', 'buyBox', 'commission', 'productBrand'],
      dataFields: ['avgPrice', 'totalPrice', 'quantity', 'totalProfit'],
    },
    productCampaignDetailReports: {
      segments: [null, 'provider', 'mainCategoryId', 'buyBox', 'commission', 'productBrand'],
      dataFields: ['avgPrice', 'totalPrice', 'quantity', 'totalProfit'],
    },
    productCampaignSelloutDetailReports: {
      segments: [null, 'provider', 'mainCategoryId', 'buyBox', 'commission', 'productBrand'],
      dataFields: ['avgPrice', 'totalPrice', 'quantity', 'totalProfit'],
    },
    cityBasedReporting: {
      dataFields: ['avgPrice', 'totalPrice', 'quantity'],
    },
  },
  segments: {
    null: {
      title: i18n.t('reports.total'),
      store: null,
      formatter: (value) => value,
    },
    provider: {
      title: i18n.t('reports.marketPlace'),
      store: null,
      formatter: (value, Vue) => Vue.filter('providerName')(value),
    },
    supplier: {
      title: i18n.t('reports.supplier'),
    },
    mainCategoryId: {
      title: i18n.t('reports.baseCategory'),
      store: 'category/getAllCategories',
      formatter: (value, Vue) => Vue.filter('categoryName')(value),
    },
    buyBox: {
      title: i18n.t('reports.BuyBox'),
      // eslint-disable-next-line no-restricted-globals
      formatter: (value) => (isNaN(Number(value)) ? i18n.t('reports.orderUnknown') : `${value}. Sıra`),
    },
    commission: {
      title: i18n.t('reports.commission'),
      formatter: (value, Vue) => Vue.filter('commissionName')(value),
    },
    productBrand: {
      title: i18n.t('reports.brand'),
      formatter: (value) => (value == null || value === 'null' ? i18n.t('reports.unkownBrand') : value),
    },
    specialOffer: {
      title: i18n.t('reports.campaign'),
      formatter: (value) => value,
    },
  },
  dataFields: {
    totalPrice: {
      title: i18n.t('reports.endorsement'),
      type: 'money',
    },
    quantity: {
      title: i18n.t('reports.salesQuantity'),
      type: 'number',
    },
    avgPrice: {
      title: i18n.t('reports.averagePrice'),
      type: 'money',
    },
    totalProfit: {
      title: i18n.t('reports.totalProfit'),
      type: 'money',
    },
  },
  resolutions: {
    day: i18n.t('reports.day'),
    week: i18n.t('reports.week'),
    months: i18n.t('reports.months'),
    quarter: i18n.t('reports.quarter'),
    year: i18n.t('reports.year'),
  },
  dataTypes: {
    money: (value) => new Intl
      .NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' })
      .format(value).replace(/^(\D+)/, '$1 '),
    number: (value) => Number(value),
  },
};
export default chartInfos;
