import {
  getSuppliers,
  getSupplierById,
  createSupplier,
  updateSupplierById,
  deleteSupplierById,
  getProviderMandatoryFields,
} from '@/api/suppliers';

import {
  getCities,
  getDistricts,
} from '@/api/locations';

export default {
  async fetchSuppliers({ commit, state }, payload) {
    if (state.suppliers.data) {
      return state.suppliers;
    }
    const response = await getSuppliers(payload);
    commit('SET_SUPPLIERS', response);
    return response;
  },
  async fetchSupplier({ commit }, payload) {
    try {
      const response = await getSupplierById(payload);
      commit('SET_SUPPLIER', response);
    } catch (e) {
      commit('SET_SUPPLIER_ERROR', e);
    }
  },
  async storeSupplier({ commit, state }, payload) {
    state.suppliers = [];
    try {
      const response = await createSupplier(payload);

      commit('SET_SUPPLIER_SUCCESS', response);
    } catch (e) {
      commit('SET_SUPPLIER_ERROR', e);
    }
  },
  async updateSupplier({ commit, state }, payload) {
    state.suppliers = [];
    try {
      const { id, params } = payload;
      const response = await updateSupplierById(id, params);
      commit('SET_SUPPLIER_SUCCESS', response);
      return response.data;
    } catch (e) {
      commit('SET_SUPPLIER_ERROR', e);
      return null;
    }
  },
  async removeSupplier({ commit, state }, payload) {
    state.suppliers = [];
    try {
      const response = await deleteSupplierById(payload);

      commit('SET_SUPPLIER_SUCCESS', response);
    } catch (e) {
      commit('SET_SUPPLIER_ERROR', e);
    }
  },
  async fetchCities({ commit }, payload) {
    try {
      const response = await getCities(payload);

      commit('SET_CITIES', response);
    } catch (e) {
      commit('SET_SUPPLIER_ERROR', e);
    }
  },
  async fetchDistricts({ commit }, payload) {
    try {
      const response = await getDistricts(payload);

      commit('SET_DISTRICTS', response);
    } catch (e) {
      commit('SET_SUPPLIER_ERROR', e);
    }
  },
  async getProviderMandatoryFields({ commit }, payload) {
    try {
      const response = await getProviderMandatoryFields(payload);
      commit('SET_PROVIDER_FIELDS', response.data);
    } catch (e) {
      commit('SET_SUPPLIER_ERROR', e);
    }
  },
};
