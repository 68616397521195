import i18n from '../../i18n.js';

const items = [
  {
    name: i18n.t('settings.settings'),
    role: 'settings.settings',
    type: 'divider',
  },
  {
    name: i18n.t('settings.company'),
    type: 'page',
    icon: 'building',
    link: '/customer/settings/company',
    role: 'settings.company',
  },
  {
    name: i18n.t('settings.integrationTab'),
    role: 'settings.provider',
    type: 'page',
    icon: 'archive',
    link: '/customer/settings/provider',
  },
  {
    name: i18n.t('settings.stock'),
    type: 'page',
    role: 'settings.stock',
    icon: 'asterisk',
    link: '/customer/settings/stocks',
  },
  {
    name: i18n.t('settings.priceChange'),
    role: 'settings.price-change',
    type: 'page',
    icon: 'capslock',
    link: '/customer/settings/price-change',
  },
  {
    name: i18n.t('settings.invoiceSettings'),
    role: 'settings.invoice-settings',
    type: 'page',
    icon: 'capslock',
    link: '/customer/settings/invoice-settings',
  },
  {
    name: i18n.t('settings.commissionProfit'),
    role: 'settings.commission',
    type: 'page',
    icon: 'wallet',
    link: '/customer/settings/commission',
  },
  {
    name: i18n.t('settings.exchange'),
    role: 'settings.exchange',
    type: 'page',
    icon: 'cash',
    link: '/customer/settings/exchange',
  },
  {
    name: i18n.t('settings.subMembership'),
    role: 'settings.customers',
    type: 'page',
    icon: 'inbox',
    link: '/customer/settings/company-customers',
  },
  {
    name: i18n.t('settings.role'),
    type: 'page',
    role: 'settings.role',
    icon: 'diagram3',
    link: '/customer/settings/company-roles',
  },
  {
    name: i18n.t('settings.api'),
    type: 'page',
    role: 'settings.role',
    icon: 'diagram3',
    link: '/customer/settings/api',
  },
];
export default items;
