import VueCookies from 'vue-cookies';
import { setJsonItem } from '../../../utils/localStorage';

export default {
  SET_CUSTOMER(state, payload) {
    payload.company.allProviders.push('manual');
    state.customer = payload;
    setJsonItem('tawkInfo', { name: payload.name, email: payload.email });
  },
  SET_TOKEN(state, payload) {
    VueCookies.set(process.env.VUE_APP_TOKEN_PATH, payload);
  },
  SET_IDEAPP(state, payload) {
    VueCookies.set('idea-app', payload);
  },
  SET_SESSION(state, payload) {
    state.session = payload;
  },
  SET_DARK_MODE(state, payload) {
    state.darkMode = payload;
  },
  SET_NUMBER_MODE(state, payload) {
    state.numberStatus = payload;
  },
  SET_COMPANY_CUSTOMERS(state, payload) {
    state.companyCustomers = payload;
  },
  SET_COLLAPSE_MENU(state, payload) {
    state.varyantCollapse = payload;
  },
  SET_RESET_FORM(state, payload) {
    state.cleanedRxForm = payload;
  },
  SET_BANK_TRANSFER(state, payload) {
    state.bankTransfer = payload;
  },
  SET_UPLOAD_IMAGE(state, payload) {
    state.statusUploadImage = payload;
  },
  SET_ADDED_ATTRIBUTES(state, payload) {
    state.addedAttiribute = payload;
  },
  SET_ERRORS_PAGE_STATUS(state, payload) {
    state.errorsStatus = payload;
  },
  SET_OFFLINE_PAGE(state, payload) {
    state.isOnline = payload;
  },
  SET_DEFAULT_DASHBOARD(state, payload) {
    state.defaultDashboard = payload;
  },
  SET_DASHBOARD_WIDGETS(state, payload) {
    state.dashboardWidgets = payload;
  },
  SET_DASHBOARD_CUSTOMER(state, payload) {
    state.customerDashboards.customerDashboards = payload.customerDashboards;
    state.customerDashboards.totalCount = payload.totalCount;
  },
  SET_DASHBOARD_COMPANY(state, payload) {
    state.companyDashboards.companyDashboards = payload.companyDashboards;
    state.companyDashboards.totalCount = payload.totalCount;
  },
  LOGOUT(state) {
    state.customer = null;
    state.session = null;
    VueCookies.remove(process.env.VUE_APP_TOKEN_PATH);
  },
};
