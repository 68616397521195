import request from '../utils/request';

const prefix = '/api/locations';

export const getCities = (data) => request.get(`${prefix}/cities`, {
  params: data,
});

export const getDistricts = (data) => request.get(`${prefix}/districts`, {
  params: data,
});
