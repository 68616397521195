import {
  getProducts,
  createProduct,
  getProduct,
  updateProduct,
  updateSingleProduct,
  updateManyStatus,
  deleteProduct,
  deleteProducts,
  updateProviderStatus,
  getProductCount,
  getProviderProducts,
  saleProduct,
  getProviderAddresses,
  changeProviderStatus,
  getProviderBrands,
  getAllProductsWithoutPagination,
  getWaitForApproveProductCount,
  csvImport,
  batchInfo,
  exportProducts,
  getExportRequests,
  getExportTemplates,
  getVariantProducts,
  getWarehouses,
  getWarehouseById,
  getCriticalStockProducts,
  getStockTakings,
  getSalesChannels,
} from '@/api/product';
import { getJsonItem, setJsonItem } from '../../../utils/localStorage';

export default {
  getProducts({ commit, rootState }, payload) {
    return getProducts(payload)
      .then((response) => {
        commit('SET_PRODUCTS', { ...response.data, rootState });
        return response.data;
      });
  },
  setProducts({ commit, rootState }, payload) {
    commit('SET_PRODUCTS', { ...payload, rootState });
    return payload;
  },
  fetchAllProductsWithoutPagination({ commit, rootState }, payload) {
    if (payload.isThere == null) {
      payload.isThere = false;
    }
    return getAllProductsWithoutPagination({ filter: payload.filter })
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (!payload.isThere) {
          commit('SET_PRODUCTS', { ...response.data, rootState });
        } else {
          commit('SET_PRODUCTS_SEARCH_DATA', { ...response.data, rootState });
        }
        return response.data;
      });
  },
  getProviderProducts({ commit }, payload) {
    return getProviderProducts(payload)
      .then((response) => {
        commit('SET_PROVIDER_PRODUCTS', response.data);
        return response.data;
      });
  },
  getProductCount({ commit }, payload) {
    return getProductCount(payload)
      .then((response) => {
        commit('SET_PRODUCT_COUNT', response.data);
        return response.data;
      });
  },
  getProduct({ commit, rootState }, payload) {
    return getProduct(payload)
      .then((response) => {
        commit('SET_PRODUCT', { ...response.data, rootState });
        return response.data;
      });
  },
  setProduct({ commit, rootState }, payload) {
    commit('SET_PRODUCT', { ...payload, rootState });
  },
  getVariantProducts(_, payload) {
    return getVariantProducts(payload)
      .then((response) => response.data);
  },
  getProductById(_, payload) {
    return getProduct(payload)
      .then((response) => response.data);
  },
  getCriticalStockProducts({ commit }, payload) {
    return getCriticalStockProducts(payload)
      .then((response) => {
        commit('SET_CRITICAL_STOCK_PRODUCTS', response.data);
        return response.data;
      });
  },
  createProduct({ commit }, data) {
    return createProduct(data).then((response) => {
      commit('SET_PRODUCT_DATA', { ...response.data });
      return response.data;
    });
  },
  updateProviderStatus(_, payload) {
    return updateProviderStatus(payload).then((response) => response.data);
  },
  updateProduct({ commit, rootState }, payload) {
    return updateProduct(payload.productId, payload.data).then((response) => {
      if (payload.isNotUpdateStore !== true) {
        commit('SET_PRODUCT', { ...response.data, rootState });
      }
      return response.data;
    });
  },
  updateSingleProduct({ commit, rootState }, payload) {
    return updateSingleProduct(payload.productId, payload.data, payload.forceUpdate)
      .then((response) => {
        if (!payload.isNotUpdateStore) {
          commit('SET_PRODUCT', { ...response.data.product, rootState });
        }
        return response.data;
      });
  },
  updateManyStatus(_, payload) {
    return updateManyStatus(payload.data).then((response) => response.data);
  },
  saleProduct(_, data) {
    return saleProduct(data)
      .then((response) => response.data);
  },
  async getProviderAddresses(_, { provider, isForce }) {
    let providerAddresses = getJsonItem(`${provider}-providerAddresses`);
    if (providerAddresses == null || isForce) {
      const response = await getProviderAddresses(provider);
      providerAddresses = response.data;
      setJsonItem(`${provider}-providerAddresses`, providerAddresses);
    }
    return providerAddresses;
  },
  getProviderBrands(_, provider) {
    return getProviderBrands(provider)
      .then((response) => response.data);
  },
  changeProviderStatus(_, data) {
    return changeProviderStatus(data)
      .then((response) => response.data);
  },
  getWaitForApproveProductCount({ commit }, data) {
    return getWaitForApproveProductCount(data)
      .then((response) => {
        commit('SET_WAIT_FOR_APPROVE_PRODUCT_PRODUCTS', { ...response.data });
      });
  },
  saveCsvFile(_, payload) {
    return csvImport(payload);
  },
  exportProducts(_, payload) {
    return exportProducts(payload);
  },
  getExportRequests({ commit }, payload) {
    return getExportRequests(payload)
      .then((response) => {
        commit('SET_EXPORT_REQUESTS', response.data);
        return response.data;
      });
  },
  deleteProduct(_, payload) {
    return deleteProduct(payload)
      .then((response) => response.data);
  },
  deleteProducts(_, payload) {
    return deleteProducts(payload)
      .then((response) => response.data);
  },
  updateDecoratedProducts({ commit }, data) {
    commit('SET_DECORATED_PRODUCTS', data);
  },
  getExportTemplates({ commit }) {
    return getExportTemplates().then((response) => {
      commit('SET_EXPORT_TEMPLATES', response.data);
    });
  },
  getBatchInfo({ commit }, payload) {
    return batchInfo(payload)
      .then((response) => {
        commit('SET_BATCH_INFO', response.data);
        return response.data;
      });
  },
  getWarehouses({ commit }, payload) {
    return getWarehouses(payload).then((response) => {
      commit('SET_WAREHOUSES', response.data);
    });
  },
  getWarehouseById({ commit }, payload) {
    return getWarehouseById(payload).then((response) => {
      commit('SET_WAREHOUSE_BY_ID', response.data);
    });
  },
  getStockTakings({ commit }, payload) {
    return getStockTakings(payload).then((response) => {
      commit('SET_STOCK_TAKINGS', response.data);
    });
  },
  getSalesChannels(provider) {
    return getSalesChannels(provider)
      .then((response) => response.data);
  },
};
