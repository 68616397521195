export default {
  orderNetOffs: {
    orderNetOffs: [],
    totalCount: 0,
  },
  allowances: {
    allowances: [],
    totalCount: 0,
  },
};
