import request from '../utils/request';

const prefix = '/api/customers';

export const getCompanyCustomers = ({ page, limit, filter }) => request.get(`${prefix}/company-customers`, {
  params: { page, limit, filter },
});

export const createCompanyCustomer = (params) => request.post(`${prefix}/add-company-customer`, params);

export const updateCompanyCustomer = (id, params) => request.put(`${prefix}/company-customer`, {
  ...params,
  customerId: id,
});

export const deleteCompanyCustomer = (id) => request.delete(`${prefix}/company-customer`, {
  data: { customerId: id },
});
