import request from '../utils/request';

const prefix = '/api/suppliers';

export const getSuppliers = ({ page, limit, filter }) => request.get(`${prefix}`, { params: { page, limit, filter } });

export const getProviderMandatoryFields = () => request.get(`${prefix}/get-provider-mandatory-fields`);

export const getSupplierById = (id) => request.get(`${prefix}/${id}`);

export const createSupplier = (params) => request.post(`${prefix}`, params);

export const createOrder = (data) => request.post(`${prefix}/create-order-for-provider`, data);

export const updateSupplierById = (id, params) => request.put(`${prefix}/${id}`, params);

export const deleteSupplierById = (id) => request.delete(`${prefix}/${id}`);
