import {
  getInvoices,
  sendReceiptToERP,
  saveSerialNumber,
  sendInvoiceToERP,
  sendInvoicesToERP,
  updateManyStatus,
  getInvoiceStats,
  getInvoiceTemplates,
  deleteInvoiceTemplates,
  updateInvoiceTemplates,
  getInvoiceTemplateElements,
  getInvoiceTemplate,
  sendReceiptsToERP,
  getAccounts,
  getInvoice,
  getEInvoices,
  getWarehouseFromErp,
  getERPProducts,
} from '@/api/invoice';

export default {
  getInvoices({ commit }, payload) {
    return getInvoices(payload)
      .then((response) => {
        commit('SET_INVOICES', response.data);
        return response.data;
      });
  },
  getERPProducts({ commit }, payload) {
    return getERPProducts(payload)
      .then((response) => {
        commit('SET_ERP_PRODUCTS_INVOICE', response.data);
        return response.data;
      });
  },
  getWareHouseFromErp({ commit }, payload) {
    return getWarehouseFromErp(payload)
      .then((response) => {
        commit('SET_WAREHOUSE_ERP', response.data);
        return response.data;
      });
  },
  getInvoice({ commit }, payload) {
    return getInvoice(payload)
      .then((response) => {
        commit('SET_INVOICE', response.data);
        return response.data;
      });
  },
  getInvoiceStats({ commit }) {
    return getInvoiceStats()
      .then((response) => {
        commit('SET_INVOICE_STATS', response.data);
        return response.data;
      });
  },
  getInvoiceTemplates({ commit }, payload) {
    return getInvoiceTemplates(payload)
      .then((response) => {
        commit('SET_INVOICE_TEMPLATES', response.data);
        return response.data;
      });
  },
  getInvoiceTemplate({ commit, state }, payload) {
    if (state.templateElement != null && state.templateElement.id != null) {
      return state.templateElement;
    }
    return getInvoiceTemplate(payload).then(({ data }) => {
      commit('SET_INVOICE_TEMPLATE_ELEMENT', data);
      return data;
    });
  },
  setInvoiceTemplate({ commit }, payload) {
    commit('SET_INVOICE_TEMPLATE_ELEMENT', payload);
  },
  getInvoiceTemplateElements({ commit }, params) {
    return getInvoiceTemplateElements()
      .then((response) => {
        if (params != null) {
          commit('SET_INVOICE_TEMPLATE_FILTER', { elements: response.data, params });
        } else {
          commit('SET_INVOICE_TEMPLATE_ELEMENTS', response.data);
        }
        return response.data;
      });
  },
  sendReceiptToERP(_, payload) {
    return sendReceiptToERP(payload)
      .then((response) => response.data);
  },
  sendReceiptsToERP(_, payload) {
    return sendReceiptsToERP(payload)
      .then((response) => response.data);
  },
  updateManyStatus(_, payload) {
    return updateManyStatus(payload.data).then((response) => response.data);
  },
  getEInvoices({ commit }, payload) {
    return getEInvoices(payload)
      .then((response) => {
        commit('SET_EINVOICE', response.data);
      });
  },
  sendInvoiceToERP(_, payload) {
    return sendInvoiceToERP(payload)
      .then((response) => response.data);
  },
  sendInvoicesToERP(_, payload) {
    return sendInvoicesToERP(payload)
      .then((response) => response.data);
  },
  saveSerialNumber(_, payload) {
    return saveSerialNumber(payload)
      .then((response) => response.data);
  },
  async deleteInvoiceTemplates({ commit }, payload) {
    const response = await deleteInvoiceTemplates(payload);
    commit('SET_INCOME_INVOICE', response);
  },
  async updateInvoiceTemplates({ commit }, payload) {
    const { id, data } = payload;
    const response = await updateInvoiceTemplates(id, data);
    commit('SET_INCOME_INVOICE', response);
  },
  getAccounts({ commit }, payload) {
    return getAccounts(payload).then((response) => {
      commit('SET_ACCOUNTS', response.data.rows);
      return response.data;
    });
  },
  updateDecoratedAccounts({ commit }, data) {
    commit('SET_DECORATED_ACCOUNTS', data);
  },
};
