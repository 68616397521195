export default {
  notifications: [],
  notificationsParent: [],
  unreadCount: 0,
  error: {},
  skipCount: 0,
  skipCountParent: 0,
  loadMoreShouldShow: false,
  loadMoreShouldShowParent: false,
};
