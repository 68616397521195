export default {
  conversation: {
    from: null,
    message: [],
  },
  subjectCategory: null,
  otherSubject: null,
  status: null,
  ticketId: null,
};
