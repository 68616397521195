export default {
  claims: {
    claims: [],
    totalCount: null,
    limit: null,
    totalPage: null,
  },
  status: null,
  reasons: null,
  approve: null,
  reject: null,
  process: null,
};
