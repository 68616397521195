import { getAllowances, getOrderNetOffs } from '@/api/allowance';

export default {
  getOrderNetOffs({ commit }, payload) {
    return getOrderNetOffs(payload)
      .then((response) => {
        commit('SET_ORDER_NET_OFFS', response.data);
        return response.data;
      });
  },
  getAllowances({ commit }, payload) {
    return getAllowances(payload).then(({ data }) => {
      commit('SET_ALLOWANCES', data);
      return data;
    });
  },
};
