import {
  getOrders,
  getOrderCargoBarcode,
  orderToBePackaged,
  exportOrders,
  setOrderUnPackage,
  setOrderCancel,
  getProviderCancelReason,
  getCargoCompanies,
  changeCargoCompany,
  changeManyCargoCompany,
  getOrderCount,
  getOrder,
  getExportOrders,
  setIsPrintedInPackage,
} from '@/api/order';

export default {
  getOrders({ commit }, payload) {
    return getOrders(payload)
      .then((response) => {
        commit('SET_ORDERS', response.data);
        return response.data;
      });
  },
  getOrder({ commit }, payload) {
    return getOrder(payload)
      .then((response) => {
        commit('SET_ORDER', response.data);
        return response.data;
      });
  },
  getOrderCount({ commit }, payload) {
    return getOrderCount(payload)
      .then((response) => {
        commit('SET_ORDER_COUNT', response.data);
        return response.data;
      });
  },
  getCargoCompanies({ commit }, payload) {
    return getCargoCompanies(payload)
      .then((response) => {
        commit('SET_CARGO_COMPANY', response.data);
        return response.data;
      });
  },
  changeCargoCompany({ commit }, payload) {
    return changeCargoCompany(payload)
      .then((response) => {
        commit('SET_CHANGE_CARGO_COMPANY', response.data);
        return response.data;
      });
  },
  changeManyCargoCompany({ commit }, payload) {
    return changeManyCargoCompany(payload)
      .then((response) => {
        commit('SET_CHANGE_MANY_CARGO_COMPANY', response.data);
        return response.data;
      });
  },
  getOrderBarcode({ commit }, payload) {
    return getOrderCargoBarcode(payload)
      .then((response) => {
        commit('SET_ORDER_BARCODE', response.data);
        return response.data;
      });
  },
  orderToBePackaged({ commit }, payload) {
    return orderToBePackaged(payload)
      .then((response) => {
        commit('SET_ORDER_TO_BE_PACKAGED', response.data);
        return response.data;
      });
  },
  setOrderUnPackage({ commit }, payload) {
    return setOrderUnPackage(payload)
      .then((response) => {
        commit('SET_ORDER_UN_PACKAGE', response.data);
        return response.data;
      });
  },

  setOrderCancel({ commit }, payload) {
    return setOrderCancel(payload)
      .then((response) => {
        commit('SET_ORDER_CANCEL', response.data);
        return response.data;
      });
  },

  getProviderCancelReason({ commit }, payload) {
    return getProviderCancelReason(payload)
      .then((response) => {
        commit('SET_PROVIDER_CANCEL_REASON', response.data);
        return response.data;
      });
  },
  exportOrders(_, payload) {
    return exportOrders(payload);
  },
  getExportOrders({ commit }, payload) {
    return getExportOrders(payload)
      .then((response) => {
        commit('SET_EXPORT_ORDERS', response.data);
        return response.data;
      });
  },
  setIsPrintedInPackage(_, payload) {
    return setIsPrintedInPackage(payload)
      .then((response) => response.data);
  },
};
