import Vue from 'vue';
import Vuex from 'vuex';
import { loadStore } from '@/utils/helpers';

const modules = loadStore();
Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  plugins: [
  ],
});
