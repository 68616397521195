import request from '../utils/request';

const prefix = '/api/products/special-offers';

export const getCampaigns = ({ page, limit, filter }) => request.get(`${prefix}`, { params: { page, limit, filter } });

export const getCampaignById = (id) => request.get(`${prefix}/${id}`);

export const createCampaign = (params) => request.post(`${prefix}`, params);

export const updateCampaignById = (id, params) => request.put(`${prefix}/${id}`, params);

export const deleteCampaignById = (id) => request.delete(`${prefix}/${id}`);
